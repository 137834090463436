import { createReducer } from "@reduxjs/toolkit"
import { supportActions } from "./actions"
import { TInitialState } from "./types"

const InitialState: TInitialState = {
  support: { docs: [], meta: null },
  loading: false,
  response: null,
}

export const supportReducer = createReducer<TInitialState>(
  InitialState,
  (builder) => {
    builder
      .addCase(supportActions.setSupport, (state, { payload }) => ({
        ...state,
        support: payload,
      }))
      .addCase(supportActions.setSupportState, (state, { payload }) => ({
        ...state,
        response: null,
        ...payload,
      }))
  }
)
