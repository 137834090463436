import { Navigate } from "react-router-dom"

import { LoginPhone } from "pages/LoginPhone"
import { EditPatient } from "pages/EditPatient"
import { LoginCode } from "pages/LoginCode"
import { ResultConsultation } from "pages/ResultConsultationPage"
import { AccountPage } from "pages/AccountPage"
import { Chat } from "pages/Chat"
import { RegisterAccountPage } from "pages/RegisterAccountPage"
import { CreateDoctorPage } from "pages/CreateDoctorPage"
import { UpdateConsultationPage } from "pages/UpdateConsultationPage"
import { NewConsultationPage } from "pages/NewConsultationPage"
import { PatientFilesPage } from "pages/PatientFilesPage"
import { Appointmant } from "pages/Appointmant"
import { PatientsList } from "pages/Patients"
import { CurrentConsultation } from "pages/CurrentConsultation"
import { SupportPage } from "pages/SupportPage"
import { EditDoctor } from "pages/EditDoctor"
import { DoctorsList } from "pages/DoctorsList"
import { FutureConsultations } from "pages/FutureConsultations"
import { PastConsultations } from "pages/PastConsultations"
import { InfoSection } from "pages/InfoSection"

import { TRoutes } from "./types"

export const routes: TRoutes = {
  loginPhone: {
    name: "login-phone",
    path: "/login-phone",
    component: LoginPhone,
  },
  patientFilesPage: {
    name: "patient-files",
    path: "/patient-files/:id/:chatId",
    component: PatientFilesPage,
  },
  loginCode: {
    name: "login-code",
    path: "/login-code",
    component: LoginCode,
  },
  patients: {
    name: "patients",
    path: "/patients/:id",
    component: PatientsList,
  },
  pastConsultations: {
    name: "pastConsultations",
    path: "/pastConsultations/:doctorId/:userId",
    component: PastConsultations,
  },
  futureConsultations: {
    name: "futureConsultations",
    path: "/futureConsultations",
    component: FutureConsultations,
  },
  editPatient: {
    name: "patient",
    path: "/patient/:id",
    component: EditPatient,
  },
  doctorsList: {
    name: "doctors",
    path: "/doctors/:id",
    component: DoctorsList,
  },
  supportPage: {
    name: "support",
    path: "/support",
    component: SupportPage,
  },
  editDoctor: {
    name: "editDoctor",
    path: "/edit-doctor/:id",
    component: EditDoctor,
  },
  chat: {
    name: "chat",
    path: "/chat/:userId",
    component: Chat,
  },
  newConsultation: {
    name: "newConsultation",
    path: "/newConsultation/:id/:doctorId",
    component: NewConsultationPage,
  },
  appointmant: {
    name: "appointmant",
    path: "/appointmant/:id",
    component: Appointmant,
  },
  account: {
    name: "account",
    path: "/account",
    component: AccountPage,
  },
  resultConsultation: {
    name: "resultConsultation",
    path: "/resultConsultation/:id/:typeConsultation",
    component: ResultConsultation,
  },
  currentConsultation: {
    name: "currentConsultation",
    path: "/currentConsultation",
    component: CurrentConsultation,
  },
  updateConsultation: {
    name: "updateConsultation",
    path: "/updateConsultation/:id",
    component: UpdateConsultationPage,
  },
  createDoctor: {
    name: "create-doctor",
    path: "/create-doctor",
    component: CreateDoctorPage,
  },
  registerAccount: {
    name: "registerAccount",
    path: "/registerAccount",
    component: RegisterAccountPage,
  },
  notFounded: {
    name: "notFounded",
    path: "*",
    component: () => <Navigate to={"/login-phone"} />,
  },
  notFoundedAuth: {
    name: "notFoundedAuth",
    path: "*",
    component: () => <Navigate to={"/account"} />,
  },
  infoSection: {
    name: "infoSection",
    path: "/infoSection",
    component: InfoSection,
  },
}
