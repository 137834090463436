import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { io } from "socket.io-client";

import { api, RelativePreloader, useTypedSelector } from "common";
import { chatActions, getChatSelector, TChatIdMessageDocs } from "store/chat";
import { getAuthSelector } from "store/auth";

import { Container } from "./styled";
import { ChannelBody, Footer, Header } from "./components";
import { TChatBody } from "./types";
import { ITEM_PER_PAGE } from "./consts";
import { useParams } from "react-router-dom";

export const ChatBody = ({
  loading,
  idCurrentChat,
  isActiveChat,
  userName,
  setIsActiveChat,
}: TChatBody) => {
  const { userId } = useParams();
  
  const { accessToken, currentId, type } = useTypedSelector(getAuthSelector);
  const { chatIdLoading } = useTypedSelector(getChatSelector);
  const { chatIdMessages, currentChatData } = useTypedSelector(getChatSelector);

  const [messageData, setMessageData] = useState<TChatIdMessageDocs[]>([]);
  const [onlineStatus, setOnlineStatus] = useState<boolean>(false);

  const getChat = () => {
    dispatch(
      chatActions.getChat({
        limit: 40,
        skip: 0,
        userName: userName,
        doctorId: type === "admin" ? "" : currentId,
        userId: userId === "all" ? "" : userId,
      })
    );
  };

  useEffect(() => {
    const socket = io(api.withAPI, {
      autoConnect: true,
      forceNew: true,
      reconnection: true,
      extraHeaders: { Authorization: `Bearer ${accessToken}` },
    });

    socket.on("newChatMessage", (patientMessage) => {
      if (idCurrentChat === patientMessage.chat) {
        setMessageData((prevState) => [patientMessage, ...prevState]);
        dispatch(chatActions.chatRead({ _id: idCurrentChat }));
      }
    });

    socket.on("onlineStatus", (status: { _id: string; online: boolean }) => {
      if (status._id !== currentId) {
        setOnlineStatus(status.online);
        getChat();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const dispatch = useDispatch();

  const [currPage, setCurrPage] = useState<number>(0);

  const totalPages = chatIdMessages.meta
    ? Math.ceil(chatIdMessages.meta.totalCount / ITEM_PER_PAGE)
    : 0;

  useEffect(() => {
    if (idCurrentChat) {
      dispatch(
        chatActions.getChatIdMessages({
          _id: idCurrentChat,
          limit: 20,
          skip: currPage,
        })
      );
    }
  }, [idCurrentChat, currPage]);

  useEffect(() => {
    if (chatIdMessages) {
      setMessageData([...messageData, ...chatIdMessages.docs]);
    }
  }, [chatIdMessages]);

  useEffect(() => {
    if (idCurrentChat) {
      setMessageData([]);
      setCurrPage(0);
      dispatch(chatActions.getCurrentChatData({ _id: idCurrentChat }));
    }
  }, [idCurrentChat]);

  return (
    <React.Fragment>
      {!chatIdLoading ? (
        <Container $isActiveChat={isActiveChat}>
          <Header
            onlineStatus={onlineStatus}
            setIsActiveChat={setIsActiveChat}
            currentChatData={currentChatData}
          />
          <ChannelBody
            totalPages={totalPages}
            loading={loading}
            currPage={currPage}
            setCurrPage={setCurrPage}
            currentChatData={currentChatData}
            chatMessage={messageData}
          />
          <Footer
            startTime={currentChatData?.appointment?.startTime}
            statusConsultation={currentChatData?.appointment?.status}
            idCurrentChat={idCurrentChat}
          />
        </Container>
      ) : (
        <RelativePreloader loading={chatIdLoading} />
      )}
    </React.Fragment>
  );
};
