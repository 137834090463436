import { colors } from "common"
import styled from "styled-components"

export const Main = styled.div`
  padding: 42px 30px;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
`

export const Form = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`

export const Input = styled.input`
  border: 1px solid white;
  border-radius: 8px;

  background-color: white;

  padding: 11px;

  width: 306px;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 420px) {
    width: 270px;
  }
`

export const Image = styled.img`
 max-width: 25%;
  height: auto;
`
export const ButtonContainer = styled.div`
  width: 20%;
  display: flex;
  gap: 16px;
  margin-top: 16px;
  align-self: flex-start;
`

export const Line = styled.div`
  border-bottom: 1px solid ${colors.bgMain};
  margin: 28px 0px;
  width: 100%;
`
