import styled from "styled-components";

import { TImage, TVideo } from "./types";

export const Container = styled.div`
  position: relative;

  @media (max-width: 780px) {
    width: 250px;
  }  

  @media (max-width: 500px) {
    width: 200px;
  }  

  @media (max-width: 400px) {
    width: 150px;
  }  
`;

export const Video = styled.video<TVideo>`
  display: flex;
  width: ${({ width }) => `${width}`};
  padding: 10px
`;

export const FileIcon = styled.img`
  margin-right: 9px;
`;

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Close = styled.img`
  position: absolute;
  right: -60px;
  cursor: pointer;
  top: -25px;
`;

export const Source = styled.source``;

export const Image = styled.img<TImage>`
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  max-height: ${({ maxHeight }) => `${maxHeight}`};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};

  background-size: cover;
  background-position: center;

  cursor: pointer;

  display: block;
  padding: 10px;

  @media (max-width: 780px) {
    width: 250px;
  }  

  @media (max-width: 500px) {
    width: 100%;
  }  
`;

export const Text = styled.span``;