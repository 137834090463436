import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";

import { appointmentActions } from "store/appointment";


import { Button, Text, colors } from "common";

import {
  EndRepeatBlock,
  Item,
  Modal,
  ModalContainer,
  RadioButton,
  RadioButtonLabel,
  Title,
  ButtonBlock,
} from "./styled";
import { TModalToRemoveConsultations } from "./types";

export const ModalToRemoveConsultations = ({
  setModalEventCalendarIsOpen,
  calendarEventData,
  doctorId,
  setModalIsActive,
  endDate,
  startDate,
}: TModalToRemoveConsultations) => {
  const [selectRepeatTo, setSelectRepeatTo] = useState<string>("");
  const dispatch = useDispatch();

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectRepeatTo(event.target.value);
  };

  const deleteSheduleHandler = () => {
    if (selectRepeatTo === "thisRecord") {
      dispatch(
        appointmentActions.deleteAppointment({ _id: calendarEventData.id })
      );
    }
    if (selectRepeatTo === "currentDate") {
      selectRepeatTo === "currentDate" &&
        dispatch(
          appointmentActions.deleteAppointmentRepeatTable({
            start: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(endDate).format(`YYYY-MM-DDT24:00:00`),
            doctor: doctorId,
            emptyOnly: "1",
          })
        );
    }
    if (selectRepeatTo === "allRecords") {
      dispatch(
        appointmentActions.deleteAppointmentRepeatTable({
          doctor: doctorId,
          group: calendarEventData.groupId,
          start: moment(startDate).format("YYYY-MM-DD"),
          end: "2040-06-10",
        })
      );
    }
    setModalIsActive(false);
    setModalEventCalendarIsOpen(false);
  };

  return (
    <Modal>
      <ModalContainer>
        <Title>Видалити повторні записи</Title>
        <EndRepeatBlock>
          <Item>
            <RadioButton
              type="radio"
              name="radio"
              value="thisRecord"
              checked={selectRepeatTo === "thisRecord"}
              onChange={(event) => handleSelectChange(event)}
            />
            <RadioButtonLabel />
            <Text text="Тільки цей запис" />
          </Item>
          <Item>
            <RadioButton
              type="radio"
              name="radio"
              value="currentDate"
              checked={selectRepeatTo === "currentDate"}
              onChange={(event) => handleSelectChange(event)}
            />
            <RadioButtonLabel />
            <Text text="Цей і наступні записи" />
          </Item>
          {calendarEventData?.groupId !== "undefined" && (
            <Item>
              <RadioButton
                type="radio"
                name="radio"
                value="allRecords"
                checked={selectRepeatTo === "allRecords"}
                onChange={(event) => handleSelectChange(event)}
              />
              <RadioButtonLabel />
              <Text text="Всі повторювані записи" />
            </Item>
          )}
        </EndRepeatBlock>
        <ButtonBlock>
          <Button
            color={`${colors.gray}`}
            width={153}
            height={40}
            size={16}
            onClick={() => setModalIsActive(false)}
            title="Відмінити"
          />
          <Button
            color={`${colors.salmon}`}
            width={153}
            height={40}
            size={16}
            onClick={deleteSheduleHandler}
            title="Підтвердити"
          />
        </ButtonBlock>
      </ModalContainer>
    </Modal>
  );
};
