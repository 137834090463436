import React, { useState } from "react"
import FullCalendar, {
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
} from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction"
import timeGridPlugin from "@fullcalendar/timegrid"
import ukLocale from "@fullcalendar/core/locales/uk"

import { ContainerCalendar } from "./styled"
import { ModalInfosEventCalendar } from "./components"
import { CalendarSchedulerProps, TEventInfos } from "./types"

export const CalendarScheduler = ({
  eventsCalendar,
  doctorId,
  setEndDayOfWeek,
  setStartDayOfWeek,
  doctor,
}: CalendarSchedulerProps) => {
  const [eventInfos, setEventInfos] = useState<TEventInfos>()
  const [isEditCard, setIsEditCard] = useState<boolean>(false)
  const [modalEventCalendarIsOpen, setModalEventCalendarIsOpen] =
    useState<boolean>(false)

  const weekends = {
    weekendsVisible: true,
  }

  const handleAddEventSelectAndOpenModal = (
    selectInfo: DateClickArg | DateSelectArg
  ) => {
    setIsEditCard(false)
    setEventInfos(selectInfo as TEventInfos)
    setModalEventCalendarIsOpen(true)
  }

  const handleEditEventSelectAndOpenModal = (clickInfo: EventClickArg) => {
    setIsEditCard(true)
    setEventInfos(clickInfo)
    setModalEventCalendarIsOpen(true)
  }

  const onDatesSet = (dateInfo: DatesSetArg) => {
    setStartDayOfWeek(dateInfo.startStr)
    setEndDayOfWeek(dateInfo.endStr)
  }

  return (
    <ContainerCalendar>
      <ModalInfosEventCalendar
        setModalEventCalendarIsOpen={setModalEventCalendarIsOpen}
        doctorId={doctorId}
        modalEventCalendarIsOpen={modalEventCalendarIsOpen}
        eventInfos={eventInfos}
        isEditCard={isEditCard}
        doctor={doctor}
      />
      <FullCalendar
        plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        headerToolbar={{
          right: "prev,today,next",
          left: "title",
        }}
        dayHeaderFormat={{
          weekday: "short",
          month: undefined,
          day: "numeric",
          omitCommas: true,
        }}
        scrollTimeReset={false}
        titleFormat={{ year: "numeric", month: "long" }}
        locales={[ukLocale]}
        droppable={false}
        weekends={weekends.weekendsVisible}
        dateClick={handleAddEventSelectAndOpenModal}
        select={handleAddEventSelectAndOpenModal}
        eventClick={handleEditEventSelectAndOpenModal}
        datesSet={onDatesSet}
        events={eventsCalendar}
        longPressDelay={1000}
        eventLongPressDelay={1000}
        nowIndicator={true}
        selectLongPressDelay={1000}
        selectable={true}
        eventOverlap={false}
        dayMaxEvents={true}
        allDaySlot={false}
        editable={false}
        contentHeight="auto"
        views={{
          week: {
            columnFormat: "ddd",
          },
        }}
      />
    </ContainerCalendar>
  )
}
