import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { Assets } from 'common';

import {
  Container,
  RatingBlock,
  ImageBlock,
  Image,
  Date,
  CommentBlock,
  Review,
  DeleteIcon
} from './styled';
import { TComment } from "./types";
import { doctorsActions } from 'store/doctors';

export const Comment = ({ feedbacks, getFeedback }:TComment) => {
  const dispatch = useDispatch();

  const deleteFeedbackHandler = (id: string) => {
    dispatch(doctorsActions.deleteFeedback({_id: id}));
    getFeedback();
  };

  return (
    <Container>
       <RatingBlock>
       <ImageBlock>
          {
            [ ...Array(feedbacks?.mark)].map((el, index: number) => <Image key={index} src={Assets.STAR} />)
          }
       </ImageBlock>   
          <Date>{moment(feedbacks.createdAt).format('DD/MM/YY')}</Date>
       </RatingBlock>
       <CommentBlock>
         <Review>{feedbacks?.review}</Review>
         <DeleteIcon onClick={() => deleteFeedbackHandler(feedbacks?._id)} src={Assets.TRASH_OUTILE}/>
       </CommentBlock>
    </Container>
  )
}