import { createReducer } from '@reduxjs/toolkit'
import { doctorsActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
	doctors: {
		docs: [],
		meta: null
	},
	doctorsLoading: false,
	doctorLoading: false,
	doctor: null,
	loading: false,
	response: null,
	feedbackLoading: false,
	feedbacksDoctor: {
		docs: [],
		meta: null
	},
	doctorId: null
}

export const doctorsReducer = createReducer<TInitialState>(InitialState, (builder) => {
	builder
		.addCase(doctorsActions.setDoctors, (state, { payload }) => ({ ...state, doctors: payload }))
		.addCase(doctorsActions.setDoctor, (state, { payload }) => ({ ...state, doctor: payload}))
		.addCase(doctorsActions.setDoctorId, (state, { payload }) => ({ ...state, doctorId: payload}))
		.addCase(doctorsActions.setFeedbacksDoctor, (state, { payload }) => ({ ...state, feedbacksDoctor: payload}))
		.addCase(doctorsActions.setDoctorsState, (state, { payload }) => ({ ...state, response: null, ...payload }))		
})