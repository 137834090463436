import styled from "styled-components"

export const Main = styled.div`
  padding: 0px 30px;

  display: flex;
  flex-direction: column;

  gap: 16px;
`
