import { put, call, takeLatest, debounce } from "redux-saga/effects"

import { TDataWrapper, TResponse } from "../types"
import { getAccessToken } from "../auth"

import { showToastAction } from "store/toasts"
import { generateErrorToast, generateSuccessToast } from "common/helpers"
import { notifiactionActions } from "store/notification"

import {
  TGetDoctorsPayload,
  TGetDoctorPayload,
  TUpdateDoctorPayload,
  TGetFeedbackDoctorPayload,
  TCreateDoctorPayload,
  TDeleteFeedbackRequestPayload,
} from "./types"
import { doctorsActions } from "./actions"
import { ApiDoctorsService } from "./api.service"

function* getDoctorsWorker({ payload }: TDataWrapper<TGetDoctorsPayload>) {
  yield put(
    doctorsActions.setDoctorsState({ loading: true, doctorsLoading: true })
  )

  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiDoctorsService, ApiDoctorsService.getDoctors],
      { ...payload, token }
    )
    if (response.data) yield put(doctorsActions.setDoctors(response.data))
  } catch (e) {
    yield put(doctorsActions.setDoctors({ data: [], meta: null }))
  }
  yield put(
    doctorsActions.setDoctorsState({ loading: false, doctorsLoading: false })
  )
}

function* getFeedbacksDoctorWorker({
  payload,
}: TDataWrapper<TGetFeedbackDoctorPayload>) {
  yield put(doctorsActions.setDoctorsState({ feedbackLoading: true }))
  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiDoctorsService, ApiDoctorsService.getFeedbacksDoctor],
      { ...payload, token }
    )
    if (response.data)
      yield put(doctorsActions.setFeedbacksDoctor(response.data))
  } catch (e) {
    yield put(doctorsActions.setFeedbacksDoctor({ docs: [], meta: null }))
  }
  yield put(doctorsActions.setDoctorsState({ feedbackLoading: false }))
}

function* getDoctorWorker({ payload }: TDataWrapper<TGetDoctorPayload>) {
  yield put(doctorsActions.setDoctorsState({ doctorLoading: true }))

  const { _id } = payload
  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiDoctorsService, ApiDoctorsService.getDoctor],
      { _id, token }
    )
    if (response.data) {
      yield put(doctorsActions.setDoctor({ ...response.data }))
      yield put(doctorsActions.setDoctorsState({ doctorLoading: false }))
    } else {
      yield put(doctorsActions.setDoctorsState({ doctorLoading: false }))
    }
  } catch (e) {}
}

function* updateDoctorWorker({ payload }: TDataWrapper<TUpdateDoctorPayload>) {
  const { _id, data } = payload
  const token: string = yield call(getAccessToken)

  try {
    yield call([ApiDoctorsService, ApiDoctorsService.updateDoctor], {
      _id,
      data,
      token,
    })
    yield put(
      showToastAction.request(generateSuccessToast("Доктор успішно оновлений"))
    )
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast("Помилка оновлення доктора"))
    )
  }

  yield put(
    doctorsActions.setDoctorsState({ loading: true, response: "updated" })
  )
}

function* createDoctorWorker({ payload }: TDataWrapper<TCreateDoctorPayload>) {
  const { data } = payload
  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiDoctorsService, ApiDoctorsService.createDoctor],
      { data, token }
    )
    if (response.data) {
      yield put(doctorsActions.setDoctorId(response.data))
    }
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast(
          "Помилка створення доктора. Доктор з таким номером телефону існує"
        )
      )
    )
  }
}

function* deleteFeedbackWorker({
  payload,
}: TDataWrapper<TDeleteFeedbackRequestPayload>) {
  const { _id } = payload
  const token: string = yield call(getAccessToken)

  try {
    yield call([ApiDoctorsService, ApiDoctorsService.deleteFeedback], {
      _id,
      token,
    })
    yield put(
      showToastAction.request(generateSuccessToast("Відгук успішно видалено"))
    )
  } catch (e) {
    yield put(
      showToastAction.request(generateSuccessToast("Помилка видалення відгуку"))
    )
  }
}

export function* doctorsWatcher() {
  yield debounce(400, doctorsActions.getDoctors, getDoctorsWorker)
  yield takeLatest(doctorsActions.getDoctor, getDoctorWorker)
  yield takeLatest(doctorsActions.updateDoctor, updateDoctorWorker)
  yield takeLatest(doctorsActions.getFeedbacksDoctor, getFeedbacksDoctorWorker)
  yield takeLatest(doctorsActions.createDoctor, createDoctorWorker)
  yield takeLatest(doctorsActions.deleteFeedback, deleteFeedbackWorker)
}
