import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { api, Assets, colors, Text } from "common";
import {
  NameBlock,
  Photo,
  TableContainer,
  UserInfo,
  Name,
  NavLinkBlock,
  Image,
  InfoBlock,
  NavLinkContainer,
  Item,
} from "./styled";
import { TTable } from "./types";

export const Table = ({ user, setUserId }: TTable) => {
  const { t } = useTranslation();
  
  return (
    <TableContainer>
      <NameBlock>
        <UserInfo>
          <Photo
            src={
              user?.photo
                ? `${api.withImageAPI}/user/${user?.photo}`
                : Assets.ACCOUNT
            }
          />
          <Name>{`${user?.surname} ${user?.name} ${user?.patronymic}`}</Name>
        </UserInfo>
        <NavLinkBlock onClick={() => setUserId("")}>
          <Image src={Assets.EDIT} />
          <Text color={`${colors.salmon}`} text='Редагувати' fontWeight={500}/>
        </NavLinkBlock>
      </NameBlock>
      <NavLinkContainer>
        <InfoBlock>
          <Image src={Assets.PHONE} />
          <Text text={user?.phone} />
        </InfoBlock>
        {user?.email && (
          <InfoBlock>
            <Image src={Assets.MAIL} />
            <Text text={user?.email} />
          </InfoBlock>
        )}  
        <InfoBlock>
          <Image src={Assets.CALENDAR} />
          <Text text={`Дата нр: ${moment(user?.birthday).format("DD/MM/YY")}`} />
        </InfoBlock>
        <InfoBlock>
          <Image src={Assets.ACCOUNT} />
          <Item>
            {t(`${user?.gender}`)}
          </Item>
        </InfoBlock>
      </NavLinkContainer>
    </TableContainer>
  );
};
