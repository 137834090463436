import React, { RefObject, useRef } from "react";

import { useObserver, useTypedSelector } from "common";
import { getAuthSelector } from "store/auth";
import { getChatSelector } from "store/chat";

import { MyMesageContainer, UserMessageContainer } from "./components";
import { TChannelBody } from "./types";
import { Container, ChannelContainer, Div, LoadingText } from "./styled";

export const ChannelBody = ({
  chatMessage,
  currentChatData,
  loading,
  setCurrPage,
  currPage,
  totalPages,
}: TChannelBody) => {
  const lastElement = useRef(null);

  const { currentId, type } = useTypedSelector(getAuthSelector);
  const { chatMessageLoading } = useTypedSelector(getChatSelector);

  useObserver(lastElement, currPage < totalPages, loading, () => {
    setCurrPage(currPage + 1);
  });

  const ScrollUp = () => {
    const element = document.getElementById("firsElement");
    element?.scrollIntoView({
      block: "end",
    });
  };

  return (
    <React.Fragment>
      <Container>
        <Div id="firsElement"></Div>
        {chatMessageLoading && <LoadingText>Завантаження...</LoadingText>}
        {chatMessage?.map((el) => (
          <ChannelContainer key={el?._id}>
            {type === "admin" ? (
              <>
                <>
                  {el.from === "iTbjFwJmmZN3NFGFl5AL2jgxIcX2" ? (
                    <MyMesageContainer
                      myMessageData={el}
                      currentChatData={currentChatData}
                    />
                  ) : (
                    <>
                      {el.from === currentChatData?.doctor?._id ? (
                        <MyMesageContainer
                          myMessageData={el}
                          currentChatData={currentChatData}
                        />
                      ) : (
                        <UserMessageContainer
                          userMessageData={el}
                          currentChatData={currentChatData}
                        />
                      )}
                    </>
                  )}
                </>
              </>
            ) : (
              <>
              {el.from === "iTbjFwJmmZN3NFGFl5AL2jgxIcX2" ? (
                <MyMesageContainer
                  myMessageData={el}
                  currentChatData={currentChatData}
                />
              ) : (
                <>
                  {el.from === currentId ? (
                    <MyMesageContainer
                      myMessageData={el}
                      currentChatData={currentChatData}
                    />
                  ) : (
                    <UserMessageContainer
                      userMessageData={el}
                      currentChatData={currentChatData}
                    />
                  )}
                </>
              )}
            </>
            )}
          </ChannelContainer>
        ))}
        <Div ref={lastElement as RefObject<HTMLDivElement>}></Div>
      </Container>
    </React.Fragment>
  );
}
