import { AxiosResponse } from "axios"
import { HttpService } from "store/http.service"
import { TResponse } from "store/types"
import { CLINICS_URL } from "./config"
import { TGetClinicsRequestPayload } from "./types"

export class ApiClinicsService extends HttpService {
  static getClinics({
    token,
  }: TGetClinicsRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${CLINICS_URL.getClinics}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
  }
}
