import { Assets } from "common"

import { TSiderbarMenus } from "./types"

export const sidebarConsultations: TSiderbarMenus[] = [
  {
    name: "Майбутні",
    path: "/futureConsultations",
    exact: false,
  },
  {
    name: "Теперішні",
    path: "/currentConsultation",
    exact: false,
  },
  {
    name: "Проведені консультації",
    path: "/pastConsultations/doctorAll/userAll",
    exact: false,
  },
  {
    name: "Нова консультація",
    path: "/newConsultation/all/doctorAll",
    exact: false,
  },
]

export const sidebarMenus: TSiderbarMenus[] = [
  {
    name: "Розклад",
    path: "/appointmant/all",
    exact: false,
    icon: Assets.CALENDAR,
  },
  {
    name: "Пацієнти",
    path: "/patients/all",
    exact: false,
    icon: Assets.DOCUMENT,
  },
  {
    name: "Чат",
    path: "/chat/all",
    exact: false,
    icon: Assets.CHAT,
  },
  {
    name: "Лікарі",
    path: "/doctors/all",
    exact: false,
    icon: Assets.DOCTOR,
  },
  {
    name: "Підтримка",
    path: "/support",
    exact: false,
    icon: Assets.CUSTOMER_SERVICE,
  },
  {
    name: "Новини",
    path: "/infoSection",
    exact: false,
    icon: Assets.DOCUMENT,
  },
  {
    name: "Акаунт",
    path: "/account",
    exact: false,
    icon: Assets.ACCOUNT,
  },
]

export const sidebarMenusDoctor: TSiderbarMenus[] = [
  {
    name: "Розклад",
    path: "/appointmant/all",
    exact: false,
    icon: Assets.CALENDAR,
  },
  {
    name: "Пацієнти",
    path: "/patients/all",
    exact: false,
    icon: Assets.DOCUMENT,
  },
  {
    name: "Чат",
    path: "/chat/all",
    exact: false,
    icon: Assets.CHAT,
  },
  {
    name: "Акаунт",
    path: "/account",
    exact: false,
    icon: Assets.ACCOUNT,
  },
]

export const registerSidebarMenus: TSiderbarMenus[] = [
  {
    name: "Акаунт",
    path: "/registerAccount",
    exact: false,
    icon: Assets.ACCOUNT,
  },
]
