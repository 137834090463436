import React, { SyntheticEvent, useEffect } from "react"

import { api, Button, PhotoPicker, Title, Text, useTypedSelector } from "common"
import { TFormData } from "pages/AccountPage/types"

import { Form, PhotoPickerContainer, Container } from "./styled"
import { Info, InfoForPatients } from "./components"
import { TInfoAboutDoctor } from "./types"
import { clinicsActions, getClinicsSelector } from "store/clinics"
import { useDispatch } from "react-redux"
import { TOption } from "./components/Info/types"

export const InfoAboutDoctor = ({
  title,
  registerAccount = false,
  doctor,
  register,
  errors,
  phone,
  handleSubmit,
  formData,
  setFormData,
  accountPage,
  photoFile,
  setPhotoFile,
  CRUDFunction,
}: TInfoAboutDoctor) => {
  const dispatch = useDispatch()

  const { clinics } = useTypedSelector(getClinicsSelector)

  const clinicsOptions = clinics.map(
    (clinic) =>
      clinic.address
        .map((address) => {
          if (address.lang == "uk") {
            return { name: address.value, value: clinic._id }
          }
        })
        .filter((item) => item)[0]
  )

  const onPhotoChange = (file: File | null) => {
    setPhotoFile({ ...photoFile, photo: file as File | null })
  }

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement
    if (name == "price") {
      setFormData((props: TFormData) => ({ ...props, [name]: +value }))
      return
    }
    setFormData((props: TFormData) => ({ ...props, [name]: value }))
  }

  useEffect(() => {
    dispatch(clinicsActions.getClinics({}))
  }, [])

  return (
    <Container>
      <Title title={title} />
      <PhotoPickerContainer>
        <PhotoPicker
          width={72}
          height={72}
          name="image"
          roundCorners={true}
          onChange={onPhotoChange}
          value={`${api.withImageAPI}/doctor/${doctor?.photo}`}
        />
        <Text text="Завантажити фото" fontSize={"15"} color="#6D7B75" />
      </PhotoPickerContainer>
      <Form>
        <Info
          clinicsOptions={clinicsOptions as TOption[]}
          accountPage={accountPage}
          setFormData={setFormData}
          formData={formData}
          inputChangeHandler={inputChangeHandler}
          phone={phone}
          registerAccount={registerAccount}
          errors={errors}
          register={register}
        />
        <InfoForPatients
          formData={formData}
          setFormData={setFormData}
          inputChangeHandler={inputChangeHandler}
          errors={errors}
          register={register}
        />
        <Button
          onClick={handleSubmit(CRUDFunction)}
          title="Зберегти зміни"
          width={694}
          size={16}
          height={44}
        />
      </Form>
    </Container>
  )
}
