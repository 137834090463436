import { AxiosResponse } from "axios"
import { HttpService } from "store/http.service"
import { TResponse } from "store/types"
import { SUPPORT_URL } from "./config"
import {
  TCloseSupportRequestPayload,
  TGetSupportsRequestPayload,
} from "./types"

export class ApiSupportService extends HttpService {
  static getSupport({
    token,
    status,
  }: TGetSupportsRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${SUPPORT_URL.getSupport}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        status,
      },
    })
  }

  static closeSupport({
    _id,
    token,
  }: TCloseSupportRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${SUPPORT_URL.closeSupport(_id)}`,
      method: "POST",
      headers: {
        Authorization: token,
      },
    })
  }
}
