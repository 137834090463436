import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { api, Assets, useTypedSelector } from "common";
import { chatActions, getChatSelector } from "store/chat";
import { getAuthSelector } from "store/auth";

import {
  Container,
  FormBlock,
  Input,
  UsersBlock,
  UsersContainer,
  Image,
  UserInfo,
  Name,
  TimeBlock,
  Time,
  InfoChatInfo,
  Block,
  UnreadCount,
} from "./styled";
import { TChatBar } from "./types";

export const ChatBar = ({
  idCurrentChat,
  setIdCurrentChat,
  setIsActiveChat,
  isActiveChat,
  userName,
  register
}: TChatBar) => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { t } = useTranslation();

  const [isRead, setIsRead] = useState(false);

  const { chat } = useTypedSelector(getChatSelector);
  const { currentId, type, currentDoctorData } =
    useTypedSelector(getAuthSelector);

  const navigateChatBodyHandler = useCallback((chatId: string) => {
    setIsActiveChat(true);
    setIdCurrentChat(chatId);
  }, []);

  const getChat = () => {
    dispatch(
      chatActions.getChat({
        limit: 40,
        skip: 0,
        userName: userName,
        doctorId: type === "admin" ? "" : currentDoctorData?._id,
        userId: userId === "all" ? "" : userId,
      })
    );
  };

  useEffect(() => {
    getChat();
  }, [userName, currentId, userId]);

  useEffect(() => {
    if (idCurrentChat) {
      dispatch(chatActions.chatRead({ _id: idCurrentChat }));
      setIsRead(true)
    }
  }, [idCurrentChat]);

  useEffect(() => {
    if(isRead === true){
      getChat()
      setIsRead(false)
    }
  }, [isRead])

  return (
    <Container $isActiveChat={isActiveChat}>
      <FormBlock>
        <Input placeholder="Пошук по імені" {...register("name")} />
      </FormBlock>
      <UsersContainer>
        {chat.docs?.map((u) => (
          <React.Fragment key={u._id}>
            <UsersBlock
              background={idCurrentChat === u._id}
              onClick={() => navigateChatBodyHandler(u._id)}
            >
              <UserInfo online={u?.user?.online}>
                <Image
                  src={
                    u?.user?.photo
                      ? `${api.withImageAPI}/user/${u?.user?.photo}`
                      : Assets.ACCOUNT
                  }
                />
                <InfoChatInfo>
                  <Name>
                    Пацієнт: {"   "}
                    {`${u?.user?.surname ?? ""} ${
                      u?.user?.name?.slice(0, 1) ?? "".toUpperCase()
                    }.${u?.user?.patronymic?.slice(0, 1) ?? "".toUpperCase()}.`}
                  </Name>
                  <Name>
                    Доктор: {"   "}
                    {`${u?.doctor?.surname ?? "---"} ${
                      u?.doctor?.name?.slice(0, 1).toUpperCase() ?? ""
                    } ${u?.doctor?.name ? "." : ""}
                    ${u?.doctor?.patronymic?.slice(0, 1).toUpperCase() ?? ""} ${
                      u?.doctor?.patronymic ? "." : ""
                    } ${
                      u?.doctor?.speciality
                        ? `(${t(u?.doctor?.speciality)})`
                        : ""
                    }`}
                  </Name>
                  <Name>
                    Дата консультації:{" "}
                    {moment(u?.appointment?.startTime).format("DD/MM/YY")}
                  </Name>
                  <Name>
                    Час консультації:{" "}
                    {moment(u?.appointment?.startTime).format("HH:mm")} -{" "}
                    {moment(u?.appointment?.endTime).format("HH:mm")}
                  </Name>
                </InfoChatInfo>
              </UserInfo>
              <Block>
                <TimeBlock>
                  <Time>{moment(u?.lastMessageDate).format("DD/MM/YY")}</Time>
                </TimeBlock>
                <UnreadCount unreadCount={u?.unreadCount}>
                  {u?.unreadCount ? u?.unreadCount : null}
                </UnreadCount>
              </Block>
            </UsersBlock>
          </React.Fragment>
        ))}
      </UsersContainer>
    </Container>
  );
};
