import styled from "styled-components";

import { colors } from "common";

export const Main = styled.div`
  padding: 41px 30px;

  @media (max-width: 700px) {
    padding: 30px 20px;
  }
`;

export const ItemBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: 33px;
  margin-bottom: 24px;
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: ${colors.salmon};
  margin-right:8px;
  color: ${colors.white};
  border-radius: 50%;
`;

export const Select = styled.select`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  margin-top: 2px;
	
  padding: 11px;

  width: 330px;

  cursor: pointer;

  &::placeholder{
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 460px) {
    width: 260px;
  }
`;