import React from 'react';

import { Assets } from 'common';

import { AuxProps } from "./types";
import {
	Container,
	Logo,
	Title,
	Wrapper
} from "./styled";

export const LoginWrapper = ({ children, title='Реєстрація акаунту' }:AuxProps) => {
	return (
		<Container>
			<Wrapper>
				<Logo src={Assets.LOGO238} />
				<Title>{title}</Title>
				{ children }
			</Wrapper>
		</Container>
	);
};