import { createReducer } from '@reduxjs/toolkit'
import { chatActions } from './actions'
import { TInitialState } from './types'

const InitialState: TInitialState = {
	chat: {
		docs: [],
		meta: null
	},
	chatIdMessages: {
		docs: [],
		meta: null,
	},
	messageLoading: false,
	chatIdLoading: false,
	message: null,
	chatMessageLoading: false, 
	loading: false,
	response: null,
	chatloading: false,
	currentChatData: null,
};

export const chatReducer = createReducer<TInitialState>(InitialState, (builder) => {
	builder
		.addCase(chatActions.setChat, (state, { payload }) => ({ ...state, chat: payload }))
		.addCase(chatActions.setChatIdMessages, (state, { payload }) => ({ ...state, chatIdMessages: payload }))
		.addCase(chatActions.setMessage, (state, { payload }) => ({...state, message: payload}))
		.addCase(chatActions.setChatId, (state, { payload }) => ({ ...state, currentChatData: payload }))
		.addCase(chatActions.setChatState, (state, { payload }) => ({ ...state, response: null, ...payload }))
})