import React, { useState, useEffect, SyntheticEvent } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useDispatch } from "react-redux";

import { MainNavbar, RelativePreloader, Title, useTypedSelector } from "common";

import { ListConsultations } from "pages/ListConsultations";
import { appointmentActions, getAppointmentSelector } from "store/appointment";
import { getAuthSelector } from "store/auth";

import { Main, DateBlock, Header, DateContainer } from "./styled";
import { ITEM_PER_PAGE } from "./consts";
import { TCalendarOptions, TFormData } from "./types";

export const FutureConsultations = () => {
  const { register } = useForm();
  const dispatch = useDispatch();

  const { doctorPlain, doctorPlainLoading } = useTypedSelector(
    getAppointmentSelector
  );
  const { type, currentId } = useTypedSelector(getAuthSelector);

  const [сalendarDataOptions, setCalendarOptions] = useState<
    TCalendarOptions[]
  >([
    {
      name: "День",
      value: `${moment(new Date()).format("YYYY-MM-DDT23:00:00")}`,
    },
    {
      name: "Тиждень",
      value: `${moment()
        .week(moment(new Date()).week() + 1)
        .format("YYYY-MM-DD")}`,
    },
    {
      name: "Місяць",
      value: `${moment()
        .month(moment(new Date()).month() + 1)
        .format("YYYY-MM-DD")}`,
    },
  ]);
  const [date, setDate] = useState<string>(
    `${moment()
      .month(moment(new Date()).month() + 1)
      .format("YYYY-MM-DD")}`
  );
  const [page, setPage] = useState<number>(0);
  const [formData, setFormData] = useState<TFormData>({
    doctor: "",
    patient: "",
    speciality: [],
    clinic: "",
  });

  const changeDateHandler = (value: string) => {
    setDate(value);
  };

  const getAppointmentPlain = () => {
    dispatch(
      appointmentActions.getDoctorPlain({
        doctor: type === "admin" ? formData.doctor : currentId,
        end: date,
        user: formData.patient,
        speciality: formData.speciality,
        start: moment(new Date())
          .subtract("minutes", -1)
          .format("YYYY-MM-DDTHH:mm"),
        skip: page,
        limit: ITEM_PER_PAGE,
        sortBy: "startTime",
        order: 1,
        clinic: formData.clinic,
        status: ["reserved", "payment_reserved"],
      })
    );
  };

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((props: TFormData) => ({ ...props, [name]: value }));
  };

  useEffect(() => {
    getAppointmentPlain();
  }, [date, page, dispatch, formData, currentId, type]);

  return (
    <React.Fragment>
      <MainNavbar />
      <Main>
        <Header>
          <Title title={"Майбутні Консультації"} />
          <DateContainer>
            {сalendarDataOptions.map((el, index) => (
              <DateBlock
                key={index}
                onClick={() => changeDateHandler(el.value)}
                isactive={date === el.value}
              >
                {el.name}
              </DateBlock>
            ))}
          </DateContainer>
        </Header>
        {!doctorPlainLoading ? (
          <ListConsultations
            inputChangeHandler={inputChangeHandler}
            getAppointmentPlain={getAppointmentPlain}
            page={page}
            formData={formData}
            setPage={setPage}
            register={register}
            typeConsultations={"future"}
            consultations={doctorPlain}
          />
        ) : (
          <RelativePreloader loading={doctorPlainLoading} />
        )}
      </Main>
    </React.Fragment>
  );
};
