import { createGlobalStyle } from "styled-components";
import fontSFProTextRegular from './common/assets/font/SF-Pro-Display-Regular.otf';

import { colors } from "common";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  };

  @font-face {
    font-family: "SF Pro Text";
    font-style: normal;
	  src: url(${fontSFProTextRegular});
  };

  body {
	  font-family: "SF Pro Text";
    font-style: normal;
	  src: url(${fontSFProTextRegular});
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    background: url('#CEDAD5');
    color: ${colors.black};
 };

 .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: ${colors.white};
    color: ${colors.black};
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0.3rem;
    background-color: ${colors.green};
    color: ${colors.white};
  }
`;