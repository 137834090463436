import React, { useEffect } from "react";

import { TDocsDoctorPlain } from "store/appointment";
import { Pagination } from "common";

import { TListConsultations } from "./types";
import { Forms, Table } from "./components";
import { ITEM_PER_PAGE } from "./consts";

export const ListConsultations = ({
  consultations,
  typeConsultations,
  page,
  inputChangeHandler,
  formData,
  setPage,
  getAppointmentPlain,
}: TListConsultations) => {
  const onPageChangeHandler = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  useEffect(() => {
    if (consultations?.docs?.length === 0) {
      setPage(0);
    }
  }, [consultations]);

  return (
    <React.Fragment>
      <Forms formData={formData} inputChangeHandler={inputChangeHandler} />
      {consultations?.docs?.map((consultation: TDocsDoctorPlain) => (
        <Table
          typeConsultations={typeConsultations}
          key={consultation._id}
          consultation={consultation}
          getAppointmentPlain={getAppointmentPlain}
        />
      ))}
      {consultations?.docs?.length !== 0 && (
        <Pagination
          page={page}
          pageCount={
            consultations?.meta
              ? Math.ceil(consultations?.meta?.totalCount / ITEM_PER_PAGE)
              : 0
          }
          onPageChange={onPageChangeHandler}
        />
      )}
    </React.Fragment>
  );
};
