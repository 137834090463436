import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import moment from "moment"

import { MainNavbar, useTypedSelector } from "common"
import { getUsersSelector, usersActions } from "store/users"
import { Consultation } from "pages/Consultation"
import { doctorsActions, getDoctorsSelector } from "store/doctors"

import { TFormData } from "./types"
import { Dayjs } from "dayjs"

export const NewConsultationPage = () => {
  const dispatch = useDispatch()
  const { id, doctorId } = useParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })

  const [userId, setUserId] = useState<string>("")
  const [formData, setFormData] = useState<TFormData>({
    date: new Date(),
    clinic: "",
    doctor: "",
    speciality: [],
    typeConsultation: "",
  })
  const [startDate, setStartDate] = React.useState<Dayjs | string | Date>(moment().subtract("minutes", -5).toDate());
  const [endDate, setEndDate] = React.useState<Dayjs | string | Date>(moment().subtract("minutes", -35).toDate());

  const { users, user } = useTypedSelector(getUsersSelector)
  const { doctor } = useTypedSelector(getDoctorsSelector)

  useEffect(() => {
    if (id) {
      setUserId(id !== "all" ? id : "")
    }
  }, [id])

  useEffect(() => {
    dispatch(usersActions.getUsers({ limit: 500 }))
    dispatch(doctorsActions.getDoctor({ _id: doctorId }))
  }, [])

  useEffect(() => {
    if (userId) {
      dispatch(usersActions.getUser({ _id: userId }))
    }
  }, [userId])

  useEffect(() => {
    if (doctorId !== "doctorAll") {
      setFormData({
        date: new Date(),
        clinic: "",
        doctor: doctorId ?? "",
        speciality: doctor?.speciality[0] ?? [],
        typeConsultation: "",
      })
      setStartDate(moment().subtract("minutes", -5).toDate())
      setEndDate(moment().subtract("minutes", -35).toDate())
    }
  }, [doctorId, doctor]);

  return (
    <React.Fragment>
      <MainNavbar />
      <Consultation
        register={register}
        errors={errors}
        startDate={startDate as string}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        endDate={endDate as string}
        handleSubmit={handleSubmit}
        formData={formData}
        setFormData={setFormData}
        crudtype="create"
        userId={userId}
        setUserId={setUserId}
        users={users?.docs}
        user={user}
      />
    </React.Fragment>
  )
}
