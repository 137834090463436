import React from "react";

import { Text } from "../Text/Text";

import { TextareaInfo } from "./styled";
import { TTextarea } from "./types";

export const Textarea = ({
  label,
  register,
  defaultValue,
  width,
  name,
  height,
  marginBottom,
}: TTextarea) => {
  return (
    <>
      <Text text={label} margin="0 0 2px 0" />
      <TextareaInfo
        {...register(`${name}`, { required: true })}
        defaultValue={defaultValue}
        marginBottom={marginBottom}
        width={width}
        height={height}
      />
    </>
  );
};
