import ReactDatePicker from "react-datepicker"
import styled from "styled-components"
import Select from "react-select"

import { colors } from "common"

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  margin-bottom: 36px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1040px) {
    justify-content: center;
  }
`

export const ButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
`

export const FormsContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 33px;
  }

  @media (max-width: 1040px) {
    &:not(:last-child) {
      margin-right: 0;
    }
  }
`

export const Image = styled.img`
  position: absolute;
  z-index: 100;
  top: 35px;
  right: 10px;
`

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  margin-top: 16px;

  max-width: 330px;

  .time-input {
    background-color: white;
    width: 330px;
    height: 40.5px;
    overflow-x: hidden;
    border-radius: 8px;
  }

  .time-input:focus {
    outline: none;
  }

  .css-1xhypcz-MuiStack-root {
    overflow: hidden;
  }
`

export const ReactDatePickerStyled = styled(ReactDatePicker)`
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${colors.white};
  margin-bottom: 10px;
  font-size: 14px;
  width: 330px;
  font-size: 14px;
  font-weight: 300;
  padding: 12px 10px;
  margin-top: 2px;

  position: relative;
  &::placeholder {
    font-size: 13px;
  }

  @media (max-width: 460px) {
    width: 260px;
  }
`

export const Input = styled.input`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  padding: 11px;

  width: 306px;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }
`

export const DefaultSelect = styled.select`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  padding: 11px;

  width: 330px;

  cursor: pointer;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 460px) {
    width: 260px;
  }
`

export const StyledSelect = styled(Select)`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  padding: 11px;

  width: 330px;

  cursor: pointer;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 420px) {
    width: 295px;
  }
`
