import { AxiosResponse } from "axios"

import { HttpService } from "../http.service"
import { TResponse } from "../types"

import {
  TGetUsersRequestPayload,
  TGetUserRequestPayload,
  TUpdateUserRequestPayload,
  TGetFilesRequestPayload,
} from "./types"
import { USERS_URL } from "./config"

export class ApiUsersService extends HttpService {
  static getUsers({
    token,
    order = 1,
    skip = 0,
    limit = 20,
    ...params
  }: TGetUsersRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${USERS_URL.getUsers}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        ...params,
        order,
        skip: skip * limit,
        limit,
      },
    })
  }

  static getFiles({
    token,
    filename = "",
    user = "",
    doctor = "",
    skip = 0,
    sortBy,
    order = 1,
    chat = "",
    limit = 20,
  }: TGetFilesRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${USERS_URL.getFiles}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        filename,
        user,
        doctor,
        chat,
        sortBy,
        order,
        skip: skip * limit,
        limit,
      },
    })
  }

  static getUser({
    token,
    _id,
  }: TGetUserRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${USERS_URL.getUser}/${_id}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
  }

  static updateUser({
    data,
    token,
    _id,
  }: TUpdateUserRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${USERS_URL.updateUser}/${_id}`,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    })
  }
}
