import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { appointmentActions, getAppointmentSelector } from "store/appointment";
import { chatActions, getChatSelector } from "store/chat";

import { MainNavbar, RelativePreloader, useTypedSelector } from "common";
import { CurrentResultConsultation } from "pages/CurrentResultConsultation";
import { ChatBody } from "pages/ChatBody";

import { Container, Main } from "./styled";

export const ResultConsultation = () => {
  const [isActiveChat, setIsActiveChat] = useState<boolean>(false)

  const { messageLoading } = useTypedSelector(getChatSelector);

  const dispatch = useDispatch();
  const { id, typeConsultation } = useParams();

  const { currentAppointment, currentAppointmentLoading } = useTypedSelector(
    getAppointmentSelector
  );

  const getCurrrentAppointemnt = () => {
    dispatch(appointmentActions.getCurrentAppointment({ _id: id }));
  };

  useEffect(() => {
    dispatch(chatActions.setMessage(null));
  }, []);

  useEffect(() => {
    getCurrrentAppointemnt();
  }, []);

  useEffect(() => {
    if(currentAppointment?.chat){
      dispatch(chatActions.chatRead({_id: currentAppointment?.chat}));
    }
  }, [currentAppointment?.chat]);

  return (
    <Container>
      {
        !currentAppointmentLoading ?  <>
        {typeConsultation === 'future' &&<MainNavbar type={'chat'} navigate='/futureConsultations' />}
        {typeConsultation === 'current' && <MainNavbar type={'chat'} navigate='/currentConsultation' />}
        {typeConsultation === 'past' && <MainNavbar type={'chat'} navigate="/pastConsultations/doctorAll/userAll" />}
        <Main>
          <>  
            <CurrentResultConsultation
              isActiveChat={isActiveChat}
              setIsActiveChat={setIsActiveChat}
              currentAppointment={currentAppointment}
              getCurrrentAppointemnt={getCurrrentAppointemnt}
            />
            {currentAppointment?.chat && (
              <ChatBody
                isActiveChat={isActiveChat}
                setIsActiveChat={setIsActiveChat}
                idCurrentChat={currentAppointment?.chat}
                loading={messageLoading}
              />
            )}
          </>
        </Main>
      </> : <RelativePreloader loading={currentAppointmentLoading} />
      }
    </Container>
  );
};
