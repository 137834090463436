import styled from "styled-components";

import { colors } from "common/styles/colors";

import { TContainerStyle } from "./types";

export const Container = styled.div<TContainerStyle>`
  padding: 30px 39px;
  width: 100%;
  max-width: 680px;

  border-right: 1px solid ${colors.gray85};

  @media (max-width: 1030px) {
    display: ${({ $isActiveChat }) => !$isActiveChat ? 'block' : 'none'};;
  };
`;

export const NavigateBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const ChatBack= styled.img`
  margin-right: 10px;
  width: 30px;
  height: 30px;
  display: none;
  cursor: pointer;
  transform: rotate(180deg);

  @media (max-width: 1030px) {
    display: block;
  };
`;

export const NavigateChat = styled.p`
  display: none;
  cursor: pointer;
  font-size: 18px;
  color: ${colors.salmon};

  margin-left: 20px;

  @media (max-width: 1030px) {
    display: block;
  };
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const ItemsContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    padding: 10px 15px 15px 0;
  }
`;

export const ItemBlock = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 22px;
  }
`;

export const Image = styled.img``;

export const Item = styled.span`
  margin-left: 6px;
`;

export const Name = styled.span`
  display: block;

  margin-top: 10px;
  margin-bottom: 35px;

  color: ${colors.darkcyan};

  font-size: 17px;
  font-weight: 500;
  line-height: 18px;  

  @media (max-width: 1030px) {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  };
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
