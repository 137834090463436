import { createAction } from "@reduxjs/toolkit"
import { CREATE_CLINIC, GET_CLINICS, SET_CLINICS } from "./consts"
import { TCreateClinicPayload } from "./types"

export const clinicsActions = {
  createClinic: createAction(
    CREATE_CLINIC,
    (payload: TCreateClinicPayload) => ({ payload })
  ),

  getClinics: createAction(GET_CLINICS, (payload) => ({ payload })),
  setClinics: createAction(SET_CLINICS, (payload) => ({ payload })),
}
