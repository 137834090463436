import styled from "styled-components";

export const Form = styled.form`
  margin-top: 16px;
  display: flex;
  width: 320px;
  flex-direction: column;

  @media (max-width: 460px) {
    width: 100%;
    align-items: center;
  }
`;

export const Input = styled.input`
  border: 1px solid white;
  border-radius: 12px;

  background-color: #ECF3F2;

  padding: 13px 14px;

  &::placeholder{
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 460px) {
    width: 240px;
  }
`;

export const Image = styled.img``;

export const RepeatCodeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  margin-bottom: 20px;
`;

export const Captcha = styled.div`
  display: flex;
	justify-content: center;
	margin: 15, 0;
`;