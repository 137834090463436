import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"

import {
  firebaseConfig,
  Assets,
  useTypedSelector,
} from "common"

import { authActions, getAuthSelector } from "store/auth"

import {
  SidebarWrapper,
  MenuWrapper,
  Image,
  ImageBlock,
  MenuName,
  Logo,
  Arrow,
  HeadlineContainer,
  NavLinkContainer,
  HoverBlockNavlinkConsultation,
  Line,
  LogoContainer,
} from "./styled"
import {
  registerSidebarMenus,
  sidebarConsultations,
  sidebarMenus,
  sidebarMenusDoctor,
} from "./sidebarData"
import { TSiderbar, TSiderbarMenus } from "./types"
import { NavLink } from "./components"

export default function Sidebar({
  onMenuItemClick,
  isActive,
  setIsActive,
}: TSiderbar) {
  const dispatch = useDispatch()
  const [isOpenSelectConsultation, setIsOpenSelectConsultation] =
    useState<boolean>(false)

  const refreshToken = () => {
    firebase.initializeApp(firebaseConfig)
    firebase
      .auth()
      .currentUser?.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        dispatch(authActions.setToken(idToken))
      })
      .catch(() => {})
  }

  const onMenuClickHandler = () => {
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    onMenuItemClick
    setIsActive(false)
    refreshToken()
    dispatch(authActions.setNotificationIsOpen(false))
  }

  const { currentDoctorData, type } = useTypedSelector(getAuthSelector)

  useEffect(() => {
    dispatch(authActions.setNotificationIsOpen(false))
  }, [])

  const isOpenMenuHandler = () => {
    setIsOpenSelectConsultation(!isOpenSelectConsultation)
  }

  return (
    <SidebarWrapper $isActive={isActive}>
      <LogoContainer>
        <Logo src={Assets.LOGO} />
      </LogoContainer>
      <MenuWrapper>
        {currentDoctorData && (
          <>
            <NavLinkContainer>
              <HeadlineContainer onClick={isOpenMenuHandler}>
                <ImageBlock>
                  <Image src={Assets.DOCUMENT} />
                </ImageBlock>
                <MenuName>Консультації</MenuName>
                <Arrow
                  $rotate={isOpenSelectConsultation}
                  src={Assets.ARROW_DOWN}
                />
              </HeadlineContainer>
              {isOpenSelectConsultation && (
                <>
                  {sidebarConsultations.map(
                    (menu: TSiderbarMenus, index: number) => {
                      if (
                        (currentDoctorData?.isAdmin == false ||
                          currentDoctorData?.isAdmin == undefined) &&
                        menu.name == "Нова консультація"
                      ) {
                        return
                      }

                      return (
                        <HoverBlockNavlinkConsultation
                          $isActive={isActive}
                          key={menu.name + index}
                          to={menu.path}
                          onClick={onMenuClickHandler}
                        >
                          <MenuName left={20} size={15}>
                            {menu.name}
                          </MenuName>
                        </HoverBlockNavlinkConsultation>
                      )
                    }
                  )}
                </>
              )}
            </NavLinkContainer>
            <Line />
          </>
        )}
        {currentDoctorData ? (
          <>
            {type !== "doctor" ? (
              <NavLink
                sidebarMenusData={sidebarMenus}
                isActive={isActive}
                onMenuClickHandler={onMenuClickHandler}
              />
            ) : (
              <NavLink
                sidebarMenusData={sidebarMenusDoctor}
                isActive={isActive}
                onMenuClickHandler={onMenuClickHandler}
              />
            )}
          </>
        ) : (
          <NavLink
            sidebarMenusData={registerSidebarMenus}
            isActive={isActive}
            onMenuClickHandler={onMenuClickHandler}
          />
        )}
      </MenuWrapper>
    </SidebarWrapper>
  )
}
