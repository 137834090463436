import styled from "styled-components";

import { colors } from "common";

export const Container = styled.div`
  padding: 13px;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Input = styled.input`
  width: 80%;
  border: none;
  
  padding: 10px;
  margin-left: 4px;

  &::placeholder {
    font-size: 14px;
    color: ${colors.gray};
  }

  
  @media (max-width: 1030px) {
    width: 70%;
  };
`;

export const Block = styled.div`
  width: 100% ;
  display: flex;
  align-items: center;
`;

export const SendMessageIcon = styled.img`
  border-radius: 50%;
  
  background-color: ${colors.salmon};

  padding: 7px;

  cursor: pointer;
`;

export const PhotoPickerBlock = styled.div`
  margin-right: 10px;
  margin-bottom: 7px;
`;

export const Text = styled.div`
  font-size: 18px;
  margin: auto;
`;