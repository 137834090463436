import styled from "styled-components";

import { colors } from "common";

export const Container = styled.div`
  overflow-y: hidden;
  position: relative;
`;

export const CalendarContainer = styled.div`
  width: 100%;
`;

export const ImageCopy = styled.img``;

export const ButtonBlock = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;
`;

export const CalendarBlock = styled.div`
  position: absolute;
  top: 15px;
  right: 40%;

  @media (max-width: 1250px) {
    right: 30%;
  }

  @media (max-width: 1100px) {
    top: 60px;
    left: 20px;
  }

  @media (max-width: 770px) {
    top: 90px;
    left: 20px;
  } ;
`;

export const CalnedarContainer = styled.div`
  position: relative;
  display: flex;
`;

export const Select = styled.select`
  z-index: 30;

  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  padding: 11px;

  width: 261px;

  cursor: pointer;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 600px) {
    width: 230px;
  } ;
`;
