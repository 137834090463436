import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

export const firebaseConfig = {
  apiKey: "AIzaSyDLzEvwVo70vy5WGa2WPd_zMYag1OsMrRo",
  authDomain: "your-doctor-83361.firebaseapp.com",
  projectId: "your-doctor-83361",
  storageBucket: "your-doctor-83361.appspot.com",
  messagingSenderId: "9654172218",
  appId: "1:9654172218:web:0402b7880f32555f6b9303",
  measurementId: "G-F2S0NPQ3YE",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
