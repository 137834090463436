import React, { useState } from "react"
import moment from "moment"

import {
  Assets,
  Button,
  genderOptions,
  Modal,
  relationsOptions,
  Text,
} from "common"
import { TFormData } from "pages/EditPatient/types"

import {
  Container,
  Form,
  FormsContainer,
  FormBlock,
  Input,
  Select,
  EditIcon,
  InputBlock,
} from "./styled"
import { TPatient } from "./types"
import { AddressComponent } from "./components"
import { DatePicker, Space } from "antd"
import dayjs from "dayjs"

export const Patient = ({
  user,
  register,
  isAddress,
  setIsAddress,
  setFormData,
  errors,
  inputChangeHandler,
  formData,
  CRUDFunction,
  handleSubmit,
  setCoordinates,
  value,
  setValue,
}: TPatient) => {
  const [openIsModal, setOpenIsModal] = useState<boolean>(false)

  const openIsModalHandler = () => {
    setOpenIsModal(!openIsModal)
  }

  const disabledDate = (current: any) => {
    return current && current >= new Date().setHours(0, 0, 0, 0);
  };

  return (
    <Form onSubmit={handleSubmit(CRUDFunction)}>
      <Container>
        <FormsContainer>
          <FormBlock>
            <Text text=" Ім’я" margin="0 0 2px 0" sup={true} />
            <Input
              {...register("name", {
                required: true,
                pattern: /^([a-zа-яёіїґє\-_' _]+|)$/i,
              })}
              defaultValue={user?.name}
            />
            {errors.name && (
              <Text
                text="Це поле є обов'язковим (в полі потрібно ввести лише літери)"
                color="red"
              />
            )}
          </FormBlock>
          <FormBlock>
            <Text text="Прізвище" margin="0 0 2px 0" sup={true} />
            <Input
              {...register("surname", {
                required: true,
                pattern: /^([a-zа-яёіїґє\-_' _]+|)$/i,
              })}
              defaultValue={user?.surname}
            />
            {errors.surname && (
              <Text
                text="Це поле є обов'язковим (в полі потрібно ввести лише літери)"
                color="red"
              />
            )}
          </FormBlock>
          <FormBlock>
            <Text text="По батькові" margin="0 0 2px 0" sup={true} />
            <Input
              {...register("patronymic", {
                required: true,
                pattern: /^([a-zа-яёіїґє\-_' _]+|)$/i,
              })}
              defaultValue={user?.patronymic}
            />
            {errors.patronymic && (
              <Text
                text="Це поле є обов'язковим (в полі потрібно ввести лише літери)"
                color="red"
              />
            )}
          </FormBlock>
          <FormBlock>
            <Text text="Телефон" margin="0 0 2px 0" sup={true} />
            <Input
              {...register("phone", { required: false })}
              defaultValue={user?.phone}
              disabled={true}
            />
          </FormBlock>
          <FormBlock>
            <Text text="Стать" margin="0 0 2px 0" sup={true} />
            <Select {...register("gender")} defaultValue={user?.gender}>
              {genderOptions.map((option, index: number) => (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              ))}
            </Select>
          </FormBlock>
        </FormsContainer>
        <FormsContainer>
          <FormBlock>
            <Text text="Адреса" margin="0 0 2px 0" />
            <InputBlock>
              <Input
                {...register("address", { required: false })}
                value={
                  isAddress
                    ? `${value ? `${"м."} ${value ?? ""} ${","}` : ""}
 ${formData.street ? `${"вл."} ${formData.street ?? ""} ${","}` : ""}
 ${formData.house ? `${"буд."} ${formData.house ?? ""}${","}` : ""}  
 ${formData.entrance ? `${"п-д."} ${formData.entrance ?? ""}${","}` : ""}
 ${formData.floor ? `${"пов."} ${formData.floor ?? ""}${","}` : ""} 
 ${formData.flat ? `${"кв."} ${formData.flat ?? ""}${","}` : ""} 
 ${
   formData.additionalInfo
     ? `${"інф."} ${formData.additionalInfo ?? ""}${","}`
     : ""
 }`
                    : `${
                        user?.address?.city
                          ? `${"м."} ${user?.address?.city ?? ""} ${","}`
                          : ""
                      }
 ${
   user?.address?.street ? `${"вл."} ${user?.address?.street ?? ""} ${","}` : ""
 }
 ${
   user?.address?.house ? `${"буд."} ${user?.address?.house ?? ""}${","}` : ""
 }  
 ${
   user?.address?.entrance
     ? `${"п-д."} ${user?.address?.entrance ?? ""}${","}`
     : ""
 }
 ${user?.address?.floor ? `${"пов."} ${user?.address?.floor ?? ""}${","}` : ""} 
 ${user?.address?.flat ? `${"кв."} ${user?.address?.flat ?? ""}${","}` : ""} 
 ${
   user?.address?.additionalInfo
     ? `${"інф."} ${user?.address?.additionalInfo ?? ""}${","}`
     : ""
 }`
                }
                disabled={true}
              />
              <EditIcon onClick={openIsModalHandler} src={Assets.EDIT_ICON} />
              {openIsModal && (
                <Modal background="#f5f5f5">
                  <AddressComponent
                    setIsAddress={setIsAddress}
                    setCoordinates={setCoordinates}
                    value={value}
                    setValue={setValue}
                    setOpenIsModal={setOpenIsModal}
                    formData={formData}
                    inputChangeHandler={inputChangeHandler}
                  />
                </Modal>
              )}
            </InputBlock>
          </FormBlock>
          <FormBlock>
          <Text text="Дата народження" margin="0 0 2px 0" />
          <Space>
            <DatePicker
              style={{
                height: "39.6px",
                width: "330px",
                padding: "13.3px",
                borderRadius: "8px",
              }}
              disabledDate={disabledDate}
              placeholder="Обрати дату"
              value={dayjs(formData?.birthday)}
              onChange={(value:any) =>
                setFormData((prevState: any) => ({
                  ...prevState,
                  birthday: value?.$d
                }))
              }
            />
          </Space>
        </FormBlock>
          <FormBlock>
            <Text text="Імейл" margin="0 0 2px 0" />
            <Input
              {...register("email", { required: false })}
              type="email"
              defaultValue={user?.email}
            />
          </FormBlock>
          <FormBlock>
            <Text text="Номер страхового полісу" margin="0 0 2px 0" />
            <Input
              type="number"
              {...register("insurance", { required: false })}
              defaultValue={user?.insurance}
            />
          </FormBlock>
        </FormsContainer>
      </Container>
      <Button title={"Зберегти зміни"} width={694} height={44} size={16} />
    </Form>
  )
}
