import React, { SyntheticEvent, useState } from "react";
import moment from "moment";

import {
  api,
  Assets,
  Button,
  colors,
  downloadFileFunction,
  Text,
} from "common";

import {
  TableContainer,
  NameBlock,
  Photo,
  NavLinkBlock,
  NavLinkContainer,
  Image,
  InfoBlock,
} from "./styled";
import { TTable } from "./types";
import { Input } from "../Forms/styled";
import { useDispatch } from "react-redux";
import { chatActions } from "store/chat";

export const Table = ({ file, getFiles }: TTable) => {
  const dispatch = useDispatch();

  const [fileName, setFileName] = useState<string>(
    file?.file?.filename
      .split(".")
      .slice(0, file?.file?.filename.split(".").length - 1)
      .join(".")
  );

  const [hasChanged, setHasChanged] = useState(false);

  const formatFile = file?.file?.filename
    .split(".")
    .slice(file?.file?.filename.split(".").length - 1);

  const fileNameChangeHandler = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    if (value !== fileName) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
    setFileName(value);
  };

  const editButtonClickHandler = () => {
    dispatch(
      chatActions.renameFile({
        oldName: file?.file?.filename,
        newName: `${fileName}.${formatFile}`,
      })
    );
    getFiles();
    getFiles();
  };

  const changedFileName =
    file?.file?.filename
      .split(".")
      .slice(0, file?.file?.filename.split(".").length - 1)
      .join(".") === fileName;

  const inputElement = React.useRef<HTMLInputElement | null>(null);

  return (
    <TableContainer>
      <NameBlock>
        <Photo src={Assets.GRAY_FILE} />
        <Input
          ref={inputElement}
          width={278}
          value={fileName}
          onChange={fileNameChangeHandler}
        />
      </NameBlock>
      <NavLinkContainer>
        <InfoBlock>
          <Photo src={Assets.GRAY_CALENDAR} />
          <Text text={moment(file?.createdAt).format("DD/MM/YY, HH:mm")} />
        </InfoBlock>
      </NavLinkContainer>
      <NavLinkContainer>
        <InfoBlock style={{ width: "100px" }}>
          {!changedFileName && hasChanged && (
            <Button
              title="Зберегти"
              width={108}
              height={32}
              size={14}
              onClick={editButtonClickHandler}
            />
          )}
        </InfoBlock>

        {!hasChanged && (
          <InfoBlock
            onClick={() => {
              inputElement.current && inputElement.current.focus();
            }}
          >
            <Image src={Assets.EDIT_FILE_NAME} />
            <Text
              text="Редагувати"
              color={`${colors.salmon}`}
              fontWeight={500}
            />
          </InfoBlock>
        )}

        <NavLinkBlock
          onClick={() =>
            downloadFileFunction(
              `${api.withImageAPI}/chat/${file?.file?.filename}`,
              file?.file?.filename
            )
          }
        >
          <Image src={Assets.DOWNLOAD} />
          <Text
            text="Завантажити"
            color={`${colors.salmon}`}
            fontWeight={500}
          />
        </NavLinkBlock>
      </NavLinkContainer>
    </TableContainer>
  );
};
