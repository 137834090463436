import styled from "styled-components";
import { NavLink as NavLinks } from "react-router-dom";

import { TMenuName, TSidebarWrapper } from "pages/Sidebar/types";

export const NavLinkBlock = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 0 6px 21px;

  border-bottom: 1px solid #cfdbd6;

  @media (max-width: 660px) {
    justify-content: flex-start;
    padding: 5px 21px 6px 21px;
  } ;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;

  margin-left: 9px;
  margin-right: 14px;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const HoverBlockNavlink = styled(NavLinks)<TSidebarWrapper>`
  display: flex;
  align-items: center;

  width: 260px;
  height: 39px;

  color: #052822;
  text-decoration: none;
  transition: 0.15s ease-in-out;

  &.active {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top-right-radius: ${({ $isActive }) => ($isActive ? "7px" : "0")};
    border-bottom-right-radius: ${({ $isActive }) => ($isActive ? "7px" : "0")};
    background-color: #c6dbd2;
  }

  &:hover {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top-right-radius: ${({ $isActive }) => ($isActive ? "7px" : "0")};
    border-bottom-right-radius: ${({ $isActive }) => ($isActive ? "7px" : "0")};
    background-color: #c6dbd2;
  }

  @media (max-width: 660px) {
    justify-content: flex-start;
  } ;
`;

export const MenuName = styled.div<TMenuName>`
  color: #052822;
  line-height: 18px;
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
  margin-left: ${({ left }) => (left ? `${left}px` : 0)};
`;