import { AxiosResponse } from "axios";

import { HttpService } from "store/http.service";
import { TResponse } from "store/types";
import { AUTH_URL } from "./config";

import { TCreateAuthRequestPayload } from "./types";

export class ApiAuthService extends HttpService {
	static authMe({ data, token } : TCreateAuthRequestPayload): Promise<AxiosResponse<TResponse>> {
		return this.request<TResponse>({
			url: AUTH_URL.authMe,
			method: 'POST',
			data: data,
			headers: {
				Authorization: token
			}
		})
	};
}