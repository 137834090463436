import React from "react";

import { ButtonBlock } from "./styled";
import { TButton } from "./types";

export const Button = ({
  onSubmit,
  title,
  width,
  height,
  size,
  margin,
  disabled = false,
  onClick,
  color = "#0AC38C",
}: TButton) => {
  return (
    <ButtonBlock
      color={color}
      margin={margin}
      onSubmit={onSubmit}
      disabled={disabled}
      onClick={onClick}
      styleDisabled={disabled}
      width={width}
      height={height}
      size={size}
    >
      {title}
    </ButtonBlock>
  );
};
