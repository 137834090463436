import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  User,
  UserCredential,
} from "firebase/auth"

import { authActions, getAuthSelector } from "store/auth"
import { doctorsActions, getDoctorsSelector } from "store/doctors"
import { showToastAction } from "store/toasts"

import {
  Assets,
  authentication,
  Button,
  colors,
  generateErrorToast,
  LoginWrapper,
  Text,
  useTypedSelector,
} from "common"

import { Form, Input, RepeatCodeBlock, Image, Captcha } from "./styled"

export const LoginCode = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { phone, currentDoctorData } = useTypedSelector(getAuthSelector)
  const { doctors } = useTypedSelector(getDoctorsSelector)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })

  const code = watch("code")

  const isAuthHandler = () => {
    setTimeout(() => {
      if (currentDoctorData === null) {
        dispatch(authActions.activeSidebar(true))
        dispatch(authActions.setIsSeendDoctorMe(false))
        dispatch(authActions.setCurrentId(null))
        navigate("/registerAccount")
      }
    }, 1000)
  }

  const verifyOTP = () => {
    let confirmationResult = window.confirmationResult
    confirmationResult
      .confirm(code)
      .then(({ user }: any) => {
        dispatch(authActions.setToken(user.accessToken))
        dispatch(authActions.setCurrentDoctorData(doctors?.docs[0]))
        dispatch(authActions.setCurrentId(doctors?.docs[0]?._id))
        dispatch(authActions.setIsSeendDoctorMe(true))
        isAuthHandler()
      })
      .catch(() => {
        dispatch(
          showToastAction.request(
            generateErrorToast(
              "Ви ввели не вірно код!. Будь ласка повторіть спробу."
            )
          )
        )
      })
  }

  useEffect(() => {
    dispatch(
      doctorsActions.getDoctors({ phone: phone.split("").slice(1).join("") })
    )
  }, [])

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
      },
      authentication
    )
  }

  const repeatCodeHandler = () => {
    generateRecaptcha()
    let appVerifier = window.recaptchaVerifier
    signInWithPhoneNumber(authentication, phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult
      })
      .catch((error) => {
        console.log(error, "error")
      })
  }

  return (
    <LoginWrapper>
      <Form onSubmit={handleSubmit(verifyOTP)}>
        <Text margin="0 0 2px 0" text="Код з СМС" />
        <Input
          {...register("code", {
            required: true,
            maxLength: 6,
            minLength: 6,
            pattern: /[0-9]/,
          })}
        />
        {errors.code ? (
          <Text
            margin="0 0 13px 0"
            text="Введіть 6 цифр відповідні к СМС"
            color="red"
          />
        ) : (
          <Text margin="0 0 13px 0" />
        )}
        <RepeatCodeBlock onClick={repeatCodeHandler}>
          <Image src={Assets.SYNC_OUTLINE} />
          <Text
            margin="0 0 0 8px"
            text="Відправити код ще раз"
            color={`${colors.salmon}`}
          />
        </RepeatCodeBlock>
        <Captcha id="recaptcha-container"></Captcha>
        <Button title="Підтвердити" size={16} width={320} height={44} />
      </Form>
    </LoginWrapper>
  )
}
