import { createAction } from "@reduxjs/toolkit";

import {
	GET_TOKEN,
	SET_TOKEN,
	SET_PHONE,
	LOGOUT_DOCTOR,
	SET_ID_CURRENT_DOCTOR,
	SET_PASSWORD,
	SET_CURRENT_DOCTOR_DATA,
	SET_AUTH_ME,
	GET_AUTH_ME,
	CREATE_AUTH_ME,
	ACTIVE_SIDEBAR,
	SET_CURRENT_ID,
	SET_TYPE,
	SET_NOTIFICATION_IS_OPEN
} from "./consts";

export const authActions = {
	getToken: createAction(GET_TOKEN, (payload) => ({ payload })),
	setToken: createAction(SET_TOKEN, (payload) => ({payload})),
	setPhone: createAction(SET_PHONE, (payload) => ({payload})),
	logout: createAction(LOGOUT_DOCTOR),
	setPassword: createAction(SET_PASSWORD, (payload) => ({payload})),
	setIsSeendDoctorMe: createAction(SET_ID_CURRENT_DOCTOR, (payload) => ({payload})),
	setCurrentDoctorData: createAction(SET_CURRENT_DOCTOR_DATA, (payload) => ({payload})),
	getAuthMe: createAction(GET_AUTH_ME, (payload) => ({payload})),
	setAuthMe: createAction(SET_AUTH_ME, (payload) => ({payload})),
	createAuthMe: createAction(CREATE_AUTH_ME, (payload) => ({payload})),
	activeSidebar: createAction(ACTIVE_SIDEBAR, (payload) => ({payload})),
	setCurrentId: createAction(SET_CURRENT_ID, (payload) => ({payload})),
	setType: createAction(SET_TYPE, (payload) => ({payload})),
	setNotificationIsOpen: createAction(SET_NOTIFICATION_IS_OPEN, (payload) => ({payload})),
}