import React from "react";

import { TText } from "./types";
import { TextP, Container, Sup } from "./styled";

export const Text = ({
  text,
  fontSize = "14",
  margin = "0",
  fontWeight = 400,
  color = "black",
  $cursor = false,
  onClick,
  sup=false
}: TText) => {
  return (
    <Container>
      <TextP
        fontWeight={fontWeight}
        onClick={onClick}
        color={color}
        margin={margin}
        fontSize={fontSize}
        $cursor={$cursor}
      >
        {text}
      </TextP>
      {sup && <Sup>*</Sup>}
    </Container>
  );
};
