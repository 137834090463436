import styled from "styled-components"

import { colors } from "common"

export const TableContainer = styled.div`
  padding: 12px 16px 12px 22px;
  margin-bottom: 17px;

  border-radius: 4px;

  border-left: 4px solid #e46869;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: white;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  } ;
`

export const NameBlock = styled.div`
  width: 500px;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
  } ;
`

export const Photo = styled.img`
  width: 19px;
  height: 19px;
  margin-right: 6px;
`

export const Image = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 3px;
`

export const NavLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    margin-top: 10px;
    margin-bottom: 15px;
  } ;
`

export const NavLinkBlock = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;

  margin-right: 20px;

  @media (max-width: 1400px) {
    margin-right: 25px;
    margin-top: 5px;
  } ;
`

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 20px;

  @media (max-width: 700px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  } ;
`

export const Link = styled.span`
  color: ${colors.salmon};
  font-weight: 500;
`
