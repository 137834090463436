import styled from "styled-components";

export const Container = styled.div`
  max-width: 700px;
  padding-right: 31px;
  width: 100%;

  @media (max-width: 1010px) {
    padding: 5px;
  }
`;

export const Form = styled.form``;

export const PhotoPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
	
  margin: 13px auto;
`;