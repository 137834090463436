import styled from "styled-components";

import { colors } from "common";

export const Modal = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 1000;
  max-width: 372px;
  width: 100%;
  bottom: 11px;
  padding: 23px;
  background-color: ${colors.white};

  @media (max-width: 560px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 19px;
  }
`;

export const Title = styled.h3`
  margin-bottom: 17px;
  font-size: 16px;
`;

export const EndRepeatBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: ${colors.white};
  border: 3px solid ${colors.salmon};
`;

export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  background-color: ${colors.salmon};
  width: 25px;
  height: 25px;
  margin-left: 10px;
  &:hover ~ ${RadioButtonLabel} {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.salmon};
    &::after {
      content: "";
      border: 3px solid ${colors.white};
      border-radius: 50%;
      color: ${colors.white};
      width: 15px;
      height: 15px;
    }
  }
  &:checked + ${Item} {
    background: ${colors.salmon};
    border: 3px solid ${colors.salmon};
  }
  &:checked + ${RadioButtonLabel} {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.salmon};
    &::after {
      content: "";
      border: 3px solid ${colors.white};
      border-radius: 50%;
      color: ${colors.white};
      width: 15px;
      height: 15px;
    }
  }
`;

export const ButtonBlock = styled.div`
  margin-top: 23px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 560px) {
    justify-content: space-between;
    height: 90px;
    margin-top: 15px;
    width: 150px;
  }
`;
