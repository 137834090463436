import styled from "styled-components";

import { colors } from "common";

export const TableContainer = styled.div`
  padding: 12px 16px 12px 22px;
  margin-bottom: 17px;

  border-radius: 4px;

  border-left: 4px solid ${colors.salmon};

  background-color: ${colors.white};

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    flex-wrap: wrap;  
	  padding: 12px 15px;
  };
`;

export const InfoContainer = styled.div`
  display: flex;
  width: 200px;

  @media (max-width: 1100px) {
    width: 300px;
    margin-left: 20px;
  };
`;

export const NameBlock = styled.div`
  display: flex;
  align-items: center;
	width: 220px;

  @media (max-width: 1000px) {
    width: 100%;
  };
`;

export const Photo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 3px;
`;

export const NavLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    justify-content: center;
  };

  @media (max-width: 1000px) {
    margin-top: 10px;
    margin-bottom: 15px;
    justify-content: space-between;
  };
`;

export const NavLinkBlock = styled.div`
  display: flex;
  align-items: center;
  width: 140px;
  cursor: pointer;
  margin-right: 30px;

  @media (max-width: 1400px) {
    margin-right: 25px;
    margin-top: 5px;
  };
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;	
  margin-right: 20px;
`;

export const Link = styled.span`
  color: ${colors.salmon};
  text-align: left;
  font-weight: 500;
`;
