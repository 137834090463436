import { put, call, takeLatest } from "redux-saga/effects"

import { TDataWrapper, TResponse } from "../types"
import { getAccessToken } from "../auth"

import { notifiactionActions } from "store/notification"

import {
  TGetNotificationRequestPayload, TReadNotificationReques,
} from "./types"
import { ApiNotificationsService } from "./api.service"

function* getNotificationsWorker({ payload }: TDataWrapper<TGetNotificationRequestPayload>) {
  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiNotificationsService, ApiNotificationsService.getNotifications],
      { ...payload, token }
    )
    if (response.data) yield put(notifiactionActions.setNotifications(response.data))
  } catch (e) {
    yield put(notifiactionActions.setNotifications({ data: [], meta: null }))
  }
}


function* readNotifications({ payload }: TDataWrapper<TReadNotificationReques>) {
  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiNotificationsService, ApiNotificationsService.readNotifications],
      { token }
    )
  } catch (e) {}
}

export function* notificationsWatcher() {
  yield takeLatest(notifiactionActions.getNotifications, getNotificationsWorker)
  yield takeLatest(notifiactionActions.readNotifications, readNotifications )
}
