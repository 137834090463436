import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { MainNavbar, useTypedSelector } from "common";
import { InfoAboutDoctor } from "pages/InfoAboutDoctor";
import { doctorsActions, getDoctorsSelector } from "store/doctors";
import { authActions, getAuthSelector } from "store/auth";

import { Container, Main } from "./styled";
import { TFormData, TPhotoFile } from "./types";

export const AccountPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });
  const dispatch = useDispatch();

  const { currentDoctorData, phone, isSeendDoctorMe } =
    useTypedSelector(getAuthSelector);
  const { doctors, response } = useTypedSelector(getDoctorsSelector);

  const [photoFile, setPhotoFile] = useState<TPhotoFile>({ photo: null });
  const [formData, setFormData] = useState<TFormData>(null);

  const doctorData = doctors?.docs?.filter((doc) => doc?.phone === phone)[0];

  useEffect(() => {
    const data = {
      name: currentDoctorData?.name,
      surname: currentDoctorData?.surname,
      patronymic: currentDoctorData?.patronymic,
      phone: currentDoctorData?.phone,
      email: currentDoctorData?.email,
      gender: currentDoctorData?.gender,
      birthday: currentDoctorData?.birthday,
      lang: currentDoctorData?.lang,
      description: currentDoctorData?.description,
      education: currentDoctorData?.education,
      speciality: currentDoctorData?.speciality,
      experience: currentDoctorData?.experience,
    };
    if (isSeendDoctorMe) dispatch(authActions.createAuthMe({ data: data }));
    dispatch(authActions.setIsSeendDoctorMe(false));
  }, [isSeendDoctorMe]);

  const updateAccountHandler = async () => {
    dispatch(authActions.setPhone(formData?.phone));
    const formDataPhoto = new FormData();
    const data = formData;
    photoFile.photo && formDataPhoto.append("photo", photoFile.photo as File);
    await dispatch(
      doctorsActions.updateDoctor({ _id: doctorData?._id, data: data })
    );
    await dispatch(
      doctorsActions.updateDoctor({
        _id: doctorData?._id,
        data: formDataPhoto,
      })
    );
    dispatch(
      doctorsActions.getDoctors({ phone: phone.split("").slice(1).join("") })
    );
  };

  useEffect(() => {
    if (doctors) {
      dispatch(authActions.setCurrentId(doctorData?._id));
      dispatch(authActions.setType(doctorData?.isAdmin ? "admin" : "doctor"));
    }
  }, [doctors]);

  useEffect(() => {
    dispatch(
      doctorsActions.getDoctors({ phone: phone.split("").slice(1).join("") })
    );
  }, []);

  useEffect(() => {
    if (response == "updated") {
      dispatch(
        doctorsActions.getDoctors({
          phone: phone.split("").slice(1).join(""),
        })
      );
    }
  }, [response]);

  useEffect(() => {
    if (doctors) {
      setFormData({
        name: doctorData?.name,
        surname: doctorData?.surname,
        patronymic: doctorData?.patronymic,
        phone: doctorData?.phone,
        email: doctorData?.email,
        gender: doctorData?.gender,
        birthday: doctorData?.birthday,
        lang: doctorData?.lang,
        description: doctorData?.description,
        education: doctorData?.education,
        speciality: doctorData?.speciality,
        experience: doctorData?.experience,
        price: doctorData?.price,
        clinic: doctorData?.clinic,
      });
      dispatch(authActions.setCurrentDoctorData({ ...doctors?.docs[0] }));
    }
  }, [doctors]);

  return (
    <Container>
      <MainNavbar />
      <Main>
        <InfoAboutDoctor
          phone={doctorData?.phone}
          accountPage={true}
          formData={formData}
          photoFile={photoFile}
          setPhotoFile={setPhotoFile}
          setFormData={setFormData}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          doctor={doctorData}
          CRUDFunction={updateAccountHandler}
          title={"Акаунт"}
        />
      </Main>
    </Container>
  );
};
