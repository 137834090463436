import { AxiosResponse } from "axios";

import { HttpService } from "store/http.service";
import { TResponse } from "store/types";
import { APPOINTMENT_URL } from "./config";

import {
  TCreateAppointmentBulkRequestPayload,
  TCreateAppointmentRequestPayload,
  TGetAppointmentByDoctorRequestPayload,
  TCreateAppointmentRepeatSlotRequestPayload,
  TGetDoctorPlainRequestPayload,
  TUpdateAppointmentRequestPayload,
  TDeleteAppointmentRequestPayload,
  TDeleteAppointmentRepeatableRequestPayload,
  TGetCurrentAppointmentRequestPayload,
  TAppointmentCompleteRequestPayload,
  TDeleteAppointmentCurrentDateRequestPayload,
} from "./types";

export class ApiAppointmentService extends HttpService {
  static getAppointmentByDoctor({
    token,
    _id,
    start = "",
    end = "",
    timezone = 2,
  }: TGetAppointmentByDoctorRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${APPOINTMENT_URL.getAppointmentByDoctor}/${_id}/byDoctor`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        start,
        timezone,
        end,
      },
    });
  }

  static getCurrentAppointment({
    token,
    _id,
  }: TGetCurrentAppointmentRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${APPOINTMENT_URL.getCurrentAppointment}/${_id}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    });
  }

  static appointmentComplete({
    token,
    _id,
    data,
  }: TAppointmentCompleteRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${APPOINTMENT_URL.appointmentComplete}/${_id}/complete`,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static getDoctorPlainByDoctor({
    token,
    doctor,
    start = "",
    end = "",
    skip = 0,
    limit = 10,
    order = 1,
    user = "",
    status = "",
    clinic = "",
    sortBy = "startTime",
    speciality = [],
  }: TGetDoctorPlainRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: doctor
        ? `${APPOINTMENT_URL.getDoctorPlain}/${doctor}/plain`
        : `${APPOINTMENT_URL.getDoctorPlain}/plain`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        start,
        end,
        skip: skip * limit,
        limit,
        user,
        sortBy,
        status,
        order,
        clinic,
        speciality,
      },
    });
  }

  static createAppointment({
    data,
    token,
  }: TCreateAppointmentRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: APPOINTMENT_URL.createAppointment,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static createAppointmentBulk({
    data,
    token,
  }: TCreateAppointmentBulkRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: APPOINTMENT_URL.createAppointmentBulk,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static createAppointmentRepeatSlot({
    data,
    token,
  }: TCreateAppointmentRepeatSlotRequestPayload): Promise<
    AxiosResponse<TResponse>
  > {
    return this.request<TResponse>({
      url: APPOINTMENT_URL.appointmentRepeatSlot,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static updateAppointment({
    data,
    token,
    _id,
  }: TUpdateAppointmentRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${APPOINTMENT_URL.updateAppointment}/${_id}`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: token,
      },
    });
  }

  static delteAppointment({
    token,
    _id,
  }: TDeleteAppointmentRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${APPOINTMENT_URL.deleteAppointment}/${_id}`,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    });
  }

  static delteAppointmentRepeatTable({
    token,
    group,
    start,
    end,
    doctor,
    emptyOnly,
  }: TDeleteAppointmentRepeatableRequestPayload): Promise<
    AxiosResponse<TResponse>
  > {
    return this.request<TResponse>({
      url: `${APPOINTMENT_URL.deleteAppointmentRepeatTable}`,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
      params: {
        start,
        doctor,
        emptyOnly,
        group,
        end,
      },
    });
  }

  static deleteAppointmentCurrentDate({
    token,
    doctor,
    date,
  }: TDeleteAppointmentCurrentDateRequestPayload): Promise<
    AxiosResponse<TResponse>
  > {
    return this.request<TResponse>({
      url: `${APPOINTMENT_URL.deleteAppointmentCurrentDate}`,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
      params: {
        doctor,
        date,
      },
    });
  }
}
