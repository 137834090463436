import { createReducer } from "@reduxjs/toolkit";

import { authActions } from "./actions";
import { TInitialState } from "./types";

const InitialState: TInitialState = {
  accessToken: null,
  phone: "",
  isSeendDoctorMe: false,
  password: null,
  currentDoctorData: null,
  currentId: null,
  activeSidebar: false,
  type: "",
  notificationIsOpen: false,
};

export const authReducer = createReducer<TInitialState>(
  InitialState,
  (builder) => {
    builder
      .addCase(authActions.setToken, (state, { payload }) => ({
        ...state,
        accessToken: payload,
      }))
      .addCase(authActions.setPhone, (state, { payload }) => ({
        ...state,
        phone: payload,
      }))
      .addCase(authActions.setIsSeendDoctorMe, (state, { payload }) => ({
        ...state,
        isSeendDoctorMe: payload,
      }))
      .addCase(authActions.setPassword, (state, { payload }) => ({
        ...state,
        password: payload,
      }))
      .addCase(authActions.setCurrentDoctorData, (state, { payload }) => ({
        ...state,
        currentDoctorData: payload,
      }))
      .addCase(authActions.activeSidebar, (state, { payload }) => ({
        ...state,
        activeSidebar: payload,
      }))
      .addCase(authActions.setCurrentId, (state, { payload }) => ({
        ...state,
        currentId: payload,
      }))
      .addCase(authActions.setType, (state, { payload }) => ({
        ...state,
        type: payload,
      }))
      .addCase(authActions.setNotificationIsOpen, (state, { payload }) => ({
        ...state,
        notificationIsOpen: payload,
      }))
      .addCase(authActions.logout, (state) => ({
        ...state,
        idCurrentDoctor: null,
        accessToken: null,
        currentDoctorData: null,
        password: null,
      }));
  }
);
