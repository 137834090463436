import styled from "styled-components";

import { colors } from "common/styles";

import { TContainer } from "./types";

export const Container = styled.div<TContainer>`
  display: flex;
  justify-content: ${({ justify }) => `${justify}`};
  align-items: center;

  background: #ebf2ef;

  padding: 13.5px 12px 13px 30px;

  border-bottom: 1px solid #cedad5;
`;

export const TextBlock = styled.div`
  @media (max-width: 780px) {
    margin-right: 20px;
  }
`;

export const NotifiactionContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const NavigateBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 660px) {
    margin-left: 60px;
  }
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const NavBlock = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin-right: 18px;

  width: 41px;
  height: 41px;

  border-radius: 50%;
  background-color: ${colors.white};
  margin-right: 18px;
`;

export const Notification = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin-right: 18px;

  width: 41px;
  height: 41px;

  border-radius: 50%;
  background-color: ${colors.white};
`;

export const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 30px;
  left: 30px;
  padding: 2px;
  content: "0";
  width: 15px;
  height: 15px;

  border-radius: 50%;
  background-color: ${colors.red};
  color: ${colors.white};

  font-size: 13px;
  line-height: 18px;
`;

export const Image = styled.img`
  cursor: pointer;

  &:hover {
    transition: all 0.4s;
    transform: scale(1.2);
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 21px;
`;

export const NotificationModal = styled.div`
  box-sizing: border-box;
  padding: 20px;
  position: absolute;
  right: 80px;
  top: 50px;
  background-color: ${colors.white};
  width: 400px;
  height: 300px;
  overflow: auto;
  border: 2px solid #dbdbdb;
  border-radius: 5px;
  z-index: 100;

  @media (max-width: 780px) {
    width: 300px;
    height: 300px;
  }

  @media (max-width: 450px) {
    right: 0;
    padding: 10px;
  }

  @media (max-width: 350px) {
    width: 270px;
    margin-right: 10px;
  }
`;
