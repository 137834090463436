import styled from "styled-components";

import { colors } from "common";

import { TTableContainer } from "./types";

export const TableContainer = styled.div<TTableContainer>`
  padding: 12px 16px 12px 22px;
  margin-bottom: 17px;

  border-radius: 4px;

  border-left: 4px solid ${colors.salmon};

  display: flex;
  justify-content: space-between;
  align-items: center;
  
  background:${({ deletedAccount }) => deletedAccount ? `repeating-linear-gradient(-60deg, red, red 2px, transparent 1px, transparent 15px)` : 'white'};

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  };
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameBlock = styled.div`
	width: 180px;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
  };
`;

export const Photo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
`;

export const Contact = styled.span`
  margin-right: 30px;
  margin-left: 20px;

  @media (max-width: 700px) {
    margin-left: 0;
  };
`;

export const Date = styled.span``;

export const Image = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 3px;
`;

export const NavLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    margin-top: 10px;
    margin-bottom: 15px;
  };
`;

export const NavLinkBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  margin-right: 20px;

  @media (max-width: 1400px) {
    margin-right: 25px;
    margin-top: 5px;
  };
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 20px;

  @media (max-width: 700px) {
	  width: 100%;
	  justify-content: space-between;
    margin-top: 10px;
  };
`;

export const Link = styled.span`
  color: ${colors.salmon};
  font-weight: 500;
`;
