import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"

import { MainNavbar, useTypedSelector } from "common"
import { InfoAboutDoctor } from "pages/InfoAboutDoctor"
import { authActions, getAuthSelector } from "store/auth"

import { Container, Main } from "./styled"
import { TFormData, TPhotoFile } from "./types"
import { TClinic } from "store/clinics"

export const RegisterAccountPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })
  const dispatch = useDispatch()

  const { phone, password } = useTypedSelector(getAuthSelector)

  const [photoFile, setPhotoFile] = useState<TPhotoFile>({ photo: null })
  const [formData, setFormData] = useState<TFormData>(null)

  const authMeAccountHandler = async () => {
    const formDataPhoto = new FormData()
    photoFile.photo && formDataPhoto.append("photo", photoFile.photo as File)

    const data = formData
    await dispatch(authActions.activeSidebar(false))
    await dispatch(authActions.createAuthMe({ data }))
    photoFile.photo &&
      (await dispatch(authActions.createAuthMe({ data: formDataPhoto })))
  }

  useEffect(() => {
    setFormData({
      name: "",
      surname: "",
      patronymic: "",
      password: password,
      phone: phone,
      email: "",
      gender: "",
      birthday: new Date(new Date().setDate(new Date().getDate() -1)).toISOString(),
      lang: "", 
      description: "",
      education: "",
      speciality: [],
      experience: "",
      price: 1,
      clinic: "",
    })
  }, [])

  return (
    <Container>
      <MainNavbar type="chat" />
      <Main>
        <InfoAboutDoctor
          registerAccount={true}
          formData={formData}
          setFormData={setFormData}
          photoFile={photoFile}
          phone={phone}
          setPhotoFile={setPhotoFile}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          CRUDFunction={authMeAccountHandler}
          title={"Акаунт"}
        />
      </Main>
    </Container>
  )
}
