import styled from "styled-components";

export const Form = styled.form``;

export const Close = styled.img`
  cursor: pointer;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 90px;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 10px;
  border: 1px solid white;
  resize: none;
`;

export const ButtonBlock = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TextAreaBlock = styled.div`
 display:flex;
 flex-direction: column;
`;