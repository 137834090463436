import { put, call, takeLatest } from "redux-saga/effects"

import { TDataWrapper, TResponse } from "../types"
import { getAccessToken } from "../auth"

import { usersActions } from "./actions"
import { ApiUsersService } from "./api.service"
import { showToastAction } from "store/toasts"
import { generateErrorToast, generateSuccessToast } from "common"

import {
  TGetfilesPayload,
  TGetUserPayload,
  TGetUsersPayload,
  TUpdateUserPayload,
} from "./types"

function* getUsersWorker({ payload }: TDataWrapper<TGetUsersPayload>) {
  yield put(usersActions.setUsersState({ loading: true }))

  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiUsersService, ApiUsersService.getUsers],
      { ...payload, token }
    )
    if (response.data) yield put(usersActions.setUsers(response.data))
  } catch (e) {
    yield put(usersActions.setUsers({ docs: [], meta: null }))
  }
  yield put(usersActions.setUsersState({ loading: false }))
}

function* getFilesWorker({ payload }: TDataWrapper<TGetfilesPayload>) {
  yield put(usersActions.setUsersState({ loadingFiles: true }))

  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiUsersService, ApiUsersService.getFiles],
      { ...payload, token }
    )
    if (response.data) yield put(usersActions.setFiles(response.data))
  } catch (e) {
    yield put(usersActions.setFiles({ docs: [], meta: null }))
  }
  yield put(usersActions.setUsersState({ loadingFiles: false }))
}

function* getUserWorker({ payload }: TDataWrapper<TGetUserPayload>) {
  yield put(usersActions.setUsersState({ userLoading: true }))

  const { _id } = payload
  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiUsersService, ApiUsersService.getUser],
      { _id, token }
    )
    if (response.data) {
      yield put(usersActions.setUser({ ...response.data }))
      yield put(usersActions.setUsersState({ userLoading: false }))
    }
  } catch (e) {}
}

function* updateUserWorker({ payload }: TDataWrapper<TUpdateUserPayload>) {
  const { _id, data } = payload
  const token: string = yield call(getAccessToken)

  try {
    yield call([ApiUsersService, ApiUsersService.updateUser], {
      _id,
      data,
      token,
    })
    yield put(
      showToastAction.request(generateSuccessToast("Пацієнт успішно оновлений"))
    )
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast("Помилка оновлення пацієнта"))
    )
  }
}

export function* usersWatcher() {
  yield takeLatest(usersActions.getUsers, getUsersWorker)
  yield takeLatest(usersActions.getUser, getUserWorker)
  yield takeLatest(usersActions.updateUser, updateUserWorker)
  yield takeLatest(usersActions.getFiles, getFilesWorker)
}
