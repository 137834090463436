import { AxiosResponse } from "axios"

import { HttpService } from "../http.service"
import { TResponse } from "../types"

import { TGetNotificationRequestPayload, TReadNotificationReques } from "./types"
import { NOTIFICATION_URL } from "./config"

export class ApiNotificationsService extends HttpService {
  static getNotifications({
    token,
    limit = 500,
    read = 'unread',
  }: TGetNotificationRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${NOTIFICATION_URL.getNotification}`,
      method: "GET",
      params: {
        limit,
        read,
      },
      headers: {
        Authorization: token,
      }
    })
  }

  static readNotifications({
    token,
  }: TReadNotificationReques): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${NOTIFICATION_URL.readNotifications}`,
      method: "POST",
      headers: {
        Authorization: token,
      },
    })
  }
}