import { AxiosResponse } from "axios"

import { HttpService } from "../http.service"
import { TResponse } from "../types"

import {
  TGetChatRequestPayload,
  TGetChatIdMessagesRequestPayload,
  TGetChatIdRequestPayload,
  TCreateMessageRequestPayload,
  TChatReadRequestPayload,
  TRenameFileRequestPayload,
} from "./types"
import { CHAT_URL } from "./config"
import { method } from "lodash"

export class ApiChatService extends HttpService {
  static getChat({
    token,
    skip = 0,
    limit = 5,
    doctorName,
    userName,
    doctorId,
    userId,
  }: TGetChatRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${CHAT_URL.getChat}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        skip: skip * limit,
        limit,
        doctorName,
        userName,
        doctorId,
        userId,
      },
    })
  }

  static getCurrentChatData({
    token,
    _id,
  }: TGetChatIdRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${CHAT_URL.getCurrentChatData}/${_id}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
  }

  static getChatIdMessages({
    token,
    _id,
    limit = 15,
    skip = 0,
  }: TGetChatIdMessagesRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${CHAT_URL.getChatIdMessages}/${_id}/messages`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        skip: skip * limit,
        limit,
      },
    })
  }

  static createMessage({
    data,
    token,
    _id,
  }: TCreateMessageRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${CHAT_URL.createMessage}/${_id}/message`,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    })
  }

  static chatRead({
    token,
    _id,
  }: TChatReadRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${CHAT_URL.chatRead}/${_id}/read`,
      method: "POST",
      headers: {
        Authorization: token,
      },
    })
  }

  static renameFile({
    token,
    ...payload
  }: TRenameFileRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${CHAT_URL.renameFile}`,
      method: "PATCH",
      data: { ...payload },
      headers: {
        Authorization: token,
      },
    })
  }
}
