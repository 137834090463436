export const colors = {
	black: '#222222',
	salmon: '#E46869',
	green: '#24A56C',
	gray: '#6D7B75',
	darkGreen: '#07493D',
	background: '#EDF2F0',
	darkcyan: '#116A5A',
	sidebarBlack: '#052822',
	bgSidebar: '#E3EBE8',
	bgMain: '#CEDAD5',
	disabled: '#C0D4CC',
	white: '#FFFFFF',
	lightGreen: '#0AC38C',
	red: '#E33B3C',
	buttonStyle: '#27B275',
	disabledButton: '#5EA685',
	whiteSmode: '#f5f5f5',
	gray85: '#D9D9D9',
	calendarSlotBg: '#E5ECF3',
}; 