import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import endOfMinute from "date-fns/endOfMinute";
import startOfMinute from "date-fns/esm/fp/startOfMinute";

import {
  Assets,
  Button,
  Checkbox,
  generateErrorToast,
  useTypedSelector,
  Text,
  colors,
} from "common";
import { appointmentActions } from "store/appointment";
import { showToastAction } from "store/toasts";

import { ModalToRemoveConsultations } from "../ModalToRemoveConsultations";
import { RepeatConsultation } from "../RepeatConsultation";
import { TCalendarEventData } from "../ModalInfoEventCalendar/types";
import { Timing } from "../Timing";

import {
  Container,
  Title,
  Header,
  ClosePopup,
  ButtonBlock,
  CheckboxBlock,
  FormContainer,
} from "./styled";
import { TPopupOfCalendar } from "./types";
import { getAuthSelector } from "store/auth";

export const PopupOfCalendar = ({
  isEditCard,
  calendarEventData,
  inputChangeHandler,
  setModalEventCalendarIsOpen,
  setCalendarEventData,
  doctorId,
  doctor,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: TPopupOfCalendar) => {
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm<TCalendarEventData>({
    mode: "onSubmit",
  });

  const { type } = useTypedSelector(getAuthSelector);

  const [checked, setChecked] = useState<boolean>(false);
  const [daysOfWeekResult, setDaysOfWeekResult] = useState<string[]>([]);
  const [selectRepeatTo, setSelectRepeatTo] = useState<string>("day");
  const [modalIsActive, setModalIsActive] = useState<boolean>(false);
  const [dateFinish, setDateFinish] = useState<Date>(new Date());

  const startTimeConsultation = moment(calendarEventData?.date).format(
    `dddd, MMMM DD YYYY ${moment(
      startDate?.$d ? startDate?.$d : startDate
    ).format("HH:mm:ss")}`
  );
  const endTimeConsultation = moment(calendarEventData?.date).format(
    `dddd, MMMM DD YYYY ${moment(endDate?.$d ? endDate?.$d : endDate).format(
      "HH:mm:ss"
    )}`
  );

  const selectDate =
    selectRepeatTo === "day"
      ? moment(dateFinish).format("YYYY-MM-DD")
      : moment(calendarEventData.date).add(1, "year").format("YYYY-MM-DD");

  const createAppointment = () => {
    if (
      moment(startTimeConsultation).format() >
      moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
    ) {
      if (!checked) {
        dispatch(
          appointmentActions.createAppointment({
            data: {
              type: calendarEventData?.typeConsultation,
              startTime: endOfMinute(new Date(startTimeConsultation)),
              endTime: startOfMinute(new Date(endTimeConsultation)),
              doctor: doctorId,
              speciality: calendarEventData?.speciality,
              clinic: doctor?.clinic,
            },
          })
        );
      } else {
        dispatch(
          appointmentActions.createAppointmentRepeatSlot({
            data: {
              clinic: doctor?.clinic,
              speciality: calendarEventData?.speciality,
              type: calendarEventData?.typeConsultation,
              startTime: endOfMinute(new Date(startTimeConsultation)),
              endTime: startOfMinute(new Date(endTimeConsultation)),
              daysOfWeek: [...daysOfWeekResult],
              doctor: doctorId,
              repeatFrom: moment(calendarEventData?.date).format("YYYY-MM-DD"),
              repeatTo: selectDate,
            },
          })
        );
      }
      setModalEventCalendarIsOpen(false);
    } else {
      dispatch(
        showToastAction.request(
          generateErrorToast("Консультацію на минулий час не можливо створити!")
        )
      );
    }
  };

  const updateAppointment = () => {
    dispatch(
      appointmentActions.updateAppointment({
        data: {
          type: calendarEventData?.typeConsultation,
          startTime: endOfMinute(new Date(startTimeConsultation)),
          endTime: startOfMinute(new Date(endTimeConsultation)),
          doctor: doctorId,
          status:
            calendarEventData.backgroundColor === "#E46869"
              ? "reserved"
              : "empty",
          speciality: calendarEventData?.speciality,
          clinic: doctor?.clinic,
        },
        _id: calendarEventData?.id,
      })
    );
    setModalEventCalendarIsOpen(false);
  };

  const checkedHandler = () => {
    setChecked(!checked);
  };

  const handleIsModalActive = () => {
    setModalIsActive(true);
  };

  if (!isEditCard && type != "admin") {
    setModalEventCalendarIsOpen(false);
    setModalIsActive(true);
  }

  return (
    <Container>
      <Header>
        {!isEditCard ? (
          <Title>Новий слот розкладу</Title>
        ) : (
          <Title>Редагувати cлот розкладу</Title>
        )}
        <ClosePopup
          src={Assets.CLOSE}
          onClick={() => setModalEventCalendarIsOpen(false)}
        />
      </Header>
      <FormContainer>
        <Timing
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setCalendarEventData={setCalendarEventData}
          register={register}
          calendarEventData={calendarEventData}
          inputChangeHandler={inputChangeHandler}
          doctorId={doctorId as string}
        />
        {!isEditCard && (
          <CheckboxBlock>
            <Checkbox checked={checked} onChange={checkedHandler} />
            <Text margin="0 0 0 10px" text="Повторювати" />
          </CheckboxBlock>
        )}
        {checked === true && (
          <RepeatConsultation
            setDaysOfWeekResult={setDaysOfWeekResult}
            daysOfWeekResult={daysOfWeekResult}
            checked={checked}
            setDateFinish={setDateFinish}
            dateFinish={dateFinish}
            select={selectRepeatTo}
            setSelect={setSelectRepeatTo}
          />
        )}
        {!isEditCard ? (
          <ButtonBlock>
            <Button
              width={414}
              onClick={handleSubmit(createAppointment)}
              height={40}
              title={"Створити"}
              size={16}
            />
          </ButtonBlock>
        ) : (
          <ButtonBlock>
            {moment(startTimeConsultation).format("YYYY-MM-DDTHH:mm:ss:ms") >=
              moment(new Date()).format("YYYY-MM-DDTHH:mm:ss:ms") && (
              <>
                {type == "admin" && (
                  <>
                    <Button
                      width={200}
                      height={40}
                      color={`${colors.salmon}`}
                      onClick={handleIsModalActive}
                      title={"Видалити запис"}
                      size={16}
                    />
                    <Button
                      width={200}
                      height={40}
                      onClick={handleSubmit(updateAppointment)}
                      title={"Зберегти зміни"}
                      size={16}
                    />
                  </>
                )}
              </>
            )}
          </ButtonBlock>
        )}
      </FormContainer>
      {modalIsActive && (
        <ModalToRemoveConsultations
          endDate={endDate as string}
          startDate={startDate as string}
          dateFinish={dateFinish}
          setModalEventCalendarIsOpen={setModalEventCalendarIsOpen}
          doctorId={doctorId}
          calendarEventData={calendarEventData}
          setModalIsActive={setModalIsActive}
        />
      )}
    </Container>
  );
};
