import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const InputBlock = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
`;

export const Container = styled.div`
  max-width: 695px;
  margin-bottom: 36px;
  width: 100%;

  margin-top: 24px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1020px) {
    justify-content: center;
  }
`;

export const FormsContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 33px;
  }

  @media (max-width: 1020px) {
    &:not(:last-child) {
      margin-right: 0px;
    }
  }
`;

export const EditIcon = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    transition: all 0.4s;
    transform: scale(1.2);
  }
`;

export const Image = styled.img`
  position: absolute;
  z-index: 100;
  top: 30px;
  right: 10px;
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  margin-top: 16px;

  max-width: 330px;
`;

export const Input = styled.input`
  border: 1px solid white;
  border-radius: 8px;

  background-color: white;

  padding: 11px;

  width: 306px;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 400px) {
    width: 276px;
  }
`;

export const Select = styled.select`
  border: 1px solid white;
  border-radius: 8px;

  background-color: white;

  padding: 11px;

  width: 330px;

  cursor: pointer;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 400px) {
    width: 300px;
  }
`;
