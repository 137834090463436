import { styled } from "@mui/material/styles";

import { colors } from "common";

export const ContainerCalendar = styled("div")`
  @media (max-width: 768px) {
    .fc-toolbar.fc-header-toolbar {
      display: flex;
      flex-direction: column;
    }
  }

  .fc .fc-scrollgrid {
      background-color: white;
  }

  .fc-v-event .fc-event-main-frame {
    color: ${colors.black};
    font-weight: bold;
  }

  .fc-timegrid-event .fc-event-time {
    white-space: nowrap;
    font-size: 0.85em;
    font-size: var(--fc-small-font-size, 0.85em);
    margin-bottom: 1px;

    @media (max-width: 1000px) {
      white-space: pre-wrap;
    }

    @media (max-width: 400px) {
      text-align: center;
    }
  }

  .fc-day-today {
    background: ${colors.white} !important;
  }

  .fc-v-event .fc-event-title {
    font-size: 15px;

    @media (max-width: 1000px) {
      font-size: 10px;
    }
  }

  .fc .fc-timegrid-slot-minor {
    cursor: pointer;
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    cursor: pointer;
  }

  .fc .fc-timegrid-slot {
    height: 65px;
    border-bottom: 0;
  }

  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion {
    padding-left: 5px;
  }

  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion::after {
    padding-left: 5px;
    position: relative;
    right: 5px;
    content: ":00";
  }

  .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    margin-top: 12px;
    padding: 6px;
    background: transparent;
    color: ${colors.darkcyan};
    font-size: 16px;
    font-weight: 700;
    margin: 4px;
    border-radius: 6px;
    border: 1px solid ${colors.darkcyan};
  }

  .fc-direction-ltr .fc-button-group > .fc-button:not(:first-of-type) {
    margin-top: 12px;
    padding: 6px;
    background: transparent;
    color: ${colors.darkcyan};
    font-size: 16px;
    font-weight: 500;
    margin: 4px;
    border-radius: 6px;
    border: 1px solid ${colors.darkcyan};
  }

  .fc .fc-button-group {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    margin-top: 12px;
    color: ${colors.darkcyan};
  }

  .fc .fc-toolbar-title {
    font-size: 22px;
    color: ${colors.black};
    margin-left: 30px;
    margin: 0;
  }

  .fc .fc-toolbar-title::first-letter {
    text-transform: uppercase;
  }

  .fc .fc-toolbar.fc-header-toolbar {
    padding: 0 20px;
    margin-bottom: 35px;
    background-color: ${colors.whiteSmode};

    @media (max-width: 1100px) {
      margin-bottom: 100px;
    }
  }

  .fc .fc-scrollgrid-liquid {
    height: 100%;
    background: ${colors.white};
  }

  .fc-scrollgrid-section.fc-scrollgrid-section-header {
    border: none;
  }

  .fc-timeGridWeek-view .fc-timegrid-now-indicator-arrow {
    display: none;
  }

  .fc-timeGridWeek-view .fc-timegrid-now-indicator-line::before {
    content: "";
    position: absolute;
    top: -6px;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: ${colors.red};
    border-radius: 50%;
  }

  .fc .fc-timegrid-axis-cushion,
  .fc .fc-timegrid-slot-label-cushion {
    padding: 0 4px;
    color: ${colors.gray};
  }

  .fc-timegrid-event .fc-event-main {
    padding: 1px 1px 0;
  }

  .fc-timegrid-event-harness {
    position: absolute;
    opacity: 0.6;
  }

  .fc-v-event {
    border: none;
    border-left: 2px solid ${colors.green};
    box-shadow: 4px 4px 3px #C8C8C8;
  }

  .fc-scrollgrid-sync-inner {
    display: flex;
    flex-direction: column;
  }

  .fc-col-header-cell.fc-day.fc-day-today {
    position: relative;
    z-index: 1;
    color: ${colors.black};
  }

  .fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 12px;
    justify-content: center;
    align-items: center;
    width: 50px;
  }

  .fc-scrollgrid-sync-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
  }

  .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion {
    position: relative;
  }

  .fc-timegrid-event .fc-event-time {
    font-size: 13px;
  }

  .fc-day-today .fc-scrollgrid-sync-inner .fc-col-header-cell-cushion::after {
    position: absolute;
    z-index: -1;
    content: "";
    width: 25px;
    height: 25px;
    background-color: ${colors.salmon};
    border-radius: 50%;
    right: 12px;
    bottom: 8.5px;
  }
`;
