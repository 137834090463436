import React, { SyntheticEvent, useEffect, useState } from "react";
import moment from "moment";

import { Modal } from "common";
import { PopupOfCalendar } from "../Popup";

import { IModalInfosEventCalendaryProps, TCalendarEventData } from "./types";
import { Dayjs } from "dayjs";

export const ModalInfosEventCalendar = ({
  modalEventCalendarIsOpen,
  eventInfos,
  isEditCard,
  doctorId,
  setModalEventCalendarIsOpen,
  doctor,
}: IModalInfosEventCalendaryProps) => {
  const [calendarEventData, setCalendarEventData] =
    useState<TCalendarEventData>({
      id: "",
      date: new Date(),
      groupId: "",
      typeConsultation: "online",
      speciality: "",
      clinic: doctor?.clinic,
      backgroundColor: "",
      user: ''
    });
  const [startDate, setStartDate] = React.useState<Dayjs | string | null>("");
  const [endDate, setEndDate] = React.useState<Dayjs | string | null>("");

  useEffect(() => {
    if (isEditCard) {
      setCalendarEventData({
        id: eventInfos?.event?.id,
        date: eventInfos?.event?.start,
        groupId: eventInfos?.event?.groupId,
        typeConsultation: eventInfos?.event?.title,
        speciality: eventInfos?.event?.extendedProps?.speciality,
        clinic: doctor?.clinic,
        backgroundColor: eventInfos?.event?.backgroundColor,
        user: eventInfos?.event?.extendedProps?.user?._id
      });
      setStartDate(eventInfos?.event?.start);
      setEndDate(eventInfos?.event?.end);
    } else {
      setCalendarEventData({
        id: "",
        date: eventInfos?.dateStr,
        groupId: "",
        typeConsultation: "online",
        speciality: doctor?.speciality[0],
        clinic: doctor?.clinic,
        backgroundColor: "",
        user: ''
      });
      setStartDate(eventInfos?.dateStr);
      setEndDate(
        moment(eventInfos?.dateStr).subtract("minutes", -30).toDate() as any
      );
    }
  }, [eventInfos, isEditCard, doctor]);

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setCalendarEventData((props: TCalendarEventData) => ({
      ...props,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (eventInfos?.event?.title.includes("Онлайн")) {
      setCalendarEventData((prevState: TCalendarEventData) => ({
        ...prevState,
        typeConsultation: "online",
      }));
    }
    if (eventInfos?.event?.title.includes("Всі")) {
      setCalendarEventData((prevState: TCalendarEventData) => ({
        ...prevState,
        typeConsultation: "all",
      }));
    }
    if (eventInfos?.event?.title.includes("Клініка")) {
      setCalendarEventData((prevState: TCalendarEventData) => ({
        ...prevState,
        typeConsultation: "clinic",
      }));
    }
  }, [eventInfos, isEditCard]);

  return (
    <>
      {modalEventCalendarIsOpen && (
        <Modal background={"#F1F5F4"}>
          <PopupOfCalendar
            endDate={endDate}
            setEndDate={setEndDate}
            startDate={startDate}
            setStartDate={setStartDate}
            setCalendarEventData={setCalendarEventData}
            setModalEventCalendarIsOpen={setModalEventCalendarIsOpen}
            doctorId={doctorId}
            isEditCard={isEditCard}
            calendarEventData={calendarEventData}
            inputChangeHandler={inputChangeHandler}
            doctor={doctor}
          />
        </Modal>
      )}
    </>
  );
};
