import styled from "styled-components";

import { colors } from "common";

export const Container = styled.div``;

export const Main = styled.div`
  padding: 42px 30px;

  background-color: ${colors.whiteSmode};
`;