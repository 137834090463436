import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { api, Assets, useTypedSelector, Text, colors } from "common";

import { appointmentActions } from "store/appointment";
import { getAuthSelector } from "store/auth";

import {
  TableContainer,
  NameBlock,
  Photo,
  NavLinkBlock,
  NavLinkContainer,
  Image,
  DeleteIcon,
  Span,
} from "./styled";
import { TTable } from "./types";

export const Table = ({
  consultation,
  typeConsultations,
  getAppointmentPlain,
}: TTable) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { type } = useTypedSelector(getAuthSelector);

  const navigatePage = (url: string) => {
    navigate(url);
  };

  const deleteAppointment = (id: string) => {
    dispatch(appointmentActions.deleteAppointment({ _id: id }));
    getAppointmentPlain();
    getAppointmentPlain();
    getAppointmentPlain();
  };

  return (
    <TableContainer>
      <NameBlock>
        <Photo
          src={
            consultation?.user?.photo
              ? `${api.withImageAPI}/user/${consultation?.user?.photo}`
              : Assets.ACCOUNT
          }
        />
        <Text
          text={`${consultation?.user?.surname ?? "-"} ${
            consultation?.user?.name ?? "-"
          } ${consultation?.user?.patronymic ?? "-"}`}
        />
      </NameBlock>
      {type === "admin" && (
        <NameBlock>
          <Photo
            src={
              consultation?.doctor?.photo
                ? `${api.withImageAPI}/doctor/${consultation?.doctor?.photo}`
                : Assets.ACCOUNT
            }
          />
          <Text
            text={`${consultation?.doctor?.surname} ${consultation?.doctor?.name} ${consultation?.doctor?.patronymic}`}
          />
          <Span style={{ paddingLeft: "18px" }}>
            (
            {consultation?.doctor?.speciality.map((item) => t(item)).join(", ")}
            )
          </Span>
        </NameBlock>
      )}
      <NavLinkContainer>
        <NavLinkBlock>
          <Image src={Assets.GRAY_CALENDAR} />
          <Text text={moment(consultation?.startTime).format("DD/MM")} />
        </NavLinkBlock>
        <NavLinkBlock>
          <Image src={Assets.CLOCK} />
          <Text
            text={`${moment(consultation?.startTime).format("HH:mm")}-${moment(
              consultation?.endTime
            ).format("HH:mm")}`}
          />
        </NavLinkBlock>
        <NavLinkBlock>
          <Image src={Assets.LOCATION} />
          <Text text={t(consultation?.type)} />
        </NavLinkBlock>
      </NavLinkContainer>
      <NavLinkContainer>
        <NavLinkBlock
          onClick={() =>
            navigatePage(
              `/resultConsultation/${consultation?._id}/${typeConsultations}`
            )
          }
        >
          <Image src={Assets.CHAT} />
          <Text fontWeight={500} text="Чат" color={`${colors.salmon}`}></Text>
        </NavLinkBlock>
        {typeConsultations === "future" && (
          <NavLinkBlock
            onClick={() =>
              navigatePage(`/updateConsultation/${consultation?._id}`)
            }
          >
            <Image src={Assets.EDIT} />
            <Text
              fontWeight={500}
              text="Редагувати"
              color={`${colors.salmon}`}
            ></Text>
          </NavLinkBlock>
        )}
        {typeConsultations === "past" && (
          <NavLinkBlock
            onClick={() =>
              navigatePage(
                `/resultConsultation/${consultation?._id}/${typeConsultations}`
              )
            }
          >
            <Image src={Assets.DOCUMENT} />
            <Text
              fontWeight={500}
              text="Результати"
              color={`${colors.salmon}`}
            ></Text>
          </NavLinkBlock>
        )}
        {typeConsultations === "current" && (
          <NavLinkBlock
            onClick={() =>
              navigatePage(
                `/resultConsultation/${consultation?._id}/${typeConsultations}`
              )
            }
          >
            <Image src={Assets.DOCUMENT} />
            <Text
              fontWeight={500}
              text="Результати"
              color={`${colors.salmon}`}
            ></Text>
          </NavLinkBlock>
        )}
        {type === "admin" && (
          <>
            {typeConsultations === "future" && (
              <DeleteIcon
                onClick={() => deleteAppointment(consultation?._id)}
                src={Assets.DELETE_ICON}
              />
            )}
          </>
        )}
      </NavLinkContainer>
    </TableContainer>
  );
};
