import React from "react";
import moment from "moment";

import { api, Assets } from "common";

import {
  Contaienr,
  Message,
  MessageBlock,
  Time,
  Photo,
} from "./styled";
import { TUserMessage } from "./types";
import { MessageBody } from "../MessageBody";

export const UserMessageContainer = ({
  userMessageData,
  currentChatData,
}: TUserMessage) => {
  return (
    <Contaienr>
      <MessageBlock>
        <Photo src={currentChatData?.user?.photo ? `${api.withImageAPI}/user/${currentChatData?.user?.photo}` : Assets.ACCOUNT} />
        <Message>
          {userMessageData?.files?.map((el) => (
            <MessageBody el={el} key={el._id}/>
          ))}
          {userMessageData?.message}
        </Message>
      </MessageBlock>
      <Time>{moment(userMessageData?.createdAt).format("HH:mm")}</Time>
    </Contaienr>
  );
}