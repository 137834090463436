import styled from "styled-components";

import { colors } from "common";

export const Container = styled.div`
  position: relative;
  width: 420px;

  @media (max-width: 560px) {
    width: 290px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClosePopup = styled.img`
  padding: 7px;
  cursor: pointer;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 16px;
  color: ${colors.black}
`;

export const FormContainer = styled.div`
  margin-top: 21px;
`;

export const Label = styled.label`
  color: #000;
  line-height: 20px;
  font-size: 14px;
  color: ${colors.black}
`;

export const ButtonBlock = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
  flex-wrap: wrap;

  @media (max-width: 560px) {
    justify-content: center;
    height: 100px;
  }
`;

export const CheckboxBlock = styled.div`
  display: flex;
  align-items: center;
  
  margin-top: 20px;
`;
