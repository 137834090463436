import styled, { css } from "styled-components"

import { Assets, colors } from "common"

import { TImageButton } from "./types"

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .photo-input {
    display: none;
  }
`

export const ContainerPhotoPicker = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
`

export const PhotoPickerBlock = styled.div`
  position: relative;
  display: flex;
`

export const PlusIcon = styled.img.attrs({
  src: Assets.PHOTO_ICON,
})`
  transition: all 0.4s ease;
  width: 37px;
  height: auto;
`

export const AttachIcon = styled.img.attrs({
  src: Assets.ATTACH,
})`
  transition: all 0.4s ease;
  width: 37px;
  height: auto;
`

export const ImageButton = styled.button<TImageButton>`
  cursor: pointer;
  max-width: 90%;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  position: relative;
  background-color: ${({ nameImage }) =>
    nameImage ? "transparent" : `${colors.bgSidebar}`};
  border: none;
  border-radius: ${({ roundCorners }) => (roundCorners ? "50%" : "0%")};
  ${({ src }) =>
    src &&
    css<{ roundCorners?: boolean }>`
      border-radius: ${({ roundCorners }) => (roundCorners ? "50%" : "0%")};

      ${PlusIcon} {
        display: none;
      }

      ${AttachIcon} {
        display: none;
      }
    `}

  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
`
