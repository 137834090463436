import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import { MainNavbar, useTypedSelector } from "common"
import { InfoAboutDoctor } from "pages/InfoAboutDoctor"
import { doctorsActions, getDoctorsSelector } from "store/doctors"

import { Main } from "./styled"
import { TFormData, TPhotoFile } from "./types"
import moment from "moment"

export const CreateDoctorPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { doctorId } = useTypedSelector(getDoctorsSelector)

  const [photoFile, setPhotoFile] = useState<TPhotoFile>({
    photo: null,
  })
  const [formData, setFormData] = useState<TFormData>({
    birthday: moment().subtract(18, 'years').format('YYYY-MM-DD'),
    name: '',
    surname: '',
    patronymic: '',
    phone: '',
    email: '',
    gender: '',
    lang: '',
    description: '',
    education: '',
    speciality: [],
    experience: '',
    price: 0,
    clinic: '',
  });

  const createDoctor = async () => {
    const data = formData
    await dispatch(doctorsActions.createDoctor({ data }))
  }

  useEffect(() => {
    const formDataPhoto = new FormData()
    photoFile.photo && formDataPhoto.append("photo", photoFile.photo as File)
    if (doctorId?.upsertedId) {
      photoFile.photo &&
        dispatch(
          doctorsActions.updateDoctor({
            data: formDataPhoto,
            _id: doctorId?.upsertedId,
          })
        )
      navigate("/doctors/all")
    }
  }, [doctorId?.upsertedId])

  useEffect(() => {
    dispatch(doctorsActions.setDoctorId(null))
  }, [])

  return (
    <React.Fragment>
      <MainNavbar navigate="/doctors/all" />
      <Main>
        <InfoAboutDoctor
          formData={formData}
          setFormData={setFormData}
          photoFile={photoFile}
          setPhotoFile={setPhotoFile}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          CRUDFunction={createDoctor}
          title={"Створити доктора"}
        />
      </Main>
    </React.Fragment>
  )
}
