import { AxiosResponse } from "axios"

import { HttpService } from "../http.service"
import { TResponse } from "../types"

import {
  TGetDoctorsRequestPayload,
  TGetDoctorRequestPayload,
  TUpdateDoctorRequestPayload,
  TGetFeedbackDoctorRequestPayload,
  TCreateDoctorRequestPayload,
  TDeleteFeedbackRequestPayload,
} from "./types"
import { DOCTORS_URL } from "./config"

export class ApiDoctorsService extends HttpService {
  static getDoctors({
    token,
    speciality = [],
    name = "",
    order,
    user,
    sortBy,
    skip = 0,
    limit = 10,
    phone = "",
  }: TGetDoctorsRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${DOCTORS_URL.getDoctors}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        speciality,
        name,
        order,
        user,
        sortBy,
        skip: skip * limit,
        limit,
        phone,
      },
    })
  }

  static getFeedbacksDoctor({
    token,
    doctor = "",
    skip = 0,
    limit = 5,
  }: TGetFeedbackDoctorRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${DOCTORS_URL.getFeedbacksDoctor}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        doctor,
        limit,
        skip: skip * limit,
      },
    })
  }

  static getDoctor({
    token,
    _id,
  }: TGetDoctorRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${DOCTORS_URL.getDoctor}/${_id}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
  }

  static updateDoctor({
    data,
    token,
    _id,
  }: TUpdateDoctorRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${DOCTORS_URL.updateDoctor}/${_id}`,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    })
  }

  static createDoctor({
    data,
    token,
  }: TCreateDoctorRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${DOCTORS_URL.createDoctor}`,
      method: "POST",
      data: data,
      headers: {
        Authorization: token,
      },
    })
  }

  static deleteFeedback({
    token,
    _id,
  }: TDeleteFeedbackRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${DOCTORS_URL.deleteFeedback}/${_id}`,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    })
  }
}
