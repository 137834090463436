import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { useDispatch } from "react-redux"
import startOfMinute from "date-fns/startOfMinute"
import endOfMinute from "date-fns/endOfMinute"

import { appointmentActions, TDocsDoctorPlain } from "store/appointment"
import { getAuthSelector } from "store/auth"
import { TUser, usersActions } from "store/users"
import { showToastAction } from "store/toasts"

import { generateErrorToast, Title, useTypedSelector, Text } from "common"

import { ItemBlock, Main, Count, FormBlock, Select } from "./styled"
import { Forms, Table } from "./components"
import { TConsultation } from "./types"
import { clinicsActions, getClinicsSelector } from "store/clinics"

export const Consultation = ({
  userId,
  setUserId,
  users,
  handleSubmit,
  user,
  register,
  formData,
  errors,
  setFormData,
  crudtype,
  idAppointment,
  appointment,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: TConsultation) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { type, currentId } = useTypedSelector(getAuthSelector)
  const { clinics } = useTypedSelector(getClinicsSelector)

  const [filteredUser, setFilteredUser] = useState<TUser[]>([])

  const clinicsOptions = clinics.map(
    (clinic) =>
      clinic.address
        .map((address) => {
          if (address.lang == "uk") {
            return { name: address.value, value: clinic._id }
          }
        })
        .filter((item) => item)[0]
  );

  const startTimeConsultation = moment(formData.date).format(
    `dddd, MMMM DD YYYY ${moment(startDate?.$d ? startDate?.$d : startDate).format("HH:mm:ss")}`
  )
  const endTimeConsultation = moment(formData.date).format(
    `dddd, MMMM DD YYYY ${moment(endDate?.$d ? endDate?.$d : endDate).format("HH:mm:ss")}`
  )

  useEffect(() => {
    dispatch(usersActions.getUser({ _id: userId }))
  }, [userId]);

  const createAppointment = () => {
    if (
      moment(startTimeConsultation).format() >
      moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
    ) {
      dispatch(
        appointmentActions.createAppointment({
          data: {
            user: userId,
            type: formData?.typeConsultation,
            startTime: endOfMinute(new Date(startTimeConsultation)),
            endTime: startOfMinute(new Date(endTimeConsultation)),
            doctor: type === "admin" ? formData?.doctor : currentId,
            status: "reserved",
            speciality: formData?.speciality,
            clinic: formData?.clinic
              ? formData?.clinic
              : clinicsOptions[0]?.value,
          },
        })
      )
      navigate("/futureConsultations")
    } else {
      dispatch(
        showToastAction.request(
          generateErrorToast("Консультацію на минулий час не можливо створити!")
        )
      )
    }
  }

  const deleteAppointment = () => {
    dispatch(appointmentActions.deleteAppointment({ _id: idAppointment }))
    navigate("/futureConsultations")
  }

  const updateAppointment = () => {
    if (
      moment(startTimeConsultation).format() >
      moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
    ) {
      dispatch(
        appointmentActions.updateAppointment({
          data: {
            user: userId,
            type: formData.typeConsultation,
            startTime: endOfMinute(new Date(startTimeConsultation)),
            endTime: startOfMinute(new Date(endTimeConsultation)),
            doctor: type === "admin" ? formData.doctor : currentId,
            status: "reserved",
            speciality: formData.speciality,
            clinic: formData?.clinic,
          },
          _id: idAppointment,
        })
      )
    } else {
      dispatch(
        showToastAction.request(
          generateErrorToast("Консультацію на минулий час не можливо створити!")
        )
      )
    }
  }

  useEffect(() => {
    dispatch(clinicsActions.getClinics({}))
  }, []);

  useEffect(() => {
    if(users){
       setFilteredUser(users.filter(el => !el.deleted))
    }
  },[users]);

  return (
    <Main>
      <Title title="Запис на консультацію" />
      <ItemBlock>
        <Count>1</Count>
        <Text text="Інформація про пацієнта" fontSize="16" fontWeight={500} />
      </ItemBlock>
      {userId === "" ? (
        <FormBlock>
          <Text text="Виберіть пацієнта" margin="0 0 2px 0" />
          <Select onChange={(e) => setUserId(e.target.value)} defaultValue="">
            <option value="" disabled></option>
            {filteredUser?.map((option, index: number) => (
              <option key={index} value={option._id}>
                {`${option.surname} ${option?.name
                  ?.slice(0, 1)
                  .toUpperCase()}.${option?.patronymic
                  ?.slice(0, 1)
                  .toUpperCase()}.`}
              </option>
            ))}
          </Select>
        </FormBlock>
      ) : (
        <React.Fragment>
          <Table user={user} setUserId={setUserId} />
          <ItemBlock>
            <Count>2</Count>
            <Text
              text="Інформація про консультацію"
              fontSize="16"
              fontWeight={500}
            />
          </ItemBlock>
          <Forms
            startDate={startDate as string}
            setStartDate={setStartDate}
            endDate={endDate as string}
            setEndDate={setEndDate}
            appointment={appointment as TDocsDoctorPlain}
            handleSubmit={handleSubmit}
            errors={errors}
            register={register}
            updateAppointment={updateAppointment}
            deleteAppointment={deleteAppointment}
            createAppointment={createAppointment}
            crudtype={crudtype}
            formData={formData}
            setFormData={setFormData}
          />
        </React.Fragment>
      )}
    </Main>
  )
}
