import styled from "styled-components";

import { colors } from "common/styles/colors";

import { TUserInfoStyled, TUsersBlock, TUnreadCount, TChatBody } from "./types";

export const Container = styled.div<TChatBody>`
  max-width: 373px;
  width: 100%;
  padding-top: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid ${colors.gray85};

  @media (max-width: 1100px) {
    width: 320px;
  };

  @media (max-width: 1030px) {
    display: ${({ $isActiveChat }) => !$isActiveChat ? 'flex' : 'none'};
    height: 80vh;
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  };
`;

export const InfoChatInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Div = styled.div`
  padding: 5px;
  background-color: ${colors.red};
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 15px;
  }

  max-width: 265px;

  @media (max-width: 1437px) {
    margin-top: 10px;
    margin-right: 15px;
  };
`;

export const Input = styled.input`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  padding: 8.5px;

  width: 308px;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
    color: ${colors.gray};
  }

  @media (max-width: 1100px) {
    width: 300px;
  };
`;

export const UsersContainer = styled.div`
  margin-top: 20px;

  height: 600px;

  overflow-y: auto;

  &::-webkit-scrollbar { width: 0; }
`;

export const UsersBlock = styled.div<TUsersBlock>`
  display: flex;
  justify-content: space-between;

  background: ${({ background }) => (background ? "#DEE7E3" : "transparent")};
  width: 100%;
  cursor: pointer;

  width: 333px;

  padding: 17px 21px;
  border-bottom: 1px solid ${colors.bgMain};

  &:hover {
    background: #dee7e3;
    transition: ease 0.3s;
  }

  @media (max-width: 1100px) {
    width: 300px;
    padding: 15px 10px;
  };
`;

export const Image = styled.img`
  position: relative;

  width: 56px;
  height: 56px;

  margin-right: 15px;

  border-radius: 50%;
`;

export const UserInfo = styled.div<TUserInfoStyled>`
  display: flex;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    left: 43px;
    top: 44px;
    z-index: 1;
    border-radius: 50%;
    border: ${({ online }) => (online ? "1px solid white" : "transparent")};
    background-color: ${({ online }) => (online ? colors.green : "transparent")};
  }
`;

export const Name = styled.span``;

export const TimeBlock = styled.div``;

export const Time = styled.span`
  color: ${colors.gray};
  font-size: 13px;
`;

export const UnreadCount = styled.div<TUnreadCount>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  background: ${({ unreadCount }) => unreadCount ? colors.salmon : 'transparent'};
  border-radius: 50%;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
