import React, { SyntheticEvent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"

import {
  Button,
  MainNavbar,
  Pagination,
  Title,
  useTypedSelector,
} from "common"
import { doctorsActions, getDoctorsSelector, TDoctor } from "store/doctors"
import { getAuthSelector } from "store/auth"

import { Forms, Table } from "./components"
import { Main, Header } from "./styled"
import { ITEM_PER_PAGE } from "./consts"
import { TFormData } from "./types"

export const DoctorsList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  const { doctors } = useTypedSelector(getDoctorsSelector)
  const { type } = useTypedSelector(getAuthSelector)

  const [page, setPage] = useState<number>(0)
  const [formData, setFormData] = useState<TFormData>({
    speciality: [],
    order: "1",
    name: "",
    user: "",
  })

  const onPageChangeHandler = ({ selected }: { selected: number }) => {
    setPage(selected)
  }

  const utils = {
    getQueryParams: () => {
      const res = {}

      const requestFields = {
        ...Object.keys(formData)
          .map((field) =>
            formData[field as keyof typeof formData]
              ? Object.assign(res, {
                  [field]: formData[field as keyof typeof formData],
                })
              : ""
          )
          .filter((item) => item),
      }
      return res
    },
  }

  useEffect(() => {
    dispatch(
      doctorsActions.getDoctors({
        ...utils.getQueryParams(),
        skip: page,
        sortBy: "createdAt",
        limit: ITEM_PER_PAGE,
      })
    )
  }, [page, formData, dispatch])  

  const navigateCreateDoctor = () => {
    navigate("/create-doctor")
  }

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement
    setFormData((props: TFormData) => ({ ...props, [name]: value }))
  }

  useEffect(() => {
    if (id) {
      setFormData({
        user: id === "all" ? "" : id,
        name: "",
        order: "-1",
        speciality: [],
      })
    }
  }, [id])

  if (type != "admin") {
    navigate("./account")
  }

  useEffect(() => {
    if (doctors?.docs?.length === 0) {
      setPage(0)
    }
  }, [doctors])

  return (
    <React.Fragment>
      <MainNavbar />
      <Main>
        <Header>
          <Title title={"Лікарі"} />
          <Button
            title="Створити"
            onClick={navigateCreateDoctor}
            size={14}
            height={32}
            width={149}
          />
        </Header>
        <Forms inputChangeHandler={inputChangeHandler} formData={formData} />
        {doctors?.docs?.map((doctor: TDoctor) => (
          <Table key={doctor._id} doctor={doctor} />
        ))}
        {doctors?.docs?.length !== 0 && (
          <Pagination
            page={page}
            pageCount={
              doctors?.meta
                ? Math.ceil(doctors?.meta?.totalCount / ITEM_PER_PAGE)
                : 0
            }
            onPageChange={onPageChangeHandler}
          />
        )}
      </Main>
    </React.Fragment>
  )
}
