import { createReducer } from '@reduxjs/toolkit';

import { notifiactionActions } from './actions';
import { TInitialState } from './types';

const InitialState: TInitialState = {
  notifications: null
};

export const notificationReducer = createReducer<TInitialState>(InitialState, (builder) => {
	builder
    .addCase(notifiactionActions.setNotifications, (state, { payload }) => ({ ...state, notifications: payload }))
})
