import React from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import { api, Assets, Button, Text } from "common"

import {
  TableContainer,
  NameBlock,
  Photo,
  InfoBlock,
  NavLinkBlock,
  NavLinkContainer,
  Image,
  Link,
  InfoContainer,
} from "./styled"
import { TTable } from "./types"

export const Table = ({ doctor }: TTable) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const navigatePage = (url: string) => {
    navigate(url)
  }

  return (
    <TableContainer>
      <NameBlock>
        <Photo
          src={
            doctor?.photo
              ? `${api.withImageAPI}/doctor/${doctor?.photo}`
              : Assets.ACCOUNT
          }
        />
        <Text
          text={`${doctor?.surname} ${doctor?.name} ${doctor?.patronymic}`}
        />
      </NameBlock>
      <InfoContainer>
        <InfoBlock>
          <Image src={Assets.STAR} />
          <Text text={doctor?.rating === 0 ? "-" : doctor?.rating.toFixed(0)} />
        </InfoBlock>
        <InfoBlock>
          <Image src={Assets.DOCTOR_GRAY} />
          <Text text={doctor?.speciality?.map((item) => t(item))?.join(", ")} />
        </InfoBlock>
      </InfoContainer>
      <NavLinkContainer>
        <NavLinkBlock
          onClick={() => {
            navigatePage(`/pastConsultations/${doctor?._id}/userAll`)
          }}
        >
          <Image src={Assets.DOCUMENT} />
          <Link>Консультації ({doctor?.appointmentsCount})</Link>
        </NavLinkBlock>
        <NavLinkBlock
          onClick={() => navigatePage(`/appointmant/${doctor?._id}`)}
        >
          <Image src={Assets.CALENDAR} />
          <Link>Розклад</Link>
        </NavLinkBlock>
        <NavLinkBlock onClick={() => navigatePage(`/patients/${doctor?._id}`)}>
          <Image src={Assets.ACCOUNT} />
          <Link>Пацієнти ({doctor?.usersCount})</Link>
        </NavLinkBlock>
        <NavLinkBlock
          onClick={() => navigatePage(`/edit-doctor/${doctor?._id}`)}
        >
          <Image src={Assets.EDIT} />
          <Link>Редагувати</Link>
        </NavLinkBlock>
      </NavLinkContainer>
      <Button
        onClick={() => navigatePage(`/newConsultation/all/${doctor?._id}`)}
        title="Нова консультація"
        size={14}
        height={32}
        width={149}
      />
    </TableContainer>
  )
}
