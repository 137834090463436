import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  
  padding: 14px 0;
  font-size: 13px;
  border-bottom: 1px solid #e8eae9;
`;

export const Doctor = styled.p`
  font-size: 14px;
  margin-right: 20px;
  font-weight: 600;
`;

export const Text = styled.p``;

export const DoctrBlock = styled.div`
  display: flex;
`;

export const Photo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 50%;
  margin-left: 10px;
`;
