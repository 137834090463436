import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";

import {
  Container,
  FormBlock,
  FormsContainer,
  Input,
} from "pages/Patient/styled";
import { Button, colors, Text } from "common";

import { TAddressComponent } from "./types";
import { Textarea, TextAreaBlock, ButtonBlock, Form } from "./styled";

export const AddressComponent = ({
  inputChangeHandler,
  setIsAddress,
  formData,
  setValue,
  setCoordinates,
  value,
  setOpenIsModal,
}: TAddressComponent) => {
  const { ref } = usePlacesWidget({
    language: "ua",
    apiKey: "AIzaSyDFIMKjdPiUc47BWjndFq6LlifowMsfz34",
    onPlaceSelected: (place) => {
      setValue(place.address_components[0].long_name);
      setCoordinates({
        lat: place.geometry.location.lat(),
        lon: place.geometry.location.lng(),
      });
    },
  });

  const cancelHandler = () => {
    setIsAddress(false);
    setOpenIsModal(false);
  };

  const saveHandler = () => {
    setIsAddress(true);
    setOpenIsModal(false);
  };

  return (
    <Form>
      <Container>
        <FormsContainer>
          <FormBlock>
            <Text text="Місто" margin="0 0 2px 0" sup={true} />
            <Input ref={ref as unknown as React.MutableRefObject<HTMLInputElement>} defaultValue={value} />
          </FormBlock>
          <FormBlock>
            <Text text="Дім" margin="0 0 2px 0" sup={true} />
            <Input
              onChange={inputChangeHandler}
              name="house"
              value={formData?.house}
            />
          </FormBlock>
          <FormBlock>
            <Text text="Під'їзд" margin="0 0 2px 0" />
            <Input
              name="entrance"
              onChange={inputChangeHandler}
              value={formData.entrance}
            />
          </FormBlock>
        </FormsContainer>
        <FormsContainer>
          <FormBlock>
            <Text text="Вулиця" margin="0 0 2px 0" sup={true} />
            <Input
              name="street"
              value={formData.street}
              onChange={inputChangeHandler}
            />
          </FormBlock>
          <FormBlock>
            <Text text="Квартира" margin="0 0 2px 0" />
            <Input
              name="flat"
              onChange={inputChangeHandler}
              value={formData.flat}
            />
          </FormBlock>
          <FormBlock>
            <Text text="Поверх" margin="0 0 2px 0" />
            <Input
              name="floor"
              onChange={inputChangeHandler}
              value={formData.floor}
              type="number"
            />
          </FormBlock>
        </FormsContainer>
      </Container>
      <TextAreaBlock>
        <Text text="Додаткова інформація" margin="0 0 2px 0" />
        <Textarea
          placeholder="Додаткова інформація"
          value={formData.additionalInfo}
          name="additionalInfo"
          onChange={inputChangeHandler}
        />
      </TextAreaBlock>
      <ButtonBlock>
        <Button
          onClick={saveHandler}
          size={16}
          title="Зберегти"
          width={325}
          height={44}
        />
        <Button
          onClick={cancelHandler}
          color={`${colors.salmon}`}
          size={16}
          title="Скасувати"
          width={325}
          height={44}
        />
      </ButtonBlock>
    </Form>
  );
};
