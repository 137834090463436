import { NavLink as NavLinks } from "react-router-dom";
import styled from "styled-components";

import { colors } from "common";

import { TArrow, TMenuName, TSidebarWrapper } from "./types";

export const Logo = styled.img`
  padding: 22px 46px 19px;
  border-bottom: 1px solid ${colors.bgMain};

  @media (max-width: 660px) {
    width: 50%;
    border-bottom: 1px solid ${colors.bgMain};
  } ;
`;

export const Arrow = styled.img<TArrow>`
  display: flex;

  transform: ${({ $rotate }) => ($rotate ? "rotate(180deg)" : "rotate(360deg)")};

  margin-left: 80px;

  @media (max-width: 900px) {
    margin-left: 60px;
  } ;
`;

export const LogoContainer = styled.div`
  width: 100%;
  @media (max-width: 660px) {
    display: flex;
    justify-content: center;
  } ;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;

  margin-left: 9px;
  margin-right: 14px;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const Line = styled.div`
  border-bottom: 1px solid ${colors.bgMain};
`;

export const SidebarWrapper = styled.div<TSidebarWrapper>`
  width: 272px;
  height: 100vh;

  background: ${colors.bgSidebar};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex-wrap: nowrap;

  @media (max-width: 900px) {
    width: 250px;
  }

  @media (max-width: 660px) {
    display: ${({ $isActive }) => ($isActive ? "flex" : "none")};
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
  } ;
`;

export const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;

  margin-top: 8px;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 660px) {
    overflow-x: hidden;
  }
`;

export const HeadlineContainer = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  padding: 15px 0 15px 21px;

  @media (max-width: 660px) {
    width: 100%;
    justify-content: flex-start;
    padding: 15px 21 15px 21px;
  } ;
`;

export const NavLinkContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 660px) {
    align-items: flex-start;
  } ;
`;

export const HoverBlockNavlinkConsultation = styled(NavLinks)<TSidebarWrapper>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 53px;
  margin-bottom: 5px;

  width: 220px;
  height: 39px;

  color: ${colors.sidebarBlack};
  text-decoration: none;
  transition: 0.15s ease-in-out;

  &.active {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #c6dbd2;
    border-top-right-radius: ${({ $isActive }) => ($isActive ? "7px" : "0")};
    border-bottom-right-radius: ${({ $isActive }) => ($isActive ? "7px" : "0")};
  }

  &:hover {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    border-top-right-radius: ${({ $isActive }) => ($isActive ? "7px" : "0")};
    border-bottom-right-radius: ${({ $isActive }) => ($isActive ? "7px" : "0")};
  }

  @media (max-width: 660px) {
    align-items: flex-start;
  } ;
`;

export const ImageBlock = styled.div``;

export const MenuName = styled.div<TMenuName>`
  color: ${colors.sidebarBlack};
  line-height: 18px;
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
  margin-left: ${({ left }) => (left ? `${left}px` : 0)};
`;
