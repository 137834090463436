import { createAction } from "@reduxjs/toolkit";

import {
	GET_DOCTORS,
	SET_DOCTORS,
	SET_DOCTOR,
	GET_DOCTOR,
	SET_DOCTORS_STATE,
	EDIT_DOCTOR,
	GET_FEEDBACKS_DOCTOR,
	SET_FEEDBACKS_DOCTOR,
	CREATE_DOCTOR,
	SET_DOCTOR_ID,
	DELETE_FEEDBACK
} from "./consts";

export const doctorsActions = {
	getDoctors: createAction(GET_DOCTORS, (payload) => ({ payload })),
	setDoctors: createAction(SET_DOCTORS, (payload) => ({payload})),
	setDoctor: createAction(SET_DOCTOR, (payload) => ({payload})),
	getDoctor: createAction(GET_DOCTOR, (payload) => ({payload})),
	updateDoctor: createAction(EDIT_DOCTOR, (payload) => ({payload})),
    setDoctorsState: createAction(SET_DOCTORS_STATE, (payload) => ({payload})),
	setFeedbacksDoctor: createAction(SET_FEEDBACKS_DOCTOR, (payload) => ({payload})),
	getFeedbacksDoctor: createAction(GET_FEEDBACKS_DOCTOR, (payload) => ({payload})),
	createDoctor: createAction(CREATE_DOCTOR, (payload) => ({payload})),
	setDoctorId: createAction(SET_DOCTOR_ID, (payload) => ({payload})),
	deleteFeedback: createAction(DELETE_FEEDBACK, (payload) => ({payload}))
}