import React, { useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

import { hideToastAction } from 'store/toasts';

import { TToastProps } from './types';
import { AUTO_HIDE_DURATION } from './consts';

/*
 * @description 5 seconds of ms multiply by multiplier
 */

export const Toast = ({ data: { severity, message, id } }:TToastProps) => {
  const dispatch = useDispatch();

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const hide = useCallback(() => {
    dispatch(hideToastAction(id))
  }, [id, dispatch]);

  useEffect(() => {
    timeout.current = setTimeout(hide, AUTO_HIDE_DURATION)
  }, [id, hide]);

  return (
    <Alert severity={severity} onClose={hide}>
      {message}
    </Alert>
  )
};
