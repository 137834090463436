import { createReducer } from "@reduxjs/toolkit"
import { clinicsActions } from "./actions"
import { TInitialState } from "./types"

const InitialState: TInitialState = {
  clinics: [],
}

export const clinicsReducer = createReducer<TInitialState>(
  InitialState,
  (builder) => {
    builder.addCase(clinicsActions.setClinics, (state, { payload }) => ({
      ...state,
      clinics: payload,
    }))
  }
)
