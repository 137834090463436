// dev
// export const host: string = "https://api.preprod-mydoc.kitg.com.ua/";
// export const hostImage: string =
//   "https://api.preprod-mydoc.kitg.com.ua/public/";

// prod
export const host: string = "https://api.mydoc.kitg.com.ua/";
export const hostImage: string = "https://api.mydoc.kitg.com.ua/public/";

export const api = {
  withAPI: `${host}`,
  withImageAPI: `${hostImage}`,
};
