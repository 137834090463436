import { createAction } from "@reduxjs/toolkit";

import {
  CLEAR_MESSAGE,
  SET_MESSAGE_NOTIFICATION,
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATION,
  GET_NOTIFICATION,
  READ_NOTIFICATIONS
} from "./consts";

export const notifiactionActions = {
	setMessageNotification: createAction(SET_MESSAGE_NOTIFICATION, (payload) => ({payload})),
  clearMessage: createAction(CLEAR_MESSAGE, (payload) => ({payload})),
  getNotifications: createAction(GET_NOTIFICATIONS, (payload) => ({ payload })),
	setNotifications: createAction(SET_NOTIFICATIONS, (payload) => ({payload})),
	setNotification: createAction(SET_NOTIFICATION, (payload) => ({payload})),
	getNotification: createAction(GET_NOTIFICATION, (payload) => ({payload})),
  readNotifications: createAction(READ_NOTIFICATIONS, (payload) => ({payload})),
};