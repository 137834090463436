import React, {
  ChangeEvent,
  KeyboardEventHandler,
  SyntheticEvent,
  useState,
} from "react";
import { useDispatch } from "react-redux";

import { Assets, PhotoPicker } from "common";
import { chatActions } from "store/chat";
import moment from "moment";

import {
  Container,
  Input,
  SendMessageIcon,
  Block,
  Text,
  PhotoPickerBlock,
} from "./styled";
import { TFooter } from "./types";

export const Footer = ({
  idCurrentChat,
  statusConsultation,
  startTime,
}: TFooter) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState<string>("");
  const [file, setFile] = useState<File[]>([]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const sendMessageHandler = async () => {
    const data = new FormData();
    message && data.append("message", message);
    file &&
      data.append(
        "files",
        JSON.stringify(
          file.map((el) => ({
            extension: el.type,
            size: el.size,
            originalFilename: el.name,
            width: 400,
            height: 400,
          }))
        )
      );
    file && file.map((el) => data.append("file", el as File));
    if (message || file.length !== 0) {
      dispatch(chatActions.createMessage({ _id: idCurrentChat, data }));
    }
    await setMessage("");
    await setFile([]);
  };

  const onKeySendMessages = async (event: { keyCode: number }) => {
    if (event.keyCode == 13) {
      sendMessageHandler();
    }
  };

  const onPhotoChange = (fileData: File | null) => {
    setFile([fileData as File]);
  };

  return (
    <Container>
      {moment(startTime).format("YYYY-MM-DDTHH:mm") <=
      moment(new Date()).format("YYYY-MM-DDTHH:mm") ? (
        <>
          {statusConsultation !== "completed" &&
          statusConsultation !== "payment_reserved" ? (
            <>
              <Block>
                <PhotoPickerBlock>
                  <PhotoPicker
                    width={33}
                    height={33}
                    nameImage={"attach"}
                    name="image"
                    onKeySendMessages={onKeySendMessages}
                    clearPhoto={file}
                    onChange={onPhotoChange}
                    roundCorners={true}
                  />
                </PhotoPickerBlock>
                <Input
                  onKeyDown={onKeySendMessages}
                  value={message}
                  placeholder="Ваше повідомлення..."
                  onChange={onChangeHandler as () => void}
                />
              </Block>
              <SendMessageIcon
                src={Assets.SEND_MESSAGE_ICON}
                onKeyDown={onKeySendMessages}
                onClick={sendMessageHandler}
              />
            </>
          ) : (
            <Text>
              {statusConsultation !== "payment_reserved"
                ? "Чат завершен"
                : "Пацієнт не сплатив консультацію"}
            </Text>
          )}
        </>
      ) : (
        <Text>Консультація ще не розпочалась</Text>
      )}
    </Container>
  );
};
