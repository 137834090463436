import React from "react"

import { specialityOptions, Text, useTypedSelector } from "common"

import {
  H2,
  FormsContainer,
  FormBlock,
  StyledSelect,
  Input,
  DescriptionContainer,
} from "./styled"
import { TInfoForPatients } from "./types"
import { getAuthSelector } from "store/auth"

export const InfoForPatients = ({
  formData,
  setFormData,
  inputChangeHandler,
  register,
  errors,
}: TInfoForPatients) => {
  const { type } = useTypedSelector(getAuthSelector)
  return (
    <>
      <H2>Інформація для пацієнтів</H2>
      <FormsContainer>
        <FormBlock>
          <Text text="Спеціальність" margin="0 0 2px 0" sup={true} />
          <StyledSelect
            isMulti
            options={specialityOptions}
            value={specialityOptions
              .map((item) => {
                if (formData?.speciality?.includes(item.value)) {
                  return item
                }
              })
              .filter((item) => item)}
            onChange={(value:any) => {
              setFormData({
                ...formData,
                speciality: value.map((item:any) => item.value),
              })
            }}
          />
          {errors.speciality && (
            <Text text="Це поле є обов'язковим" color="red" />
          )}
        </FormBlock>
        <FormBlock>
          <Text text="Досвід  (Роки)" margin="0 0 2px 0" sup={true} />
          <Input
            {...register("experience", {
              validate: () => !!formData?.experience,
            })}
            onChange={inputChangeHandler}
            value={formData?.experience ?? ""}
          />
          {errors.experience && (
            <Text text="Це поле є обов'язковим" color="red" />
          )}
        </FormBlock>
      </FormsContainer>
      <DescriptionContainer>
        <FormBlock>
          <Text text="Освіта" margin="0 0 2px 0" sup={true} />
          <Input
            width={666}
            {...register("education", {
              validate: () => !!formData?.education,
            })}
            onChange={inputChangeHandler}
            value={formData?.education ?? ""}
          />
          {errors.education && (
            <Text text="Це поле є обов'язковим" color="red" />
          )}
        </FormBlock>
        <FormBlock>
          <Text text="Опис (для пацієнтів)" margin="0 0 2px 0" sup={true} />
          <Input
            width={666}
            {...register("description", {
              validate: () => !!formData?.description,
            })}
            onChange={inputChangeHandler}
            value={formData?.description ?? ""}
          />
          {errors.description && (
            <Text text="Це поле є обов'язковим" color="red" />
          )}
        </FormBlock>
        {type === "admin" && (
          <FormBlock>
            <Text
              text="Ціна консультації за годину"
              margin="0 0 2px 0"
              sup={true}
            />
            <Input
              width={666}
              {...register("price", {
                maxLength: 5,
                validate: () => !!formData?.price,
              })}
              onChange={inputChangeHandler}
              type='number'
              value={formData?.price ?? ""}
            />
            {errors.price && (
              <Text text="Це поле є обов'язковим (не більше 5 чисел)" color="red" />
            )}
          </FormBlock>
        )}
      </DescriptionContainer>
    </>
  )
}
