import styled from "styled-components";

import { TTextareaStyled } from "./types";

export const TextareaInfo = styled.textarea<TTextareaStyled>`
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  resize:none;
  border-radius: 8px;
  border: 1px solid white;
  padding: 12px 14px;
  margin-bottom: ${({ marginBottom }) => `${marginBottom}`};
  box-sizing:border-box;
`;