import React from "react";
import { HashRouter } from "react-router-dom";

import { getAuthSelector } from "store/auth";
import { LayoutComponent } from "pages/Layout";
import { useTypedSelector } from "common";

import { NotAuthRoutes } from "./NotAuthRoutes";
import { AuthRoutes } from "./AuthRoutes";

export const AppRouter = () => {
  const { currentDoctorData, activeSidebar } =
    useTypedSelector(getAuthSelector);

  return (
    <HashRouter basename="/">
      {currentDoctorData ? (
        <LayoutComponent>
          <AuthRoutes />
        </LayoutComponent>
      ) : (
        <>
          {activeSidebar ? (
            <LayoutComponent>
              <NotAuthRoutes />
            </LayoutComponent>
          ) : (
            <NotAuthRoutes />
          )}
        </>
      )}
    </HashRouter>
  );
};
