import { createReducer } from '@reduxjs/toolkit';

import { usersActions } from './actions';
import { TInitialState } from './types';

const InitialState: TInitialState = {
	users: {
		docs: [],
		meta: null
	},
	userLoading: false,
	user: null,
	loading: false,
	response: null,
	loadingFiles:false,
	files: {
		docs: [],
    meta: null
	},
}

export const usersReducer = createReducer<TInitialState>(InitialState, (builder) => {
	builder
		.addCase(usersActions.setUsers, (state, { payload }) => ({ ...state, users: payload }))
		.addCase(usersActions.setUser, (state, { payload }) => ({ ...state, user: payload}))
		.addCase(usersActions.setFiles, (state, { payload }) => ({ ...state, files: payload}))
		.addCase(usersActions.setUsersState, (state, { payload }) => ({ ...state, response: null, ...payload }))
})
