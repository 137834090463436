import React, { SyntheticEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";

import { doctorsActions, getDoctorsSelector } from "store/doctors";
import { getAuthSelector } from "store/auth";

import {
  Button,
  consultationWithoutAllOptions,
  specialityOptions,
  useTypedSelector,
  Text,
  colors,
} from "common";
import { TFormData } from "pages/NewConsultationPage/types";

import {
  Container,
  Form,
  FormsContainer,
  FormBlock,
  DefaultSelect,
  ReactDatePickerStyled,
  ButtonBlock,
} from "./styled";
import { TForms } from "./types";

export const Forms = ({
  formData,
  setFormData,
  updateAppointment,
  handleSubmit,
  errors,
  crudtype,
  createAppointment,
  register,
  deleteAppointment,
  appointment,
  startDate,
  endDate,
  setEndDate,
  setStartDate,
}: TForms) => {
  const dispatch = useDispatch();

  const { doctors } = useTypedSelector(getDoctorsSelector);
  const { currentDoctorData, type } = useTypedSelector(getAuthSelector);

  useEffect(() => {
    type === "admin" &&
      dispatch(
        doctorsActions.getDoctors({
          speciality: formData.speciality,
          limit: 100,
        })
      );
  }, [formData.speciality, type]);

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((props: TFormData) => ({ ...props, [name]: value }));
  };

  return (
    <Form>
      <Container>
        <FormsContainer>
          <FormBlock>
            <Text text="Тип консультації" margin="0 0 2px 0" />
            <DefaultSelect
              {...register("typeConsultation", { required: true })}
              value={formData.typeConsultation}
              onChange={inputChangeHandler}
            >
              <option value=""></option>
              {consultationWithoutAllOptions.map((option, index: number) => (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              ))}
            </DefaultSelect>
            {errors.typeConsultation && (
              <Text text="Це поле є обов'язковим" color="red" />
            )}
          </FormBlock>
          {type === "admin" && (
            <FormBlock>
              <Text text="Спеціальність лікаря" margin="0 0 2px 0" />
              <DefaultSelect
                {...register("speciality", { required: true })}
                defaultValue={appointment?.speciality}
                value={formData.speciality}
                onChange={inputChangeHandler}
              >
                <option value=""></option>
                {specialityOptions.map((option, index: number) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </DefaultSelect>
              {errors.speciality && (
                <Text text="Це поле є обов'язковим" color="red" />
              )}
            </FormBlock>
          )}
          <FormBlock>
            <Text text="Дата" margin="0 0 2px 0" />
            <ReactDatePickerStyled
              placeholderText="Виберіть дату"
              {...register("date")}
              onChange={(e) =>
                setFormData((props: TFormData) => ({ ...props, date: e }))
              }
              selected={new Date(formData.date)}
              dateFormat="dd/MM/yyyy"
            />
          </FormBlock>
          <FormBlock>
            <Text text="Час до" margin="0 0 2px 0" />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                className="time-input"
                InputProps={{
                  className: "time-input",
                }}
                value={dayjs(endDate as string)}
                onChange={(newValue) => setEndDate(newValue as Dayjs)}
                format="HH:mm"
                size="small"
              />
            </LocalizationProvider>
          </FormBlock>
        </FormsContainer>
        <FormsContainer>
          {type === "admin" && (
            <FormBlock>
              <Text text="Лікар" margin="0 0 2px 0" />
              <DefaultSelect
                {...register("doctor", { required: true })}
                onChange={inputChangeHandler}
                value={formData.doctor}
                disabled={!doctors?.docs?.length || !formData.speciality.length}
              >
                <>
                  {formData.speciality && (
                    <>
                      <option value=""></option>
                      {doctors?.docs?.map((option, index: number) => (
                        <option key={index} value={option._id}>
                          {`${option.surname} ${option?.name
                            ?.slice(0, 1)
                            .toUpperCase()}.${option?.patronymic
                            ?.slice(0, 1)
                            .toUpperCase()}.`}
                        </option>
                      ))}
                    </>
                  )}
                </>
              </DefaultSelect>
              {errors.doctor && (
                <Text text="Це поле є обов'язковим" color="red" />
              )}
            </FormBlock>
          )}
          <FormBlock>
            <Text text="Час з" margin="0 0 2px 0" />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimeField
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                value={dayjs(startDate as string)}
                InputProps={{
                  className: "time-input",
                }}
                onChange={(newValue) => setStartDate(newValue as Dayjs)}
                format="HH:mm"
                size="small"
              />
            </LocalizationProvider>
          </FormBlock>
        </FormsContainer>
      </Container>
      {crudtype === "create" && (
        <Button
          title={"Створити консультацію"}
          onClick={handleSubmit(createAppointment)}
          width={694}
          height={44}
          size={16}
        />
      )}
      {crudtype === "update" && currentDoctorData?.isAdmin && (
        <ButtonBlock>
          <Button
            onClick={handleSubmit(updateAppointment)}
            title={"Зберегти запис"}
            width={694}
            height={44}
            size={16}
          />
          <Button
            onClick={deleteAppointment}
            title={"Видалити запис"}
            width={694}
            color={`${colors.salmon}`}
            height={44}
            size={16}
          />
        </ButtonBlock>
      )}
    </Form>
  );
};
