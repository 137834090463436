import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { sortOrderOptions, specialityOptions, useTypedSelector } from "common"
import { getUsersSelector, usersActions } from "store/users"

import { Form, FormBlock, Input, Label, Select } from "./styled"
import { TForms } from "./types"

export const Forms = ({ inputChangeHandler, formData }: TForms) => {
  const dispatch = useDispatch()

  const { users } = useTypedSelector(getUsersSelector)

  useEffect(() => {
    dispatch(usersActions.getUsers({ limit: 500 }))
  }, [])

  return (
    <Form>
      <FormBlock>
        <Label>Пошук по імені</Label>
        <Input
          name="name"
          onChange={inputChangeHandler}
          value={formData.name}
        />
      </FormBlock>
      <FormBlock>
        <Label>Сортування</Label>
        <Select
          name="order"
          onChange={inputChangeHandler}
          value={formData.order}
        >
          {sortOrderOptions.map((option, index: number) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </Select>
      </FormBlock>
      <FormBlock>
        <Label>Спеціалізація</Label>
        <Select
          name="speciality"
          onChange={inputChangeHandler}
          value={formData.speciality}
        >
          <option value="">Всі спеціалізації</option>
          {specialityOptions?.map((option, index: number) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </FormBlock>
      <FormBlock>
        <Label>Пацієнт</Label>
        <Select name="user" onChange={inputChangeHandler} value={formData.user}>
          <option value="">Всі</option>
          {users?.docs?.map((option) => (
            <option key={option?._id} value={option?._id}>
              {`${option?.surname} ${option?.name
                ?.slice(0, 1)
                .toUpperCase()}.${option?.patronymic
                ?.slice(0, 1)
                .toUpperCase()}.`}
            </option>
          ))}
        </Select>
      </FormBlock>
    </Form>
  )
}
