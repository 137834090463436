import React, { useState, useEffect, SyntheticEvent } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import moment from "moment";

import {
  Assets,
  MainNavbar,
  RelativePreloader,
  Title,
  useTypedSelector,
} from "common";

import { ListConsultations } from "pages/ListConsultations";
import { appointmentActions, getAppointmentSelector } from "store/appointment";
import { getAuthSelector } from "store/auth";

import { Main } from "./styled";
import { ITEM_PER_PAGE } from "./consts";
import { TFormData } from "./types";
import {
  ReactDatePickerStyled,
  Span,
  Image,
  Header,
  DateContainer,
} from "pages/PastConsultations/styled";
import { TForm } from "pages/PastConsultations/types";

export const CurrentConsultation = () => {
  const { register, watch, control } = useForm<TForm>({
    // defaultValues: {
    //   endDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss"),
    // },
  });
  const dispatch = useDispatch();

  const { doctorPlain, doctorPlainLoading } = useTypedSelector(
    getAppointmentSelector
  );
  const { type, currentId } = useTypedSelector(getAuthSelector);

  const [page, setPage] = useState<number>(0);
  const [formData, setFormData] = useState<TFormData>({
    doctor: "",
    patient: "",
    speciality: [],
    clinic: "",
  });
  const watchAllFields = watch();
  const getAppointmentPlain = () => {
    const end =
      moment(watchAllFields.endDate).format("YYYY-MM-DDTHH:mm:ss") >
      moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
        ? moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
        : moment(watchAllFields.endDate).format("YYYY-MM-DDTHH:mm:ss");

    const start = watchAllFields.startDate
      ? moment(watchAllFields.startDate).format("YYYY-MM-DDTHH:mm:ss")
      : undefined;
    dispatch(
      appointmentActions.getDoctorPlain({
        doctor: type === "admin" ? formData.doctor : currentId,
        user: formData.patient,
        speciality: formData.speciality,
        end: end,
        start,
        skip: page,
        limit: ITEM_PER_PAGE,
        sortBy: "startTime",
        order: -1,
        clinic: formData.clinic,
        status: ["reserved", "payment_reserved"],
      })
    );
  };

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((props: TFormData) => ({ ...props, [name]: value }));
  };

  useEffect(() => {
    getAppointmentPlain();
  }, [
    page,
    dispatch,
    formData,
    currentId,
    type,
    watchAllFields.endDate,
    watchAllFields.startDate,
  ]);

  return (
    <React.Fragment>
      <MainNavbar />
      <Main>
        <Header>
          <Title title={"Теперішні Консультації"} />
          <DateContainer>
            <Image src={Assets.CALENDAR_FOR_INPUT} />
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <ReactDatePickerStyled
                  placeholderText="Виберіть дату"
                  onChange={(e) => field.onChange(e)}
                  selected={field.value}
                  dateFormat={"dd/MM/yyyy"}
                />
              )}
            />
            <Span>-</Span>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => (
                <ReactDatePickerStyled
                  placeholderText="Виберіть дату"
                  onChange={(e) => field.onChange(e)}
                  selected={field.value}
                  dateFormat={"dd/MM/yyyy"}
                />
              )}
            />
          </DateContainer>
        </Header>
        {!doctorPlainLoading ? (
          <ListConsultations
            inputChangeHandler={inputChangeHandler}
            getAppointmentPlain={getAppointmentPlain}
            page={page}
            formData={formData}
            setPage={setPage}
            register={register}
            typeConsultations={"current"}
            consultations={doctorPlain}
          />
        ) : (
          <RelativePreloader loading={doctorPlainLoading} />
        )}
      </Main>
    </React.Fragment>
  );
};
