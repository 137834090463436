import React, { FC, SyntheticEvent, useEffect, useState } from "react";

import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";

import { getNewsSelector, newsActions, TNews } from "store/news";
import {
  api,
  Assets,
  Button,
  colors,
  Modal,
  Pagination,
  useTypedSelector,
  Text,
} from "common";

import {
  ButtonContainer,
  ButtonWrapper,
  ChangePhoto,
  Close,
  H1,
  Image,
  Line,
  Main,
  Popup,
} from "./styled";
import { ITEM_PER_PAGE } from "./consts";
import { EditorComponentProps } from "./types";

export const EditorComponent: FC<EditorComponentProps> = () => {
  const dispatch = useDispatch();

  const { news, response } = useTypedSelector(getNewsSelector);

  const [opentModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [editNewsItem, setEditNewsItem] = useState<TNews[]>();
  const [page, setPage] = useState<number>(0);
  const [indx, setIndex] = useState<number>(0);
  const [openPhoto, setOpenPhoto] = useState<boolean>(false);

  const onPageChangeHandler = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  const hiddenFileInput = React.useRef(null);

  const getNews = () => {
    dispatch(
      newsActions.getNews({
        lang: "ru",
        limit: 10,
        page: page,
        order: -1,
        sortBy: "_id",
      })
    );
  };

  const newsInputHandler = (text: string, index: number) => {
    if (editNewsItem) {
      const item = { ...editNewsItem[index], text: text };
      let resArr = editNewsItem.slice();

      resArr[index] = item;

      setEditNewsItem(resArr);
    }
  };

  const saveEdit = (_id: string, index: number) => {
    if (editNewsItem) {
      const data = new FormData();
      data.append("text", editNewsItem[index].text);
      dispatch(newsActions.patchNews({ _id: editNewsItem[index]._id, data }));
    }
  };

  const hiddenImageFileUpload = (index: number) => {
    setIndex(index);
    //@ts-ignore
    hiddenFileInput.current.click();
  };

  const submitHandler = (e: SyntheticEvent) => {
    const inputEventHandler = (e.target as HTMLInputElement).files;
    const file = inputEventHandler ? inputEventHandler[0] : "";

    if (file && editNewsItem) {
      const data = new FormData();

      data.append("image", file);

      dispatch(newsActions.patchNews({ _id: editNewsItem[indx]._id, data }));
    }
  };

  useEffect(() => {
    if (response == "patched" || response == "deleted") getNews();
  }, [response, page]);

  useEffect(() => {
    if (news?.docs?.length) setEditNewsItem(news.docs);
  }, [news]);

  useEffect(() => {
    getNews();
  }, []);

  const deleteNews = () => {
    dispatch(newsActions.deleteNews({ _id: deleteId }));
  };
  
  const agreeHandler = () => {
    deleteNews()
    setDeleteId('')
    setOpenModal(false)
  }

  return (
    <Main>
      {news?.docs &&
        editNewsItem &&
        news.docs.map((newsItem: TNews, index) => (
          <React.Fragment key={index}>
            <ChangePhoto
              src={Assets.CLOSE}
              onClick={() => hiddenImageFileUpload(index)}
            />
            {openPhoto && indx === index && (
              <Modal padding="50px">
                <Close
                  src={Assets.CLOSE}
                  onClick={() => setOpenPhoto(!openPhoto)}
                />
                <Image
                  src={`${api.withImageAPI}news/${newsItem.image}`}
                  onClick={() => setOpenPhoto(!openPhoto)}
                  style={{ maxWidth: "100%" }}
                />
              </Modal>
            )}
            <Image
              src={`${api.withImageAPI}news/${newsItem.image}`}
              onClick={() => {
                setOpenPhoto(!openPhoto);
                setIndex(index);
              }}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={submitHandler}
              style={{ display: "none" }}
            />
            <Editor
              apiKey="lci85rlzi43qr9ohaqaufzdvoefi7w9ldifxcexfwhtn2ff8"
              value={
                editNewsItem[index]?.text
                  ? editNewsItem[index].text
                  : newsItem.text
              }
              init={{
                height: 350,
                width: "100%",
                menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor textcolor",
                  "searchreplace visualblocks code fullscreen textcolor ",
                  "insertdatetime media table paste code help wordcount image textcolor",
                ],
                image_title: false,
                automatic_uploads: false,
                images_upload_url: "postAcceptor.php",
                toolbar:
                  "undo redo | formatselect | bold italic backcolor forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | image code",
                branding: false,
              }}
              onEditorChange={(c) => {
                newsInputHandler(c, index);
              }}
            />
            <ButtonContainer>
              <Button
                title="Зберегти"
                height={36}
                width={108}
                size={18}
                onClick={() => saveEdit(newsItem._id, index)}
              />
              <Button
                title="Видалити"
                color={colors.red}
                height={36}
                width={108}
                size={18}
                onClick={() => {
                  setOpenModal(true);
                  setDeleteId(newsItem._id);
                }}
              />
            </ButtonContainer>
            <Line />
          </React.Fragment>
        ))}
      <Pagination
        page={page}
        pageCount={
          news.meta?.totalCount
            ? Math.ceil(news?.meta?.totalCount / ITEM_PER_PAGE)
            : 1
        }
        onPageChange={onPageChangeHandler}
      />
      {opentModal && (
        <Popup>
          <H1>Підтвердіть дію</H1>
          <Text margin="10px 0 10px 0" text="Ви впевнені?" />
          <ButtonWrapper>
            <Button
              width={200}
              height={40}
              color={`${colors.salmon}`}
              title="Відмінити"
              size={16}
              margin="0 20px 0 0"
              onClick={() => {
                setOpenModal(false);
                setDeleteId("");
              }}
            />
            <Button
              width={200}
              height={40}
              onClick={agreeHandler}
              title="Підтвердити"
              size={16}
            />
          </ButtonWrapper>
        </Popup>
      )}
    </Main>
  );
};
