import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

import { authentication, Button, LoginWrapper, Text } from "common";

import { authActions } from "store/auth";
import { doctorsActions } from "store/doctors";

import { Input, Form, Captcha } from "./styled";

export const LoginPhone = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const phone = watch("phone");

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
      },
      authentication
    );
  };

  const onSignIn = () => {
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(authentication, phone, appVerifier)
      .then((confirmationResult) => {
        dispatch(authActions.setPhone(phone));
        window.confirmationResult = confirmationResult;
        navigate("/login-code");
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };
  useEffect(() => {
    dispatch(doctorsActions.setDoctor(null));
    dispatch(authActions.setIsSeendDoctorMe(false))
    dispatch(authActions.setCurrentDoctorData(null))
    dispatch(authActions.activeSidebar(false));
    dispatch(authActions.setIsSeendDoctorMe(false));
    dispatch(authActions.setCurrentId(null))
  }, []);

  return (
    <LoginWrapper>
      <Form onSubmit={handleSubmit(onSignIn)}>
        <Text text='Телефон' margin="0 0 2px 0"/>
        <Input
          {...register("phone", {
            required: true,
            maxLength: 13,
            minLength: 13,
            pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
          })}
          maxLength={13}
          defaultValue={'+380'}
        />
        {errors.phone ? (
          <Text margin="0 0 24px 0" text='Введіть коректний номер телефону (+380, без пробілів)' color="red"/>
        ) : (
          <Text margin="0 0 24px 0"></Text>
        )}
        <Button title="Зареєструватися або увійти" size={16} width={320} height={44} />
        <Captcha id="recaptcha-container"></Captcha>
      </Form>
    </LoginWrapper>
  );
}
