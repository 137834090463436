import styled from "styled-components"

import { colors } from "common"

import {
  THeaderSection,
  TMainBlock,
  TForm,
  TSupportContainerStyled,
} from "../../types"

export const Main = styled.div`
  padding: 42px 30px;
`

export const SupportWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 80vh;

  margin-top: 30px;
  border-radius: 12px;
  background-color: ${colors.white};

  @media (max-width: 500px) {
    margin-top: 10px;
    height: 75vh;
  }
`

export const SupportContainer = styled.div<TSupportContainerStyled>`
  padding: 20px 20px;
  width: 100%;

  &:not(:last-child) {
    border-right: 1px solid ${colors.bgSidebar};
  }

  @media (max-width: 1260px) {
    width: 100%;
    display: ${({ $isActive, $position }) =>
      $position === "left"
        ? !$isActive
          ? "block"
          : "none"
        : $isActive
        ? "block"
        : "none"};
    &:not(:last-child) {
      border-right: none;
    }
  }
`

export const HeaderSection = styled.div<THeaderSection>`
  display: flex;
  align-items: center;
  justify-content: ${({ $justify }) => $justify};

  padding: 20px 0;

  border-bottom: 1px solid ${colors.bgSidebar};
`

export const MainBlock = styled.div<TMainBlock>`
  display: flex;
  justify-content: space-around;

  background-color: ${({ $background }) => $background};

  padding: 30px 0;

  width: 100%;

  cursor: pointer;

  border-bottom: 1px solid ${colors.bgSidebar};
`

export const TextBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
  margin-right: 10px;
`

export const FormTextare = styled.div`
  padding: 10px;
  height: 400px;
  overflow-y: auto;
  max-width: 675px;
  width: 100%;
  border-radius: 8px;
  background-color: ${colors.bgSidebar};
  box-sizing: border-box;

  @media (max-width: 1660px) {
    width: 600px;
    height: 350px;
  }

  @media (max-width: 1600px) {
    width: 450px;
    height: auto;
  }

  @media (max-width: 1060px) {
    width: 350px;
  }

  @media (max-width: 700px) {
    width: 300px;
    height: auto;
  }

  @media (max-width: 500px) {
    height: 280px;
    width: 250px;
  }
`

export const MainSection = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
`

export const FormBlock = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }
`

export const Form = styled.div<TForm>`
  padding: 10px;
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
  background-color: ${colors.bgSidebar};
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 250px;
  }
`
