import React from "react";
import moment from "moment";

import { api, Assets } from "common";
import { MessageBody } from "../MessageBody";

import {
  Contaienr,
  Message,
  MessageBlock,
  Time,
  Photo
} from "./styled";
import { TMyMessage } from "./types";

export const MyMesageContainer = ({ myMessageData, currentChatData }: TMyMessage) => {
  return (
    <Contaienr>
      <MessageBlock>
        <Message>
          {myMessageData?.files?.map((el) => (
            <MessageBody el={el} key={el._id}/> 
          ))}
          {myMessageData?.message}
        </Message>
        <Photo
          src={
            currentChatData?.doctor?.photo
              ? `${api.withImageAPI}/doctor/${currentChatData?.doctor?.photo}`
              : Assets.ACCOUNT
          }
        />
      </MessageBlock>
      <Time>{moment(myMessageData?.createdAt).format("HH:mm")}</Time>
    </Contaienr>
  );
}
