import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { sortOrderOptions, useTypedSelector } from "common"
import { doctorsActions, getDoctorsSelector } from "store/doctors"

import { Form, FormBlock, Input, Label, Select } from "./styled"
import { TForms } from "./types"

export const Forms = ({
  formData,
  inputChangeHandler,
  type,
  chatId,
}: TForms) => {
  const dispatch = useDispatch()

  const { doctors } = useTypedSelector(getDoctorsSelector)

  useEffect(() => {
    dispatch(
      doctorsActions.getDoctors({
        limit: 100,
      })
    )
  }, [])

  return (
    <Form>
      <FormBlock>
        <Label>Пошук по імені файлу</Label>
        <Input
          name="filename"
          value={formData.filename}
          onChange={inputChangeHandler}
        />
      </FormBlock>
      <FormBlock>
        <Label>Сортування</Label>
        <Select
          name="order"
          value={formData.order}
          onChange={inputChangeHandler}
        >
          {sortOrderOptions.map((option, index: number) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </Select>
      </FormBlock>
      {type === "admin" && (
        <>
          {chatId === "all" && (
            <FormBlock>
              <Label>Лікар</Label>
              <Select
                name="doctor"
                value={formData.doctor ?? ""}
                onChange={inputChangeHandler}
              >
                <option value="" disabled></option>
                {doctors.docs.map((option, index: number) => (
                  <option key={index} value={option._id}>
                    {`${option.surname} ${option?.name
                      ?.slice(0, 1)
                      .toUpperCase()}.${option?.patronymic
                      ?.slice(0, 1)
                      .toUpperCase()}.`}
                  </option>
                ))}
              </Select>
            </FormBlock>
          )}
        </>
      )}
    </Form>
  )
}
