import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
	
  margin-top: 34px;
  margin-bottom: 31px;

  @media (max-width: 900px) {
    justify-content: center;
  };
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child){
    margin-right:15px;
  }

  max-width: 265px;


  @media (max-width: 1437px) {
    margin-top: 10px;
    margin-right: 15px;
  };	
`;

export const Label = styled.label`
  margin-bottom: 2px;
  color: #000;
  line-height: 20px;
`;


export const Input = styled.input`
  border: 1px solid white;
  border-radius: 8px;

  background-color: white;
	
  padding: 11px;

  width: 241px;

  &::placeholder{
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 600px) {
    width: 206px;
  };
`;

export const Select = styled.select`
  border: 1px solid white;
  border-radius: 8px;

  background-color: white;
	
  padding: 11px;

  width:265px;

  cursor: pointer;

  &::placeholder{
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 600px) {
    width: 230px;
  };
`;
