import styled from "styled-components";

import { colors } from "common";

export const TableContainer = styled.div`
  padding: 12px 16px 12px 22px;
  margin-bottom: 17px;

  border-radius: 4px;
	
	cursor: pointer;

  border-left: 4px solid ${colors.salmon};

  background-color: ${colors.white};

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  };
`;

export const NameBlock = styled.div`
	width: 230px;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
  };
`;

export const Photo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 3px;
`;

export const NavLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    margin-top: 10px;
    margin-bottom: 15px;
  };
`;

export const NavLinkBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  margin-right: 20px;

  @media (max-width: 1400px) {
    margin-right: 25px;
    margin-top: 5px;
  };
`;

export const Span = styled.span`
  color: #707070;
  margin-left: 5px;
`;

export const DeleteIcon = styled.img``;;
