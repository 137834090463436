import { PayloadAction } from "@reduxjs/toolkit"
import { generateErrorToast } from "common"
import { call, put, takeLatest } from "redux-saga/effects"
import { getAccessToken } from "store/auth"
import { showToastAction } from "store/toasts"
import { TResponse } from "store/types"
import { supportActions } from "./actions"
import { ApiSupportService } from "./api.service"
import { TCloseSupportPayload, TGetSupportsPayload } from "./types"

function* getSupportWorker({ payload }: PayloadAction<TGetSupportsPayload>) {
  yield put(supportActions.setSupportState({ loading: true }))
  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiSupportService, ApiSupportService.getSupport],
      {
        token,
        ...payload,
      }
    )
    if (response?.data) yield put(supportActions.setSupport(response.data))
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast("Помилка отримання звітів до підтримки.")
      )
    )
  }

  yield put(supportActions.setSupportState({ loading: false }))
}

function* closeSupportWorker({ payload }: PayloadAction<TCloseSupportPayload>) {
  yield put(supportActions.setSupportState({ loading: true }))
  const token: string = yield call(getAccessToken)

  try {
    yield call([ApiSupportService, ApiSupportService.closeSupport], {
      token,
      ...payload,
    })
  } catch (e) {
    yield put(
      showToastAction.request(
        generateErrorToast("Помилка закриття звіта підтримки.")
      )
    )
  }

  yield put(
    supportActions.setSupportState({ loading: false, response: "closed" })
  )
}

export function* supportWatcher() {
  yield takeLatest(supportActions.getSupport, getSupportWorker)
  yield takeLatest(supportActions.closeSupport, closeSupportWorker)
}
