import React from "react"

import { TSiderbarMenus } from "pages/Sidebar/types"

import { TNavlink } from "./types"
import { HoverBlockNavlink, NavLinkBlock, Image, MenuName } from "./styled"
import { useTypedSelector } from "common"
import { getAuthSelector } from "store/auth"

export const NavLink = ({
  sidebarMenusData,
  onMenuClickHandler,
  isActive,
}: TNavlink) => {
  const { currentDoctorData } = useTypedSelector(getAuthSelector)

  return (
    <>
      {sidebarMenusData
        ?.filter((menu) => {
          if (
            (currentDoctorData?.isAdmin == false ||
              currentDoctorData?.isAdmin == undefined) &&
            (menu.name == "Новини" ||
              menu.name == "Підтримка" ||
              menu.name == "Лікарі")
          ) {
            return
          }
          return menu
        })
        .map((menu: TSiderbarMenus, index: number) => (
          <React.Fragment key={index}>
            <NavLinkBlock>
              <HoverBlockNavlink
                $isActive={isActive}
                key={menu.name + index}
                to={menu.path}
                onClick={onMenuClickHandler}
              >
                <React.Fragment>
                  <Image src={menu.icon} />
                </React.Fragment>
                <MenuName>{menu.name}</MenuName>
              </HoverBlockNavlink>
            </NavLinkBlock>
          </React.Fragment>
        ))}
    </>
  )
}
