import { combineReducers } from "@reduxjs/toolkit"

import { appointmentReducer } from "./appointment"
import { authReducer } from "./auth"
import { chatReducer } from "./chat"
import { clinicsReducer } from "./clinics"
import { doctorsReducer } from "./doctors"
import { newsReducer } from "./news"
import { notificationReducer } from "./notification"
import { supportReducer } from "./support"
import { toastsReducer } from "./toasts"
import { usersReducer } from "./users"

export default combineReducers({
  toasts: toastsReducer,
  auth: authReducer,
  doctors: doctorsReducer,
  users: usersReducer,
  appointmant: appointmentReducer,
  chat: chatReducer,
  notification: notificationReducer,
  news: newsReducer,
  support: supportReducer,
  clinics: clinicsReducer,
})
