import { colors, genderOptions, langOptions, Text } from "common";
import { DatePicker, Space } from "antd";

import { Container, FormsContainer, FormBlock, Input, Select } from "./styled";
import { TInfo } from "./types";
import dayjs from "dayjs";

export const Info = ({
  formData,
  registerAccount,
  inputChangeHandler,
  register,
  errors,
  setFormData,
  clinicsOptions,
}: TInfo) => {
  const disabledDate = (current: any) => {
    return current && current >= new Date().setHours(0, 0, 0, 0);
  };

  return (
    <Container>
      <FormsContainer>
        <FormBlock>
          <Text text="Ім’я" margin="0 0 2px 0" sup={true} />
          <Input
            {...register("name", {
              validate: () =>
                !!formData?.name.match(/^([a-zа-яёіїґє\-_' _]+|)$/i) &&
                !!formData?.name,
              pattern: /^([a-zа-яёіїґє\-_' _]+|)$/i,
            })}
            onChange={inputChangeHandler}
            value={formData?.name}
          />
          {errors?.name && (
            <Text
              text="Це поле є обов'язковим (в полі потрібно ввести лише літери)"
              color={`${colors.red}`}
            />
          )}
        </FormBlock>
        <FormBlock>
          <Text text="Прізвище" margin="0 0 2px 0" sup={true} />
          <Input
            {...register("surname", {
              validate: () =>
                !!formData?.surname.match(/^([a-zа-яёіїґє\-_' _]+|)$/i) &&
                !!formData?.surname,
              pattern: /^([a-zа-яёіїґє\-_' _]+|)$/i,
            })}
            onChange={inputChangeHandler}
            value={formData?.surname ?? ""}
          />
          {errors.surname && (
            <Text
              text="Це поле є обов'язковим (в полі потрібно ввести лише літери)"
              color={`${colors.red}`}
            />
          )}
        </FormBlock>
        <FormBlock>
          <Text text="По батькові" margin="0 0 2px 0" sup={true} />
          <Input
            {...register("patronymic", {
              validate: () =>
                !!formData?.patronymic.match(/^([a-zа-яёіїґє\-_' _]+|)$/i) &&
                !!formData?.patronymic,
              pattern: /^([a-zа-яёіїґє\-_' _]+|)$/i,
            })}
            onChange={inputChangeHandler}
            value={formData?.patronymic ?? ""}
          />
          {errors.patronymic && (
            <Text
              text="Це поле є обов'язковим (в полі потрібно ввести лише літери)"
              color={`${colors.red}`}
            />
          )}
        </FormBlock>
        {!registerAccount ? (
          <FormBlock>
            <Text text="Телефон" margin="0 0 2px 0" sup={true} />
            <Input
              {...register("phone", {
                maxLength: 13,
                minLength: 13,
                pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
              })}
              onChange={inputChangeHandler}
              value={formData?.phone ?? ""}
            />
            {errors.phone && (
              <Text
                text="Введіть коректний номер телефону (+380, без пробілів)"
                color={`${colors.red}`}
              />
            )}
          </FormBlock>
        ) : (
          <FormBlock>
            <Text text="Телефон" margin="0 0 2px 0" sup={true} />
            <Input value={formData?.phone ?? ""} />
          </FormBlock>
        )}
      </FormsContainer>
      <FormsContainer>
        <FormBlock>
          <Text text="Дата народження" margin="0 0 2px 0" />
          <Space>
            <DatePicker
              style={{
                height: "39.6px",
                width: "330px",
                padding: "13.3px",
                borderRadius: "8px",
              }}
              disabledDate={disabledDate}
              placeholder="Обрати дату"
              value={dayjs(formData?.birthday)}
              onChange={(value: any) =>
                setFormData((prevState: any) => ({
                  ...prevState,
                  birthday: value?.$d,
                }))
              }
            />
          </Space>
        </FormBlock>
        <FormBlock>
          <Text text="Стать" margin="0 0 2px 0" sup={true} />
          <Select
            {...register("gender", { validate: () => !!formData?.gender })}
            onChange={inputChangeHandler}
            value={formData?.gender ?? ""}
          >
            <option disabled value=""></option>
            {genderOptions.map((option, index: number) => (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>
          {errors.gender && (
            <Text text="Це поле є обов'язковим" color={`${colors.red}`} />
          )}
        </FormBlock>
        <FormBlock>
          <Text text="Імейл" margin="0 0 2px 0" sup={true} />
          <Input
            {...register("email", { validate: () => !!formData?.email })}
            type="email"
            onChange={inputChangeHandler}
            value={formData?.email ?? ""}
          />
          {errors.email && (
            <Text text="Це поле є обов'язковим" color={`${colors.red}`} />
          )}
        </FormBlock>
        <FormBlock>
          <Text text="Мова" margin="0 0 2px 0" />
          <Select
            {...register("lang", { validate: () => !!formData?.lang })}
            onChange={inputChangeHandler}
            value={formData?.lang ?? ""}
          >
            <option disabled value=""></option>
            {langOptions.map((option, index: number) => (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>
          {errors.lang && (
            <Text text="Це поле є обов'язковим" color={`${colors.red}`} />
          )}
        </FormBlock>
        <FormBlock>
          <Text text="Лікарня" margin="0 0 2px 0" />
          <Select
            {...register("clinic", { validate: () => !!formData?.clinic })}
            onChange={inputChangeHandler}
            value={formData?.clinic ?? ""}
          >
            <option disabled value=""></option>
            {clinicsOptions?.map((option, index: number) => (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>
          {errors.clinic && (
            <Text text="Це поле є обов'язковим" color={`${colors.red}`} />
          )}
        </FormBlock>
      </FormsContainer>
    </Container>
  );
};
