import { AxiosResponse } from "axios"

import { HttpService } from "../http.service"
import { TResponse } from "../types"
import { NEWS_URL } from "./config"
import {
  TCreateNewsRequestPayload,
  TDeleteNewsRequestPayload,
  TGetNewsRequestPayload,
  TPatchNewsRequestPayload,
} from "./types"

export class ApiNewsService extends HttpService {
  static getNews({
    token,
    order,
    sortBy,
    limit = 10,
    page = 0,
    lang,
  }: TGetNewsRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request({
      url: `${NEWS_URL.getNews}`,
      method: "GET",
      headers: {
        Authorization: token,
      },
      params: {
        order,
        sortBy,
        skip: page * limit,
        limit,
        lang,
      },
    })
  }

  static createNews({
    data,
    token,
  }: TCreateNewsRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${NEWS_URL.createNews}`,
      method: "POST",
      data,
      headers: {
        Authorization: token,
      },
    })
  }

  static deleteNews({
    _id,
    token,
  }: TDeleteNewsRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${NEWS_URL.deleteNews}/${_id}`,
      method: "DELETE",
      headers: {
        Authorization: token,
      },
    })
  }

  static patchNews({
    _id,
    token,
    data,
  }: TPatchNewsRequestPayload): Promise<AxiosResponse<TResponse>> {
    return this.request<TResponse>({
      url: `${NEWS_URL.patchNews}/${_id}`,
      method: "PATCH",
      data: data,
      headers: {
        Authorization: token,
      },
    })
  }
}
