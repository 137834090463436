import { Route, Routes } from "react-router-dom"

import { routes } from "./routes"

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        path={routes.patients.path}
        element={<routes.patients.component />}
      />
      <Route
        path={routes.editPatient.path}
        element={<routes.editPatient.component />}
      />
      <Route
        path={routes.patientFilesPage.path}
        element={<routes.patientFilesPage.component />}
      />

      <Route
        path={routes.doctorsList.path}
        element={<routes.doctorsList.component />}
      />
      <Route
        path={routes.editDoctor.path}
        element={<routes.editDoctor.component />}
      />
      <Route
        path={routes.createDoctor.path}
        element={<routes.createDoctor.component />}
      />

      <Route
        path={routes.pastConsultations.path}
        element={<routes.pastConsultations.component />}
      />
      <Route
        path={routes.futureConsultations.path}
        element={<routes.futureConsultations.component />}
      />
      <Route
        path={routes.currentConsultation.path}
        element={<routes.currentConsultation.component />}
      />
      <Route
        path={routes.resultConsultation.path}
        element={<routes.resultConsultation.component />}
      />
      <Route
        path={routes.newConsultation.path}
        element={<routes.newConsultation.component />}
      />
      <Route
        path={routes.updateConsultation.path}
        element={<routes.updateConsultation.component />}
      />

      <Route
        path={routes.appointmant.path}
        element={<routes.appointmant.component />}
      />

      <Route path={routes.chat.path} element={<routes.chat.component />} />

      <Route
        path={routes.account.path}
        element={<routes.account.component />}
      />

      <Route
        path={routes.notFoundedAuth.path}
        element={<routes.notFoundedAuth.component />}
      />

      <Route
        path={routes.supportPage.path}
        element={<routes.supportPage.component />}
      />
      <Route
        path={routes.infoSection.path}
        element={<routes.infoSection.component />}
      />
    </Routes>
  )
}
