import { createAction } from "@reduxjs/toolkit"

import {
  GET_CHAT,
  SET_CHAT,
  SET_CHAT_STATE,
  GET_CHAT_ID_MESSAGES,
  SET_CHAT_ID_MESSAGES,
  GET_CHAT_ID,
  SET_CHAT_ID,
  CREATE_MESSAGE,
  SET_MESSAGE,
  CHAT_READ,
  RENAME_FILE,
} from "./consts"

export const chatActions = {
  getChat: createAction(GET_CHAT, (payload) => ({ payload })),
  setChat: createAction(SET_CHAT, (payload) => ({ payload })),
  getCurrentChatData: createAction(GET_CHAT_ID, (payload) => ({ payload })),
  setChatId: createAction(SET_CHAT_ID, (payload) => ({ payload })),
  getChatIdMessages: createAction(GET_CHAT_ID_MESSAGES, (payload) => ({
    payload,
  })),
  setChatIdMessages: createAction(SET_CHAT_ID_MESSAGES, (payload) => ({
    payload,
  })),
  createMessage: createAction(CREATE_MESSAGE, (payload) => ({ payload })),
  setChatState: createAction(SET_CHAT_STATE, (payload) => ({ payload })),
  setMessage: createAction(SET_MESSAGE, (payload) => ({ payload })),
  chatRead: createAction(CHAT_READ, (payload) => ({ payload })),
  renameFile: createAction(RENAME_FILE, (payload) => ({ payload })),
}
