import styled from "styled-components";

export const Contaienr = styled.div``;

export const Message = styled.div`
  padding: 13px 16px;

  background: white;

  display: inline-block;

  border-radius: 12px;
`;

export const MessageBlock = styled.div`
  display: flex;

  margin-top: 15px;
`;

export const Time = styled.div`
  font-size: 12px;
  color: #6A7D75;
  margin-top: 6px;
  margin-left: 45px;
`;

export const Photo = styled.img`
  margin-right: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;