import React, { useState } from "react";

import { Assets, MainNavbar, useTypedSelector, Text } from "common";
import { getChatSelector } from "store/chat";
import { ChatBody } from "pages/ChatBody";

import { Container, Main, Image, ChatBlock } from "./styled";
import { ChatBar } from "./components";
import { useForm } from "react-hook-form";

export const Chat = () => {
  const { register, watch } = useForm();

  const userName = watch("name");

  const { messageLoading } = useTypedSelector(getChatSelector);

  const [idCurrentChat, setIdCurrentChat] = useState<string>("");
  const [isActiveChat, setIsActiveChat] = useState<boolean>(false);

  return (
    <Container>
      <MainNavbar type="chat" />
      <Main>
        <ChatBar
          register={register}
          userName={userName}
          setIsActiveChat={setIsActiveChat}
          isActiveChat={isActiveChat}
          idCurrentChat={idCurrentChat}
          setIdCurrentChat={setIdCurrentChat}
        />
        {idCurrentChat ? (
          <ChatBody
            userName={userName}
            setIsActiveChat={setIsActiveChat}
            isActiveChat={isActiveChat}
            loading={messageLoading}
            idCurrentChat={idCurrentChat}
          />
        ) : (
          <ChatBlock>
            <Image src={Assets.CHAT_DOCTORS} />
            <Text text="Виберіть чат" />
          </ChatBlock>
        )}
      </Main>
    </Container>
  );
};
