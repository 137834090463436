import { call, put, select, takeLatest } from "redux-saga/effects";

import { TDataWrapper, TResponse } from "store/types";

import { TCreateAuthPayload, TInitialState } from "./types";
import { getAuthSelector } from "./selectors";
import { ApiAuthService } from "./api.service";
import { authActions } from "./actions";

export function* getAccessToken(): Generator<unknown, string, TInitialState> {
  const { accessToken } = yield select(getAuthSelector);
  return ("Barear " + accessToken) as string;
}

function* authMeWorker({ payload }: TDataWrapper<TCreateAuthPayload>) {
  const { data } = payload;
  const token: string = yield call(getAccessToken);

  try {
		const response: TResponse =   yield call([ApiAuthService, ApiAuthService.authMe], { data, token });
		if(response.data) {
			yield put(authActions.setCurrentDoctorData({...response.data}))
		}
	} catch (e) {}
}

export function* authWatcher() {
  yield takeLatest( authActions.createAuthMe, authMeWorker);
}
