import { isValidElement } from 'react';
import { takeEvery, put } from '@redux-saga/core/effects';

import { generateReactNodeMessage } from './helpers';
import { showToastAction } from './actions';

function* showToastWorker({
  payload,
}: ReturnType<typeof showToastAction['request']>) {
  try {
    const { message } = payload

    if (Array.isArray(message))
      yield put(
        showToastAction.success({
          ...payload,
          id: Date.now(),
          message: generateReactNodeMessage(message as string[]),
        }),
      )
    else if (typeof message === 'string' || isValidElement(message))
      yield put(
        showToastAction.success({
          ...payload,
          id: Date.now(),
        }),
      )
    else
      yield put(
        showToastAction.success({
          ...payload,
          id: Date.now(),
          message: JSON.stringify(message),
        }),
      )
  } catch (e) {


    yield put(
      showToastAction.success({
        ...payload,
        message: 'Unhandled toast message',
        id: Date.now(),
      }),
    )
  }
}

export function* toastsSaga() {
  yield takeEvery(showToastAction.request, showToastWorker)
}
