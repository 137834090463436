import styled from "styled-components";

import { colors } from "common";

export const LayoutWrapper = styled.div`
  width: 100%;
  height: calc(100vh);

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: calc(100vw - 252px);
  height: 100%;

  overflow-y: auto;

  background-color: ${colors.whiteSmode};

  @media (max-width: 660px) {
    width: 100%;
  };
`;

export const MenuBurger = styled.img`
  width: 30px;

  position: absolute;
  z-index: 30;
  cursor: pointer;

  top: 20px;
  left: 30px;

  background-color: #ebf2ef;
  display: none;
  margin: top;

  @media (max-width: 660px) {
    display: flex;
  }
`;
