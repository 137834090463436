import styled from 'styled-components';

import { Assets } from 'common/assets';
import { colors } from 'common/styles/colors';

export const Styles = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;

   .pagination {
      margin: 40px auto;
      display: flex;
      list-style: none;
      outline: none;
      user-select: none;
   }

   .pagination > .active > a {
      color: ${colors.white};
      border: ${colors.green};
   }
   .pagination > li > a {
      border-radius: 6px;
      padding: 9px 13px;
      margin: 3px;
      outline: none;
      cursor: pointer;
      border: 1px solid ${colors.green};
   }
   .pagination > .active > a,
   .pagination > .active > span,
   .pagination > .active > a:hover,
   .pagination > .active > span:hover,
   .pagination > .active > a:focus,
   .pagination > .active > span:focus {
      background-color: ${colors.green};
      outline: none;
   }
   .pagination > li > a,
   .pagination > li > span {
      color: ${colors.green};
   }
   .pagination > li:first-child > a,
   .pagination > li:first-child > span,
   .pagination > li:last-child > a,
   .pagination > li:last-child > span {
      border-radius: unset;
   }

   .pagination > li.previous > a,
   .pagination > li.next > a {
      border: none;
   }
`

export const ArrowLeftIcon = styled.img.attrs({
   src: Assets.ARROW_FOR_PAGINATION
})``

export const ArrowRightIcon = styled.img.attrs({
   src: Assets.ARROW_FOR_PAGINATION,
})`
   transform: rotate(180deg)
`
