import React from 'react';

import { useTypedSelector } from 'common/hook';
import { getToastSelector } from 'store/toasts';

import { AlertsBar, Line } from './styled';
import Context from './context';
import { TProvider } from './types';
import { Toast } from './Toast';

export const ToastsProvider = ({ children }:TProvider) => {
  const { toasts } = useTypedSelector(getToastSelector);

  return (
    <Context.Provider value={null}>
      {children}
      <AlertsBar>
        {toasts.map((toast, index) => (
          <React.Fragment key={`snackbar-alert-${index}`}>
            <>
              {!!index && <Line />}
            </>
            <Toast data={toast} />
          </React.Fragment>
        ))}
      </AlertsBar>
    </Context.Provider>
  )
}
