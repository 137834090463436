import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Assets,
  Button,
  colors,
  Text,
  Textarea,
  Title,
} from "common";
import { appointmentActions } from "store/appointment";

import {
  Container,
  HeaderContainer,
  ItemsContainer,
  ItemBlock,
  Image,
  Item,
  Name,
  NavigateChat,
  Form,
  ChatBack,
  NavigateBlock,
  FormBlock,
} from "./styled";
import { TCurrentResultConsultation } from "./types";

export const CurrentResultConsultation = ({
  currentAppointment,
  getCurrrentAppointemnt,
  setIsActiveChat,
  isActiveChat,
}: TCurrentResultConsultation) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" });

  const watchAllFields = watch();

  const complitedHandler = async () => {
    await dispatch(
      appointmentActions.appointmentComplete({ _id: id, data: watchAllFields })
    );
    await getCurrrentAppointemnt();
    await getCurrrentAppointemnt();
    await getCurrrentAppointemnt();
  };

  const navigateChat = () => {
    setIsActiveChat(true);
  };

  return (
    <Container $isActiveChat={isActiveChat}>
      <HeaderContainer>
        <Title title="Результати консультації" />
        <ItemsContainer>
          <ItemBlock>
            <Image src={Assets.GRAY_CALENDAR} />
            <Item>{moment(currentAppointment?.startTime).format("DD/MM")}</Item>
          </ItemBlock>
          <ItemBlock>
            <Image src={Assets.CLOCK} />
            <Item>{`${moment(currentAppointment?.startTime).format(
              "HH:mm"
            )}-${moment(currentAppointment?.endTime).format("HH:mm")}`}</Item>
          </ItemBlock>
          <ItemBlock>
            <Image src={Assets.LOCATION} />
            <Item>{t(currentAppointment?.type ?? "")}</Item>
          </ItemBlock>
        </ItemsContainer>
      </HeaderContainer>
      <ItemsContainer>
        <Name>{`${currentAppointment?.user?.surname ?? "-"} ${
          currentAppointment?.user?.name ?? "-"
        } ${currentAppointment?.user?.patronymic ?? "-"}`}</Name>
        {currentAppointment?.chat && (
          <NavigateBlock onClick={navigateChat}>
            <NavigateChat>Чат</NavigateChat>
            <ChatBack src={Assets.ARROW_BACK} />
          </NavigateBlock>
        )}
      </ItemsContainer>
      <Form onSubmit={handleSubmit(complitedHandler)}>
        <FormBlock>
          <Textarea
            marginBottom={"0px"}
            width={"100%"}
            register={register}
            height={"87px"}
            name="complaints"
            label="Скарги"
            defaultValue={currentAppointment?.result?.complaints}
          />
          {errors.complaints && <Text color={`${colors.red}`} text="Це поле є обов'язковим" />}
        </FormBlock>
        <FormBlock>
          <Textarea
            marginBottom={"0px"}
            width={"100%"}
            register={register}
            name="diagnosis"
            height={"100px"}
            label="Попередній діагноз"
            defaultValue={currentAppointment?.result?.diagnosis}
          />
          {errors.diagnosis && <Text color={`${colors.red}`} text="Це поле є обов'язковим" />}
        </FormBlock>
        <FormBlock>
          <Textarea
            marginBottom={"0px"}
            width={"100%"}
            register={register}
            name="recommendations"
            height={"200px"}
            label="Рекомендовано"
            defaultValue={currentAppointment?.result?.recommendations}
          />
          {errors.recommendations && <Text color={`${colors.red}`} text="Це поле є обов'язковим" />}
        </FormBlock>
        {currentAppointment?.status === "completed" ? (
          <Button
            title="Завершити консультацію"
            height={44}
            disabled={true}
            size={16}
          />
        ) : (
          <Button
            title='Завершити консультацію'
            height={44}
            disabled={
              moment(new Date()).format("YYYY-MM-DDTHH:mm:ss:ms") <=
              moment(currentAppointment?.startTime).format("YYYY-MM-DDTHH:mm") || currentAppointment?.status === 'payment_reserved'
            }
            size={16}
          />
        )}
      </Form>
    </Container>
  );
};
