import React from "react";

import { api, Assets } from "common";

import {
  HeaderChatContainer,
  Image,
  Icon,
  IconBlock,
  InfoAboutDocto,
  InfoBlock,
  Name,
  ChatBack,
  Speciality,
  TypeNetwork
} from "./styled";
import { THeader } from "./types";
import { useNavigate } from "react-router-dom";

export const Header = ({ currentChatData, setIsActiveChat, onlineStatus }:THeader) => {
  const navigate = useNavigate();

  const navigateFilePage = (_id: string, chatId:string) => {
    navigate(`/patient-files/${_id}/${chatId}`)
  };

  const navigateChatBar = () => {
    setIsActiveChat(false)
  };

  return (
    <HeaderChatContainer>
      <InfoAboutDocto online={onlineStatus ||  currentChatData?.user?.online}>
        <ChatBack onClick={navigateChatBar} src={Assets.ARROW_BACK}/>
        <Image src={currentChatData?.user?.photo ? `${api.withImageAPI}/user/${currentChatData?.user?.photo}` : Assets.ACCOUNT} />
        <InfoBlock>
          <Name>{`${currentChatData?.user?.surname} ${currentChatData?.user?.name} ${currentChatData?.user?.patronymic}`}</Name>
          <Speciality>Пацієнт</Speciality>
          <TypeNetwork>{onlineStatus ||  currentChatData?.user?.online ? "Онлайн" : 'Офлайн'}</TypeNetwork>
        </InfoBlock>
      </InfoAboutDocto>
      <IconBlock>
        <Icon src={Assets.TRAY} onClick={() => navigateFilePage(currentChatData?.user?._id ?? '', currentChatData?._id ?? '')}/>
      </IconBlock>
    </HeaderChatContainer>
  );
}
