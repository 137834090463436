import { put, call, takeLatest } from "redux-saga/effects"

import { TDataWrapper, TResponse } from "../types"
import { getAccessToken } from "../auth"

import { chatActions } from "./actions"
import { ApiChatService } from "./api.service"
import {
  TChatReadRequestPayload,
  TCreateMessagePayload,
  TGetChatIdMessagesPayload,
  TGetChatIdRequestPayload,
  TGetChatPayload,
  TRenameFilePayload,
} from "./types"
import { PayloadAction } from "@reduxjs/toolkit"

function* getChatWorker({ payload }: TDataWrapper<TGetChatPayload>) {
  yield put(chatActions.setChatState({ chatloading: true }))
  const token: string = yield call(getAccessToken)
  try {
    const response: TResponse = yield call(
      [ApiChatService, ApiChatService.getChat],
      { ...payload, token }
    )
    if (response.data) yield put(chatActions.setChat(response.data))
  } catch (e) {
    yield put(chatActions.setChat({ data: [], meta: null }))
  }
  yield put(chatActions.setChatState({ chatloading: false }))
}

function* getChatIdMessagesWorker({
  payload,
}: TDataWrapper<TGetChatIdMessagesPayload>) {
  yield put(chatActions.setChatState({ messageLoading: true }))
  const { _id, limit, skip } = payload
  const token: string = yield call(getAccessToken)
  try {
    const response: TResponse = yield call(
      [ApiChatService, ApiChatService.getChatIdMessages],
      { _id, token, limit, skip }
    )
    if (response.data) {
      yield put(chatActions.setChatIdMessages({ ...response.data }))
    } else {
    }
  } catch (e) {}
  yield put(chatActions.setChatState({ messageLoading: false }))
}

function* getChatIdWorker({ payload }: TDataWrapper<TGetChatIdRequestPayload>) {
  yield put(chatActions.setChatState({ chatIdLoading: true }))
  const { _id } = payload
  const token: string = yield call(getAccessToken)
  try {
    const response: TResponse = yield call(
      [ApiChatService, ApiChatService.getCurrentChatData],
      { _id, token }
    )
    if (response.data) {
      yield put(chatActions.setChatId({ ...response.data }))
    } else {
    }
  } catch (e) {}
  yield put(chatActions.setChatState({ chatIdLoading: false }))
}

function* createMessageWorker({
  payload,
}: TDataWrapper<TCreateMessagePayload>) {
  yield put(chatActions.setChatState({ chatMessageLoading: true }))
  const { _id, data } = payload
  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiChatService, ApiChatService.createMessage],
      { _id, data, token }
    )
    if (response.data) {
      yield put(chatActions.setMessage({ ...response.data }))
    }
  } catch (e) {}
  yield put(chatActions.setChatState({ chatMessageLoading: false }))
}

function* chatReadWorker({ payload }: TDataWrapper<TChatReadRequestPayload>) {
  const { _id } = payload
  const token: string = yield call(getAccessToken)

  try {
    yield call([ApiChatService, ApiChatService.chatRead], { _id, token })
  } catch (e) {}
}

function* renameFileWorker({ payload }: PayloadAction<TRenameFilePayload>) {
  const token: string = yield call(getAccessToken)

  try {
    yield call([ApiChatService, ApiChatService.renameFile], {
      token,
      ...payload,
    })
  } catch (e) {}
}

export function* chatWatcher() {
  yield takeLatest(chatActions.getChat, getChatWorker)
  yield takeLatest(chatActions.getChatIdMessages, getChatIdMessagesWorker)
  yield takeLatest(chatActions.getCurrentChatData, getChatIdWorker)
  yield takeLatest(chatActions.createMessage, createMessageWorker)
  yield takeLatest(chatActions.chatRead, chatReadWorker)
  yield takeLatest(chatActions.renameFile, renameFileWorker)
}
