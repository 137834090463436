import { colors } from "common";
import styled from "styled-components";

export const Main = styled.div`
  padding: 0 30px;
  width: 90%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Image = styled.img`
  max-width: 25%;
  height: auto;

  align-self: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const Line = styled.div`
  border-bottom: 1px solid ${colors.bgMain};
  margin: 48px 0px;
  width: 100%;
`;

export const Close = styled.img`
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 15px;
`;

export const ChangePhoto = styled.img`
  align-self: flex-end;
  max-width: 20px;
  cursor: pointer;
  margin-bottom: 20px;
`;
export const Popup = styled.div`
  position: fixed;
  z-index: 22243;
  border-radius: 5px;
  width: 300px;
  background-color: white;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
  top: 0;
  right: 40%;
  padding: 20px;

`;

export const H1 = styled.h1`
  font-size: 16px;
  font-weight: bold;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 300px;
  justify-content: space-between;
`;
