import React, { FC, useState } from "react"
import { Navigate } from "react-router-dom"

import { MainNavbar, useTypedSelector } from "common"

import { getAuthSelector } from "store/auth"

import { Main } from "./styled"
import { TForm, TPhotoFile } from "./types"
import { CreateEditor, EditorComponent } from "./components"

export const InfoSection: FC = () => {
  const { currentDoctorData } = useTypedSelector(getAuthSelector)

  const [form, setForm] = useState<TForm>()
  const [photoFile, setPhotoFile] = useState<TPhotoFile>({ photo: null })

  if (!currentDoctorData?.isAdmin) {
    return <Navigate to={"/account"} />
  }

  return (
    <React.Fragment>
      <MainNavbar />
      <Main>
        <CreateEditor
          form={form as TForm}
          setForm={setForm}
          photoFile={photoFile}
          setPhotoFile={setPhotoFile}
        />
        <EditorComponent
          form={form as TForm}
          photoFile={photoFile}
          setForm={setForm}
        />
      </Main>
    </React.Fragment>
  )
}
