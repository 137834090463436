import styled from "styled-components";

export const Container = styled.div`
  max-width: 695px;
  width: 100%;

  margin-top: 18px;
  margin-bottom: 42px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1040px) {
    justify-content: center;
  }
`;

export const FormsContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: 30px;
  }

  @media (max-width: 1010px) {
    justify-content: center;

    margin-right: 0;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const Image = styled.img`
  position: absolute;
  z-index: 100;
  top: 30px;
  right: 10px;
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  margin-top: 16px;

  max-width: 330px;
`;

export const Input = styled.input`
  border: 1px solid white;
  border-radius: 8px;

  background-color: white;

  padding: 11px;

  width: 306px;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 420px) {
    width: 270px;
  }
`;

export const Select = styled.select`
  border: 1px solid white;
  border-radius: 8px;

  background-color: white;

  padding: 11px;

  width: 330px;

  cursor: pointer;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 420px) {
    width: 295px;
  }
`;