import { colors } from "common";
import styled from "styled-components";

import { TButtonStyle } from "./types";

export const ButtonBlock = styled.button<TButtonStyle>`
  max-width: ${({ width }) => `${width}px`};
  width: 100%;
  height:  ${({ height }) => `${height}px`};

  margin:  ${({ margin }) => margin };

  border-radius: 8px;
  border: none;

  cursor: ${({ styleDisabled }) => !styleDisabled && 'pointer'};

  font-size: ${({ size }) => `${size}px`};
  line-height: 19px;

  background: ${({ styleDisabled, color }) => !styleDisabled ? `${color}` : `${colors.disabled}`};
  color: white;

  font-family: SF Pro Text;
	font-weight: 400;

  &:hover {
    transition: ${({ styleDisabled }) => !styleDisabled && 'all 0.3s'};
    background-color: ${({ styleDisabled }) => !styleDisabled && 'white'};
    border: ${({ styleDisabled, color }) => !styleDisabled && `1px solid ${color}`};
    color: ${({ styleDisabled, color }) => !styleDisabled && color };
  }
`;