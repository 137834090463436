import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  MainNavbar,
  Pagination,
  RelativePreloader,
  Title,
  useTypedSelector,
} from "common";

import { getUsersSelector, usersActions } from "store/users";
import { getAuthSelector } from "store/auth";

import { Forms, Table } from "./components";
import { ITEM_PER_PAGE } from "./consts";
import { Main } from "./styled";
import { TFormData } from "./types";

export const PatientFilesPage = () => {
  const { id, chatId } = useParams();
  const dispatch = useDispatch();

  const { files, user, loadingFiles } = useTypedSelector(getUsersSelector);
  const { currentId, type } = useTypedSelector(getAuthSelector);

  const [formData, setFormData] = useState<TFormData>({
    doctor: "",
    filename: "",
    order: "-1",
    chat: "",
  });
  const [page, setPage] = useState<number>(0);

  const onPageChangeHandler = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  const getFiles = () => {
    dispatch(
      usersActions.getFiles({
        user: id,
        doctor: formData.doctor,
        filename: formData.filename,
        order: formData.order,
        skip: page,
        chat: formData.chat,
        sortBy: "createdAt",
        limit: ITEM_PER_PAGE,
      })
    );
  };

  useEffect(() => {
    getFiles();
  }, [page, formData]);

  useEffect(() => {
    dispatch(usersActions.getUser({ _id: id }));
  }, []);

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((props: TFormData) => ({ ...props, [name]: value }));
  };

  useEffect(() => {
    if (chatId) {
      setFormData({
        doctor:
          chatId === "all" ? (type === "admin" ? "" : currentId) : currentId,
        order: "-1",
        filename: "",
        chat: chatId === "all" ? "" : chatId,
      });
    }
  }, [chatId, currentId, type]);

  useEffect(() => {
    if (files?.docs?.length === 0) {
      setPage(0);
    }
  }, [files]);

  return (
    <React.Fragment>
      <MainNavbar navigate="/patients/all" />
      <Main>
        <Title
          title={`Файли: ${user?.surname} ${user?.name
            ?.slice(0, 1)
            .toUpperCase()}.${user?.patronymic?.slice(0, 1).toUpperCase()}.`}
        />
        <Forms
          chatId={chatId}
          type={type}
          formData={formData}
          inputChangeHandler={inputChangeHandler}
        />
        {!loadingFiles ? (
          <>
            {files?.docs?.map((file, index: number) => (
              <Table getFiles={getFiles} key={index} file={file} />
            ))}
          </>
        ) : (
          <RelativePreloader loading={loadingFiles} />
        )}
      </Main>
      {files?.docs?.length !== 0 && (
        <Pagination
          page={page}
          pageCount={
            files?.meta ? Math.ceil(files?.meta?.totalCount / ITEM_PER_PAGE) : 1
          }
          onPageChange={onPageChangeHandler}
        />
      )}
    </React.Fragment>
  );
};
