import styled from "styled-components";

export const Container = styled.div`
  max-width: 373px;
  width: 100%;
`;

export const RatingBlock = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageBlock = styled.div``;

export const Image = styled.img`
  width: 19px;
  height: 19px;
`;

export const Date = styled.span`
  color: #6A7D75;
  
  font-size: 12px;
`;

export const CommentBlock = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 30px;
`;

export const Review = styled.div`
  width: 328px;

  padding: 11px 16px;

  resize: none;

  background-color: white;
  border: 1px solid white;
  border-radius: 12px;

  margin-right: 15px;
  margin-top: 1px;
`;

export const DeleteIcon = styled.img`
  cursor: pointer;

  &:hover {
    transition: all 0.4s;
    transform: scale(1.3);
  }
`;