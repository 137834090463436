import { PayloadAction } from "@reduxjs/toolkit"
import { generateSuccessToast } from "common"
import { call, put, takeLatest } from "redux-saga/effects"
import { getAccessToken } from "store/auth"
import { showToastAction } from "store/toasts"
import { TResponse } from "store/types"
import { clinicsActions } from "./actions"
import { ApiClinicsService } from "./api.service"
import { TGetClinicsPayload } from "./types"

function* getClinicsWorker({ payload }: PayloadAction<TGetClinicsPayload>) {
  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiClinicsService, ApiClinicsService.getClinics],
      {
        token,
      }
    )

    if (response.data) yield put(clinicsActions.setClinics(response.data))

    // yield put(
    //   showToastAction.request(generateSuccessToast("Лікарні успішно отримані"))
    // )
  } catch (e) {}
}

export function* clinicsWatcher() {
  yield takeLatest(clinicsActions.getClinics, getClinicsWorker)
}
