import { createReducer } from "@reduxjs/toolkit"
import { newsActions } from "./actions"

import { TInitialState } from "./types"

const InitialState: TInitialState = {
  news: { docs: [], meta: null },
  loading: false,
  response: null,
}

export const newsReducer = createReducer<TInitialState>(
  InitialState,
  (builder) => {
    builder.addCase(newsActions.setNewState, (state, { payload }) => ({
      ...state,
      response: null,
      ...payload,
    }))
    builder.addCase(newsActions.setNews, (state, { payload }) => ({
      ...state,
      news: payload,
    }))
  }
)
