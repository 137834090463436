import logo from "./icons/logo.svg"
import document from "./icons/document.svg"
import calendar from "./icons/calendar.svg"
import account from "./icons/account.svg"
import chat from "./icons/chat.svg"
import doctor from "./icons/doctor.svg"
import logo238 from "./icons/logo238.svg"
import notification from "./icons/notification.svg"
import logout from "./icons/logOut.svg"
import tray from "./icons/tray.svg"
import arrowBack from "./icons/arrow-back.svg"
import edit from "./icons/edit.svg"
import start from "./icons/star.svg"
import doctorGray from "./icons/doctorGray.svg"
import trashOutline from "./icons/trash-outline.svg"
import photoIcon from "./icons/photoIcon.png"
import arrowForPagination from "./icons/arrowLeft.svg"
import syncOutline from "./icons/sync-outline.svg"
import calendarForInput from "./icons/calendarForInput.svg"
import close from "./icons/close.svg"
import deleteIcon from "./icons/deleteIcon.svg"
import grayCalendar from "./icons/grayCalendar.svg"
import location from "./icons/location.svg"
import clock from "./icons/clock.svg"
import menuIcon from "./icons/menuIcon.png"
import copy from "./icons/copy.svg"
import chatDoctors from "./icons/chatDoctors.png"
import attach from "./icons/attach.svg"
import sendMessageIcon from "./icons/sendMessageIcon.svg"
import cross from "./icons/cross.svg"
import attachPdf from "./icons/attach.svg"
import video from "./icons/video.png"
import phone from "./icons/phone.svg"
import mail from "./icons/mail.svg"
import download from "./icons/download.svg"
import grayFile from "./icons/grayFile.png"
import grayChat from "./icons/grayChat.svg"
import arrowDown from "./icons/arrow-down.svg"
import editIcon from "./icons/edit-icon.svg"
import customerService from "./icons/customer-service-2-line.svg"
import editFileName from "./icons/edit_file_name.svg"

export class Assets {
  static readonly ARROW_FOR_PAGINATION = arrowForPagination
  static readonly LOGO = logo
  static readonly DOCUMENT = document
  static readonly CALENDAR = calendar
  static readonly ACCOUNT = account
  static readonly CHAT = chat
  static readonly DOCTOR = doctor
  static readonly LOGO238 = logo238
  static readonly LOGOUT = logout
  static readonly NOTIFICATION = notification
  static readonly TRAY = tray
  static readonly ARROW_BACK = arrowBack
  static readonly EDIT = edit
  static readonly STAR = start
  static readonly DOCTOR_GRAY = doctorGray
  static readonly TRASH_OUTILE = trashOutline
  static readonly PHOTO_ICON = photoIcon
  static readonly SYNC_OUTLINE = syncOutline
  static readonly CALENDAR_FOR_INPUT = calendarForInput
  static readonly CLOSE = close
  static readonly DELETE_ICON = deleteIcon
  static readonly GRAY_CALENDAR = grayCalendar
  static readonly LOCATION = location
  static readonly CLOCK = clock
  static readonly MENU_ICON = menuIcon
  static readonly COPY = copy
  static readonly CHAT_DOCTORS = chatDoctors
  static readonly ATTACH = attach
  static readonly SEND_MESSAGE_ICON = sendMessageIcon
  static readonly CROSS = cross
  static readonly ATTACH_PDF = attachPdf
  static readonly VIDEO = video
  static readonly PHONE = phone
  static readonly MAIL = mail
  static readonly DOWNLOAD = download
  static readonly GRAY_FILE = grayFile
  static readonly GRAY_CHAT = grayChat
  static readonly ARROW_DOWN = arrowDown
  static readonly EDIT_ICON = editIcon
  static readonly CUSTOMER_SERVICE = customerService
  static readonly EDIT_FILE_NAME = editFileName
}
