import { PayloadAction } from "@reduxjs/toolkit"
import { generateErrorToast } from "common"
import { call, debounce, put, takeLatest } from "redux-saga/effects"
import { getAccessToken } from "store/auth"
import { showToastAction } from "store/toasts"
import { TResponse } from "store/types"
import { newsActions } from "./actions"
import { ApiNewsService } from "./api.service"
import {
  TCreateNewsPayload,
  TDeleteNewsPayload,
  TGetNewsPayload,
  TPatchNewsPayload,
} from "./types"

function* createNewsWorker({ payload }: PayloadAction<TCreateNewsPayload>) {
  yield put(newsActions.setNewState({ loading: true }))

  const { data } = payload
  const token: string = yield call(getAccessToken)

  try {
    yield call([ApiNewsService, ApiNewsService.createNews], { data, token })
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast("Помилка створення новини."))
    )
  }

  yield put(newsActions.setNewState({ loading: false, repsonse: "created" }))
}

function* getNewsWorker({ payload }: PayloadAction<TGetNewsPayload>) {
  yield put(newsActions.setNewState({ loading: true }))

  const token: string = yield call(getAccessToken)

  try {
    const response: TResponse = yield call(
      [ApiNewsService, ApiNewsService.getNews],
      {
        ...payload,
        token,
      }
    )

    if (response) yield put(newsActions.setNews(response.data))
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast("Помилка отримання новин."))
    )
  }
  yield put(newsActions.setNewState({ loading: false }))
}

function* deleteNewsWorker({ payload }: PayloadAction<TDeleteNewsPayload>) {
  yield put(newsActions.setNewState({ loading: true }))

  const token: string = yield call(getAccessToken)

  try {
    yield call([ApiNewsService, ApiNewsService.deleteNews], {
      ...payload,
      token,
    })
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast("Помилка видалення новини."))
    )
  }

  yield put(newsActions.setNewState({ loading: false, response: "deleted" }))
}

function* patchNewsWorker({ payload }: PayloadAction<TPatchNewsPayload>) {
  yield put(newsActions.setNewState({ loading: true }))

  const token: string = yield call(getAccessToken)

  try {
    yield call([ApiNewsService, ApiNewsService.patchNews], {
      ...payload,
      token,
    })
  } catch (e) {
    yield put(
      showToastAction.request(generateErrorToast("Помилка оновлення новини."))
    )
  }

  yield put(newsActions.setNewState({ loading: false, response: "patched" }))
}

export function* newsWatcher() {
  yield takeLatest(newsActions.createNews, createNewsWorker)
  yield debounce(500, newsActions.getNews, getNewsWorker)
  yield takeLatest(newsActions.deleteNews, deleteNewsWorker)
  yield takeLatest(newsActions.patchNews, patchNewsWorker)
}
