import styled from "styled-components";

import { colors } from "common";

import { TInfoAboutDoctor } from './types'

export const HeaderChatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 23px;

  background-color: ${colors.background};
  border-bottom: 1px solid #D1E2DD;
`;

export const InfoAboutDocto = styled.div<TInfoAboutDoctor>`
  display: flex;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    left: 43px;
    top: 44px;
    z-index: 1;
    border-radius: 50%;
    border:  ${({ online }) => online ? `1px solid ${colors.white}` : 'transparent'};
    background-color: ${({ online }) => online ? colors.green : 'transparent'};
  }
`;

export const Image = styled.img`
  width: 56px;
  height: 56px;

  border-radius: 50%;

  cursor: pointer;

  margin-right: 15px;
`;

export const ChatBack= styled.img`
  margin-top: 14px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  display: none;

  cursor: pointer;

  @media (max-width: 1030px) {
    display: block;
  };
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Name = styled.span`
  font-size: 13px;
  color: ${colors.black}
`;

export const Speciality = styled.span`
  font-size: 12px;
  color: #515B57;
`;

export const TypeNetwork = styled.div`
  font-size: 13px;
  color: #2B322F;
`;

export const IconBlock = styled.div`
  cursor: pointer;
`;

export const Icon = styled.img`
  padding: 6px;
  background: ${colors.white};
  border-radius: 50%;
`;