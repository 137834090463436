import styled from "styled-components";

import { TActiveChat } from "./types";

export const Container = styled.div<TActiveChat>`
  width: 100%;

  @media (max-width: 1030px) {
    display: ${({ $isActiveChat }) => !$isActiveChat ? 'none' : 'block'};
  };
`;