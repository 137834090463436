import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useForm } from "react-hook-form"

import { MainNavbar, RelativePreloader, useTypedSelector } from "common"
import { getDoctorsSelector, doctorsActions } from "store/doctors"
import { InfoAboutDoctor } from "pages/InfoAboutDoctor"

import { Main, CommentContainer, H2, FeedbackBlock } from "./styled"
import { Comment } from "./components"
import { TFormData, TPhotoFile } from "./type"

export const EditDoctor = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })
  const { id } = useParams()
  const dispatch = useDispatch()

  const { doctor, doctorLoading, feedbacksDoctor } =
    useTypedSelector(getDoctorsSelector)

  const [photoFile, setPhotoFile] = useState<TPhotoFile>({ photo: null })
  const [formData, setFormData] = useState<TFormData>(null)

  const getDoctor = () => {
    dispatch(doctorsActions.getDoctor({ _id: id }))
  }

  useEffect(() => {
    if (doctor) {
      setFormData({
        name: doctor?.name,
        surname: doctor?.surname,
        patronymic: doctor?.patronymic,
        phone: doctor?.phone,
        email: doctor?.email,
        gender: doctor?.gender,
        birthday: doctor?.birthday,
        lang: doctor?.lang,
        description: doctor?.description,
        education: doctor?.education,
        speciality: doctor?.speciality,
        experience: doctor?.experience,
        price: doctor?.price,
        clinic: doctor?.clinic,
      })
    }
  }, [doctor]);
  
  const getFeedback = () => {
    dispatch(
      doctorsActions.getFeedbacksDoctor({
        doctor: id,
        limit: 100,
        skip: 0,
      })
    )
  }

  const updateDoctorHandler = async () => {
    const data = formData
    const formDataPhoto = new FormData()
    photoFile.photo && formDataPhoto.append("photo", photoFile.photo as File)
    await dispatch(doctorsActions.updateDoctor({ _id: id, data }))
    await dispatch(
      doctorsActions.updateDoctor({ _id: id, data: formDataPhoto })
    )
    getDoctor()
    getDoctor()
  }

  useEffect(() => {
    getDoctor()
  }, [])

  useEffect(() => {
    if (doctor?._id) {
      getFeedback()
    }
  }, [doctor?._id])

  return (
    <React.Fragment>
      <MainNavbar navigate="/doctors/all" />
      {!doctorLoading ? (
        <Main>
          <InfoAboutDoctor
            formData={formData}
            setFormData={setFormData}
            photoFile={photoFile}
            setPhotoFile={setPhotoFile}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            doctor={doctor}
            CRUDFunction={updateDoctorHandler}
            title={"Редагувати акаунт лікаря"}
          />
          <CommentContainer>
            <H2>Рейтинг та коментарі</H2>
            {feedbacksDoctor.meta?.totalCount !== 0 ? (
              <FeedbackBlock>
                {feedbacksDoctor.docs.map((feedbacks) => (
                  <Comment
                    getFeedback={getFeedback}
                    key={feedbacks._id}
                    feedbacks={feedbacks}
                  />
                ))}
              </FeedbackBlock>
            ) : (
              "У цього лікаря поки що не має коментарів"
            )}
          </CommentContainer>
        </Main>
      ) : (
        <RelativePreloader loading={doctorLoading} />
      )}
    </React.Fragment>
  )
}
