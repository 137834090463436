import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  MainNavbar,
  Title,
  useTypedSelector,
} from "common";

import { getAuthSelector } from "store/auth";
import { supportActions, TSupport } from "store/support";
import { getSupportSelector } from "store/support/selectors";

import { SupportPageWrapper, Main, SupportWrapper, Container } from "./styled";
import { ITEM_PER_PAGE } from "./consts";
import { SupportComponent, SupportItemComponent } from "./components";

export const SupportPage = () => {
  const { currentDoctorData } = useTypedSelector(getAuthSelector);
  const { support } = useTypedSelector(getSupportSelector);

  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [currentSupportData, setCurrentSupportData] = useState<TSupport | string>('');
  const [page, setPage] = useState<number>(0);

  const getSupport = () => {
    dispatch(
      supportActions.getSupport({
        status: "new",
        limit: ITEM_PER_PAGE,
        page: page,
        order: -1,
      })
    );
  };

  useEffect(() => {
    getSupport();
  }, [page]);

  if (!currentDoctorData?.isAdmin) {
    return <Navigate to={"/account"} />;
  }

  return (
    <SupportPageWrapper>
      <MainNavbar />
      <Main>
        <Title title={"Підтримка"} />
        <Container>
          <SupportWrapper>
            <SupportComponent
              page={page}
              setPage={setPage}
              support={support}
              isActive={isActive}
              currentSupportData={currentSupportData as TSupport}
              setCurrentSupportData={setCurrentSupportData}
              setIsActive={setIsActive}
            />

            <SupportItemComponent
              isActive={isActive}
              getSupport={getSupport}
              setIsActive={setIsActive}
              setCurrentSupportData={setCurrentSupportData}
              currentSupportData={currentSupportData as TSupport}
            />
          </SupportWrapper>
        </Container>
      </Main>
    </SupportPageWrapper>
  );
};
