import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import moment from "moment"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { addDays } from "@syncfusion/ej2-react-schedule"
import { useParams } from "react-router-dom"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"

import { appointmentActions, getAppointmentSelector } from "store/appointment"
import { doctorsActions, getDoctorsSelector, TDoctor } from "store/doctors"
import { authActions, getAuthSelector } from "store/auth"

import {
  Assets,
  firebaseConfig,
  MainNavbar,
  useTypedSelector,
  Text,
  colors,
} from "common"
import { CalendarScheduler } from "pages/CalendarScheduler"

import {
  Container,
  Select,
  CalnedarContainer,
  ImageCopy,
  ButtonBlock,
  CalendarBlock,
} from "./styled"
import { TAppointmentData, TCopySheduleData } from "./types"

export const Appointmant = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id } = useParams()

  const [copySheduleData, setCopySheduleData] = useState<TCopySheduleData[]>([])
  const [listEventsCalendar, setListEventsCalendar] = useState<
    TAppointmentData[]
  >([]);
  const [doctorId, setDoctorId] = useState<string>("")
  const [startDayOfWeek, setStartDayOfWeek] = useState<Date | string>(
    new Date()
  )
  const [endDayOfWeek, setEndDayOfWeek] = useState<Date | string>(new Date())

  const { appointmentByDoctor, response } = useTypedSelector(getAppointmentSelector)
  const { doctors, doctor } = useTypedSelector(getDoctorsSelector)
  const { type, currentDoctorData } =
    useTypedSelector(getAuthSelector)

  const getAppointment = () => {
    if (doctorId) {
      dispatch(
        appointmentActions.getAppointmentByDoctor({
          _id: doctorId,
          start: moment(startDayOfWeek).format("YYYY-MM-DDTHH:mm:ss"),
          end: moment(endDayOfWeek).format("YYYY-MM-DDTHH:mm:ss"),
          timezone: new Date().getTimezoneOffset() / -60,
        })
      )
    }
  }

  useEffect(() => {
    if(doctorId){
      dispatch(doctorsActions.getDoctor({_id: doctorId}))
    }
  }, [doctorId])

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDoctorId(e.target.value)
  }

  useEffect(() => {
    if (doctorId) {
      const resultAppointmentByDoctor = appointmentByDoctor
        ?.map((el) => el?.appointments)
        .flat()
      setCopySheduleData(
        resultAppointmentByDoctor?.map((el) => ({
          startTime: new Date(
            format(
              new Date(addDays(new Date(el?.startTime), 7)),
              "yyyy-MM-dd HH:mm:ss"
            )
          ).toISOString(),
          endTime: new Date(
            format(
              new Date(addDays(new Date(el?.endTime), 7)),
              "yyyy-MM-dd HH:mm:ss"
            )
          ).toISOString(),
          doctor: el?.doctor,
          type: el?.type,
        }))
      )
    }
  }, [doctorId, appointmentByDoctor])

  const copySheduleDataHandler = () => {
    dispatch(appointmentActions.createAppintmentBulk({ data: copySheduleData }))
  }
  
  useEffect(() => {
    if (doctorId) {
      const resultAppointmentByDoctor = appointmentByDoctor
        ?.map((el) => el?.appointments)
        .flat()
      setListEventsCalendar(
        resultAppointmentByDoctor.map((el) => ({
          start: moment(el?.startTime).format("YYYY-MM-DDTHH:mm:ss"),
          end: moment(el?.endTime).format("YYYY-MM-DDTHH:mm:ss"),
          doctor: el?.doctor,
          status: el?.status,
          type: el?.type,
          user: el?.user,
          id: el?._id,
          title: `${t(el.type)} ${el?.user?.surname ?? ""} ${
            el?.user?.name?.slice(0, 1).toUpperCase() ?? ""
          } ${el?.user?.name ? "." : ""}${
            el?.user?.patronymic?.slice(0, 1).toUpperCase() ?? ""
          } ${el?.user?.patronymic ? "." : ""}`,
          groupId: el?.repeatableGroup,
          backgroundColor:
            moment(el?.endTime).format("YYYY-MM-DDTHH:mm") >
            moment(new Date()).format("YYYY-MM-DDTHH:mm")
              ? el.user
                ? `${colors.salmon}`
                : `${colors.green}`
              : `${colors.calendarSlotBg}`,
          speciality: el?.speciality,
        }))
      )
    }
  }, [doctorId, appointmentByDoctor])

  useEffect(() => {
    dispatch(doctorsActions.getDoctors({ limit: 160 }))
  }, [])

  useEffect(() => {
    getAppointment()
  }, [doctorId, startDayOfWeek, endDayOfWeek])

  useEffect(() => {
     if((response === 'CREATE_APPOINTMENT') || (response === 'UPDATE_APPOINTMENT') || (response === 'DELETE_APPOINTMENT')){
      getAppointment()
     }
  }, [response])

  useEffect(() => {
    if (type !== "admin") {
      setDoctorId(currentDoctorData?._id as string)
    } else {
      if (id === "all") {
        setDoctorId(currentDoctorData?._id as string)
      } else {
        setDoctorId(id as string)
      }
    }
  }, [type, id]);

  return (
    <Container>
      <MainNavbar />
      <CalnedarContainer>
        <CalendarBlock>
          {type === "admin" && (
            <Select onChange={handleChange} value={doctorId}>
              <option value="" disabled></option>
              {doctors?.docs?.map((option) => (
                <option key={option?._id} value={option?._id}>
                  {`${option?.surname} ${option?.name
                    ?.slice(0, 1)
                    .toUpperCase()}.${option?.patronymic
                    ?.slice(0, 1)
                    .toUpperCase()}. `}
                </option>
              ))}
            </Select>
          )}
          {type === "admin" && (
            <ButtonBlock onClick={copySheduleDataHandler}>
              <ImageCopy src={Assets.COPY} />
              <Text
                text={"Копіювати на наступний тиждень"}
                $cursor={true}
                color={`${colors.salmon}`}
              />
            </ButtonBlock>
          )}
        </CalendarBlock>
      </CalnedarContainer>
      <CalendarScheduler
        doctor={doctor as TDoctor}
        setStartDayOfWeek={setStartDayOfWeek}
        setEndDayOfWeek={setEndDayOfWeek}
        doctorId={doctorId}
        eventsCalendar={listEventsCalendar}
      />
    </Container>
  )
}
