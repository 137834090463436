import styled from "styled-components";

export const TableContainer = styled.div`
  padding: 12px 16px 12px 22px;
  margin-bottom: 17px;

  border-radius: 4px;
  max-width: 695px;
  width: 100%;
  box-sizing: border-box;

  border-left: 4px solid #E46869;

  display: flex;
  flex-direction: column;
  
  background: white;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const NameBlock = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled.span`
  font-weight: 600;
`;

export const Photo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;

  margin-right: 7px;
`;

export const NavLinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const NavLinkBlock = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 15px 20px 0 0;
`;

export const Item = styled.p``;