import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { api, Assets, Button, Text, useTypedSelector } from "common";
import { getAuthSelector } from "store/auth";

import {
  TableContainer,
  NameBlock,
  Photo,
  Contact,
  Date,
  NavLinkBlock,
  NavLinkContainer,
  Image,
  Link,
  InfoBlock,
  TextBlock,
} from "./styled";
import { TTable } from "./types";

export const Table = ({ patient }: TTable) => {
  const navigate = useNavigate();

  const { type } = useTypedSelector(getAuthSelector);

  const navigatePage = (url: string) => {
    navigate(url);
  };

  return (
    <TableContainer deletedAccount={patient?.deleted}>
      <NameBlock>
        <Photo
          src={
            patient?.photo
              ? `${api.withImageAPI}/user/${patient?.photo}`
              : Assets.ACCOUNT
          }
        />
        <TextBlock>
          <Text
            text={`${patient?.surname} ${patient?.name} ${patient?.patronymic}`}
          />
          {patient?.subscription?.productId === "1year_subscription" && (
            <Text margin="3px 0 0 0" text="(Підписка на рік)" />
          )}
          {patient?.subscription?.productId === "half_year_subscription" && (
            <Text margin="3px 0 0 0" text="(Підписка на пів року)" />
          )}
          {patient?.subscription?.productId === "3month_subscription" && (
            <Text margin="3px 0 0 0" text="(Підписка на 3 місяці)" />
          )}
        </TextBlock>
      </NameBlock>
      <InfoBlock>
        <Contact>{patient?.phone}</Contact>
        <Date>{moment(patient?.birthday).format("DD/MM/YYYY")}</Date>
      </InfoBlock>
      <NavLinkContainer>
        {type === "admin" && (
          <NavLinkBlock
            onClick={() => navigatePage(`/doctors/${patient?._id}`)}
          >
            <Image src={Assets.ACCOUNT} />
            <Link>Лікарі ({patient?.doctorsCount})</Link>
          </NavLinkBlock>
        )}
        <NavLinkBlock onClick={() => navigatePage(`/chat/${patient?._id}`)}>
          <Image src={Assets.CHAT} />
          <Link>Чат</Link>
        </NavLinkBlock>
        <NavLinkBlock
          onClick={() =>
            navigatePage(`/pastConsultations/doctorAll/${patient?._id}`)
          }
        >
          <Image src={Assets.DOCUMENT} />
          <Link>Консультації ({patient?.appointmentsCount})</Link>
        </NavLinkBlock>
        <NavLinkBlock
          onClick={() => navigatePage(`/patient-files/${patient?._id}/all`)}
        >
          <Image src={Assets.TRAY} />
          <Link>Файли</Link>
        </NavLinkBlock>
        {type === "admin" && (
          <NavLinkBlock
            onClick={() => navigatePage(`/patient/${patient?._id}`)}
          >
            <Image src={Assets.EDIT} />
            <Link>Редагувати</Link>
          </NavLinkBlock>
        )}
      </NavLinkContainer>
      {type === "admin" && (
        <Button
          onClick={() =>
            navigatePage(`/newConsultation/${patient?._id}/doctorAll`)
          }
          disabled={patient?.deleted}
          title="Записати"
          size={14}
          height={32}
          width={90}
        />
      )}
    </TableContainer>
  );
};
