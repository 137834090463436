import React, { SyntheticEvent, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import moment from "moment"
import { useParams } from "react-router-dom"

import { appointmentActions, getAppointmentSelector } from "store/appointment"
import { getAuthSelector } from "store/auth"

import { ListConsultations } from "pages/ListConsultations"
import {
  Assets,
  MainNavbar,
  RelativePreloader,
  Title,
  useTypedSelector,
} from "common"

import {
  DateContainer,
  Header,
  Main,
  ReactDatePickerStyled,
  Image,
  Span,
} from "./styled"
import { ITEM_PER_PAGE } from "./consts"
import { TForm, TFormData } from "./types"

export const PastConsultations = () => {
  const { register, control, watch } = useForm<TForm>({
    defaultValues: {
      startDate: new Date("2022-10-10"),
      endDate: new Date(),
    },
  })
  const dispatch = useDispatch()
  const { doctorId, userId } = useParams()

  const { doctorPlain, doctorPlainLoading } = useTypedSelector(
    getAppointmentSelector
  )
  const { type, currentId } = useTypedSelector(getAuthSelector)

  const [page, setPage] = useState<number>(0)
  const [formData, setFormData] = useState<TFormData>({
    doctor: "",
    patient: "",
    speciality: [],
    clinic: "",
  })

  const watchAllFields = watch()

  useEffect(() => {
    const end = moment(watchAllFields.endDate).format("YYYY-MM-DDTHH:mm:ss") > moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
      ? moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
      : moment(watchAllFields.endDate).format("YYYY-MM-DDTHH:mm:ss");

    const start = moment(watchAllFields.startDate).format("YYYY-MM-DDTHH:mm:ss");

    dispatch(appointmentActions.getDoctorPlain({
      doctor: type === "admin" ? formData.doctor : currentId,
      end,
      start,
      user: formData.patient,
      speciality: formData.speciality,
      skip: page,
      limit: ITEM_PER_PAGE,
      sortBy: "startTime",
      order: -1,
      status: "completed",
      clinic: formData.clinic,
    }));
  }, [page, watchAllFields.startDate, formData, watchAllFields.endDate, currentId, type, doctorId, userId, dispatch]);

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement
    setFormData((props: TFormData) => ({ ...props, [name]: value }))
  }

  useEffect(() => {
    setFormData({
      doctor: doctorId === "doctorAll" ? "" : doctorId,
      patient: userId === "userAll" ? "" : userId,
      speciality: [],
      clinic: "",
    })
  }, [doctorId, userId])

  return (
    <React.Fragment>
      <MainNavbar />
      <Main>
        <Header>
          <Title title={"Проведені консультації"} />
          <DateContainer>
            <Image src={Assets.CALENDAR_FOR_INPUT} />
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <ReactDatePickerStyled
                  placeholderText="Виберіть дату"
                  onChange={(e) => field.onChange(e)}
                  selected={field.value}
                  dateFormat={"dd/MM/yyyy"}
                />
              )}
            />
            <Span>-</Span>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => (
                <ReactDatePickerStyled
                  placeholderText="Виберіть дату"
                  onChange={(e) => field.onChange(e)}
                  selected={field.value}
                  dateFormat={"dd/MM/yyyy"}
                />
              )}
            />
          </DateContainer>
        </Header>
        {!doctorPlainLoading ? (
          <ListConsultations
            page={page}
            inputChangeHandler={inputChangeHandler}
            formData={formData}
            getAppointmentPlain={() => ""}
            setPage={setPage}
            register={register}
            typeConsultations={"past"}
            consultations={doctorPlain}
          />
        ) : (
          <RelativePreloader loading={doctorPlainLoading} />
        )}
      </Main>
    </React.Fragment>
  )
}
