import { createAction } from "@reduxjs/toolkit"
import {
  CREATE_NEWS,
  DELETE_NEWS,
  GET_NEWS,
  PATCH_NEWS,
  SET_NEWS,
  SET_NEWS_STATE,
} from "./consts"
import { TDeleteNewsPayload, TGetNewsPayload } from "./types"

export const newsActions = {
  setNewState: createAction(SET_NEWS_STATE, (payload) => ({ payload })),

  createNews: createAction(CREATE_NEWS, (payload) => ({
    payload,
  })),

  getNews: createAction(GET_NEWS, (payload: TGetNewsPayload) => ({ payload })),
  setNews: createAction(SET_NEWS, (payload) => ({ payload })),

  deleteNews: createAction(DELETE_NEWS, (payload: TDeleteNewsPayload) => ({
    payload,
  })),

  patchNews: createAction(PATCH_NEWS, (payload) => ({ payload })),
}
