import ReactDatePicker from "react-datepicker";
import styled from "styled-components";

export const Main = styled.div`
  padding: 42px 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const DateContainer = styled.div`
  display: flex;
  width: 188px;
  background-color: white;
  padding: 12px 21px 12px 39px;
  position: relative;
  border-radius: 8px;

  @media (max-width: 900px) {
    margin-top: 20px;
  }

  @media (max-width: 500px) {
    margin: 20px auto;
  };
`;

export const ReactDatePickerStyled = styled(ReactDatePicker)`
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid white;
  font-size: 14px;
  font-size: 14px;
  font-weight: 300;
  outline: none;
  width: 94px;

  &::placeholder {
    font-size: 13px;
  }
`;

export const Image = styled.img`
  position: absolute;
  left: 10px;
`;

export const Span = styled.span`
  margin-right: 14px;
`;
