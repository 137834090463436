import ReactDatePicker from "react-datepicker";
import styled from "styled-components";
import Select from "react-select";

import { colors } from "common";

import { TSelect } from "./types";

export const DatePickerBlock = styled.div`
  position: relative;
  width: 128px;

  .time-input {
    background-color: white;
    width: 128px;
    height: 42.5px;
    overflow-x: hidden;
    border-radius: 8px;
  }

  .time-input:focus {
    outline: none;
  }

  .css-1xhypcz-MuiStack-root {
    overflow: hidden;
  }
`;

export const FormBlock = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 560px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const ReactDatePickerStyled = styled(ReactDatePicker)`
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${colors.white};
  margin-bottom: 10px;
  font-size: 14px;
  width: 128px;
  font-size: 14px;
  font-weight: 300;
  padding: 12px 10px;
  margin-top: 2px;

  position: relative;
  &::placeholder {
    font-size: 13px;
  }
`;

export const Image = styled.img`
  position: absolute;
  z-index: 100;
  top: 32px;
  right: 10px;
`;

export const SelectBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DefaultSelect = styled.select<TSelect>`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  margin-top: 2px;

  padding: 11px;

  width: ${({ width }) => `${width}px`};

  cursor: pointer;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }
`;

export const StyledSelect = styled(Select)`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  padding: 11px;

  width: 330px;

  cursor: pointer;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 420px) {
    width: 295px;
  }
`;
