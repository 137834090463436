import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form"
import { Dayjs } from "dayjs"
import moment from "moment"

import {
  appointmentActions,
  getAppointmentSelector,
  TDocsDoctorPlain,
} from "store/appointment"
import { getUsersSelector, usersActions } from "store/users"

import { MainNavbar, RelativePreloader, useTypedSelector } from "common"
import { Consultation } from "pages/Consultation"

import { Container } from "./styled"
import { TFormData } from "./types"

export const UpdateConsultationPage = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })

  const { currentAppointment, loading } = useTypedSelector(
    getAppointmentSelector
  )
  const { users, user } = useTypedSelector(getUsersSelector)

  const [formData, setFormData] = useState<TFormData>({
    date: "",
    clinic: "",
    doctor: "",
    speciality: '',
    typeConsultation: "",
  })
  const [startDate, setStartDate] = React.useState<Dayjs | string | Date>(moment().subtract("minutes", -5).toDate());
  const [endDate, setEndDate] = React.useState<Dayjs | string | Date>(moment().subtract("minutes", -35).toDate());

  const [userId, setUserId] = useState<string>("")

  useEffect(() => {
    dispatch(appointmentActions.getCurrentAppointment({ _id: id }))
  }, [])

  useEffect(() => {
    dispatch(usersActions.getUsers({}))
  }, [])

  useEffect(() => {
    if (currentAppointment?.user) {
      setUserId(currentAppointment?.user?._id)
    }
  }, [currentAppointment?.user])

  useEffect(() => {
    if (currentAppointment) {
      setFormData({
        date: new Date(currentAppointment?.startTime),
        clinic: currentAppointment?.doctor?.clinic,
        doctor: currentAppointment?.doctor?._id,
        speciality: currentAppointment?.speciality,
        typeConsultation: currentAppointment?.type,
      })
      setStartDate(new Date(currentAppointment?.startTime))
      setEndDate(new Date(currentAppointment?.endTime))
    }
  }, [currentAppointment])

  return (
    <React.Fragment>
      {!loading ? (
        <Container>
          <MainNavbar navigate="/futureConsultations" />
          <Consultation
            startDate={startDate as string}
            endDate={endDate as string}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            appointment={currentAppointment as TDocsDoctorPlain}
            handleSubmit={handleSubmit}
            register={register}
            idAppointment={currentAppointment?._id}
            errors={errors}
            crudtype="update"
            formData={formData}
            setFormData={setFormData}
            userId={userId}
            setUserId={setUserId}
            users={users?.docs}
            user={user}
          />
        </Container>
      ) : (
        <RelativePreloader loading={loading} />
      )}
    </React.Fragment>
  )
}
