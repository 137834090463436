import React from 'react';

import { colors } from 'common/styles';

import { RelativeLoadingBar, AbsoluteContainer } from './styled';
import { TPreloader } from './types';

export const RelativePreloader = ({ loading, size = '85px', secondaryColor = colors.white}: TPreloader) => {
   return (
      <>
         {loading && (
            <AbsoluteContainer>
               <RelativeLoadingBar size={size} secondaryColor={secondaryColor} />
            </AbsoluteContainer>
         )}
      </>
   )
};
