import { FC } from "react";

import { colors, Pagination, Text } from "common";
import { ITEM_PER_PAGE } from "pages/SupportPage/consts";
import { TSupport } from "store/support";

import {
  HeaderSection,
  MainBlock,
  SupportContainer,
  TextBlock,
} from "./styled";
import { SupportComponentProps } from "./types";
import moment from "moment";

export const SupportComponent: FC<SupportComponentProps> = ({
  isActive,
  currentSupportData,
  support,
  page,
  setPage,
  setCurrentSupportData,
  setIsActive,
}) => {
  const navigateHandler = (el: TSupport) => {
    setCurrentSupportData(el);
    setIsActive(!isActive);
  };

  const onPageChangeHandler = ({ selected }: { selected: number }) => {
    setPage(selected);
  };

  return (
    <SupportContainer $isActive={isActive} $position="left">
      <HeaderSection $justify="space-around">
        <Text text="Дата" color={`${colors.gray}`} />
        <Text text="Повідомлення" color={`${colors.gray}`} />
        <Text text="Статус" color={`${colors.gray}`} />
      </HeaderSection>

      <div>
        {support.docs.map((supportMessage?) => (
          <MainBlock
            onClick={() => navigateHandler(supportMessage)}
            key={supportMessage?._id}
            $background={
              currentSupportData?._id === supportMessage?._id
                ? "#E3EBE8"
                : "transparent"
            }
          >
            <TextBlock>
              <Text
                text={moment(supportMessage?.createdAt).format('HH:mm YYYY.MM.DD')}
              />
            </TextBlock>
            <TextBlock>
              <Text text={supportMessage?.message} /> 
            </TextBlock>
            <TextBlock>
              <Text
                text={
                  supportMessage?.status == "new"
                    ? "Нове звернення"
                    : supportMessage?.status
                }
                margin="0 0 0 25px"
              />
            </TextBlock>
          </MainBlock>
        ))}
      </div>

      <Pagination
        page={page}
        pageCount={
          support.meta?.totalCount
            ? Math.ceil(support?.meta?.totalCount / ITEM_PER_PAGE)
            : 1
        }
        onPageChange={onPageChangeHandler}
      />
    </SupportContainer>
  );
};
