export const GET_CHAT = "GET_CHAT"
export const SET_CHAT = "SET_CHAT"
export const SET_CHAT_STATE = "SET_CHAT_STATE"
export const SET_CHAT_ID_MESSAGES = "SET_CHAT_ID_MESSAGES"
export const GET_CHAT_ID_MESSAGES = "GET_CHAT_ID_MESSAGES"
export const SET_CHAT_ID = "SET_CHAT_ID"
export const GET_CHAT_ID = "GET_CHAT_ID"
export const CREATE_MESSAGE = "CREATE_MESSAGE"
export const SET_MESSAGE = "SET_MESSAGE"
export const CHAT_READ = "CHAT_READ"
export const RENAME_FILE = "RENAME_FILE"
