import { Route, Routes } from 'react-router-dom';

import { routes } from './routes';

export const NotAuthRoutes = () => {
	return (
		<Routes>
			<Route path={routes.loginPhone.path} element={<routes.loginPhone.component/>}/>

			<Route path={routes.loginCode.path} element={<routes.loginCode.component />} />

			<Route path={routes.registerAccount.path} element={<routes.registerAccount.component />} />

			<Route path={routes.notFounded.path} element={<routes.notFounded.component/>}/>
		</Routes>
	);
}