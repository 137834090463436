import { all } from "redux-saga/effects"

import { appointmentWatcher } from "./appointment"
import { doctorsWatcher } from "./doctors"
import { usersWatcher } from "./users"
import { chatWatcher } from "./chat"
import { authWatcher } from "./auth"
import { toastsSaga } from "./toasts"
import { newsWatcher } from "./news"
import { supportWatcher } from "./support"
import { clinicsWatcher } from "./clinics"
import { notificationsWatcher } from "./notification/saga"

function* rootSaga() {
  yield all([
    toastsSaga(),
    doctorsWatcher(),
    usersWatcher(),
    appointmentWatcher(),
    chatWatcher(),
    authWatcher(),
    newsWatcher(),
    supportWatcher(),
    clinicsWatcher(),
    notificationsWatcher()
  ])
}

export default rootSaga
