import React, { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { MainNavbar, RelativePreloader, Title, useTypedSelector } from "common";
import { getUsersSelector, usersActions } from "store/users";
import { Patient } from "pages/Patient";

import { Main } from "./styled";
import { TCoordinates, TForm, TFormData } from "./types";

export const EditPatient = () => {
  const { user, userLoading } = useTypedSelector(getUsersSelector);

  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<TForm>({
    mode: "onSubmit",
  });

  const watchAllFields = watch();

  const [formData, setFormData] = useState<TFormData>({
    additionalInfo: "",
    city: "",
    entrance: "",
    flat: "",
    floor: "",
    house: "",
    street: "",
    birthday: "",
  });
  const [value, setValue] = useState<string>('');
	const [coordinates, setCoordinates] = useState<TCoordinates>({
		lat: 0,
		lon: 0
	});
  const [isAddress, setIsAddress] = useState<boolean>(false);

  const getUser = () => {
    dispatch(usersActions.getUser({ _id: id }));
    reset();
  };

  const updatePatientHandler = () => {
    const data = {
      name: watchAllFields.name,
      surname: watchAllFields.surname,
      patronymic: watchAllFields.patronymic,
      phone: watchAllFields.phone,
      gender: watchAllFields.gender,
      address: {
        city: value,
        street: formData?.street,
        house: formData?.house,
        flat: formData?.flat,
        entrance: formData?.entrance,
        floor: formData?.floor,
        additionalInfo: formData?.additionalInfo,
      },
      location: {
        coordinates: [coordinates.lat, coordinates.lon]
      },
      birthday: formData.birthday,
      email: watchAllFields.email,
      insurance: watchAllFields.insurance,
    };
    dispatch(usersActions.updateUser({ _id: id, data }));
    getUser();
  };

  useEffect(() => {
    if (user) {
      setFormData({
        additionalInfo: user?.address?.additionalInfo,
        city: user?.address?.city,
        entrance: user?.address?.entrance,
        flat: user?.address?.flat,
        floor: user?.address?.floor,
        house: user?.address?.house,
        street: user?.address?.street,
        birthday: user?.birthday
      });
      setValue(user?.address?.city)
      setIsAddress(true);
    }
  }, [user]);

  useEffect(() => {
    getUser();
  }, []);

	const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((props: TFormData) => ({ ...props, [name]: value }));
  };

  useEffect(() => {
    if(!isAddress){
      setFormData({
        additionalInfo: user?.address?.additionalInfo ?? '',
        city: user?.address?.city ?? '',
        entrance: user?.address?.entrance ?? '',
        flat: user?.address?.flat ?? '',
        floor: user?.address?.floor ?? '',
        house: user?.address?.house ?? '',
        street: user?.address?.street ?? '',
        birthday: user?.birthday ?? ''
      });
      setValue(user?.address?.city ?? '')
    }
  }, [isAddress, user]);

  return (
    <React.Fragment>
      {!userLoading ? (
        <React.Fragment>
          <MainNavbar navigate={"/patients/all"} />
          <Main>
          {user?.subscription?.productId === '1year_subscription' && <Title title='Пацієнт - (Підписка 1 рік)' />}
          {user?.subscription?.productId === 'half_year_subscription' && <Title title='Пацієнт - (Підписка на пів року)' />}
          {user?.subscription?.productId === '3month_subscription' && <Title title='Пацієнт - (Підписка на 3 місяці)' />}
          {!user?.subscription?.productId  && <Title title='Пацієнт'/>}
            <Patient
              setFormData={setFormData as () => void}
              value={value}
              setValue={setValue}
              setCoordinates={setCoordinates}
						  formData={formData}
						  inputChangeHandler={inputChangeHandler}
              handleSubmit={handleSubmit}
              user={user}
              isAddress={isAddress}
              setIsAddress={setIsAddress}
              register={register}
              errors={errors}
              CRUDFunction={updatePatientHandler}
            />
          </Main>
        </React.Fragment>
      ) : (
        <RelativePreloader loading={userLoading} />
      )}
    </React.Fragment>
  );
};
