import { useState, useEffect, useRef } from "react"

import { Assets } from "common/assets"

import {
  Container,
  ImageButton,
  ContainerPhotoPicker,
  PhotoPickerBlock,
  PlusIcon,
  AttachIcon,
} from "./styled"
import { TPhotoPicker } from "./types"

export const PhotoPicker = ({
  width,
  height,
  name,
  nameImage,
  onChange,
  clearPhoto,
  roundCorners,
  value,
  index,
  onKeySendMessages,
}: TPhotoPicker) => {
  const [photo, setPhoto] = useState<string>(value as string)
  const [file, setFile] = useState<File | null>()
  const [oldFile, setOldFile] = useState<File>()

  const inputRef = useRef<HTMLInputElement>(null)

  const Events = {
    buttonClickHandler: () => {
      const input = inputRef.current as HTMLInputElement

      input.onchange = (e) => {
        const input = e.target as HTMLInputElement

        file && setOldFile(file)
        const newFile = input.files ? input.files[0] : null

        setFile(newFile)
        ;(e.target as HTMLInputElement).value = ""
      }

      input.click()
    },
  }

  const Utils = {
    readFileAsImage: () => {
      const reader = new FileReader()
      file && reader.readAsDataURL(file)
      reader.onload = (e) => {
        setPhoto((e.target as FileReader).result as string)
      }
    },
  }

  // When file was changed
  useEffect(() => {
    file && Utils.readFileAsImage()
    file !== undefined &&
      onChange &&
      onChange(file, index as number, name as string, oldFile || null)
  }, [file])

  useEffect(() => {
    setPhoto(value as string)
  }, [value])

  useEffect(() => {
    if (clearPhoto?.length === 0) {
      setPhoto("")
    }
  }, [clearPhoto])

  return (
    <Container>
      <ContainerPhotoPicker>
        <PhotoPickerBlock>
          {file?.type.includes("video") ? (
            <ImageButton
              width={width}
              height={height}
              roundCorners={roundCorners}
              onClick={Events.buttonClickHandler}
              src={clearPhoto?.length === 0 ? "" : Assets.VIDEO}
              nameImage={nameImage}
            >
              {nameImage === "attach" ? <AttachIcon /> : <PlusIcon />}
              <input
                className="photo-input"
                name={name + "input"}
                ref={inputRef}
                type="file"
              />
            </ImageButton>
          ) : (
            <ImageButton
              width={width}
              height={height}
              onKeyDown={onKeySendMessages}
              roundCorners={roundCorners}
              onClick={Events.buttonClickHandler}
              src={
                clearPhoto?.length === 0
                  ? ""
                  : !file?.type?.includes("application")
                  ? photo
                  : Assets.DOCUMENT
              }
              nameImage={nameImage}
            >
              {nameImage === "attach" ? <AttachIcon /> : <PlusIcon />}
              <input
                className="photo-input"
                name={name + "input"}
                ref={inputRef}
                type="file"
              />
            </ImageButton>
          )}
        </PhotoPickerBlock>
      </ContainerPhotoPicker>
    </Container>
  )
}
