import { createReducer } from '@reduxjs/toolkit';

import { appointmentActions } from './actions';
import { TInitialState } from './types';

const InitialState: TInitialState = {
	appointmentByDoctor: [],
	loading: false,
	currentAppointmentLoading: false,
	response: null,
	doctorPlainLoading: false,
	doctorPlain: {
		docs: [],
		meta: null,
	},
	currentAppointment: null,
}

export const appointmentReducer = createReducer<TInitialState>(InitialState, (builder) => {
	builder
		.addCase(appointmentActions.setAppointmentByDoctor, (state, { payload }) => ({ ...state, appointmentByDoctor: payload }))
		.addCase(appointmentActions.setDoctorPlain, (state, { payload }) => ({ ...state, doctorPlain: payload }))
		.addCase(appointmentActions.setCurrentAppointment, (state, { payload }) => ({ ...state, currentAppointment: payload }))
		.addCase(appointmentActions.setAppointmentState, (state, { payload }) => ({ ...state, response: null, ...payload}))
});