import styled from "styled-components";

import { colors } from "common";

import { TDateBlock } from "./types";

export const Main = styled.div`
  padding: 42px 30px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const DateContainer = styled.div`
  display: flex;

  @media (max-width: 900px) {
    margin-top: 20px;
  } ;
`;

export const DateBlock = styled.div<TDateBlock>`
  display: block;
  padding: 10px 16px;
  cursor: pointer;
  color: ${colors.darkGreen};
  font-size: 16px;
  font-weight: 600;
  border-radius: 12px;
  border: 2px;
  border: ${({ isactive }) =>
    isactive ? `2px solid ${colors.green}` : `2px solid transparent`};

  &:not(:last-child) {
    margin-right: 14px;
  }

  @media (max-width: 900px) {
    padding: 8px 16px;

    &:not(:last-child) {
      margin-right: 7px;
    }
  };
`;
