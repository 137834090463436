import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { specialityOptions, useTypedSelector } from "common";

import { getUsersSelector, usersActions } from "store/users";
import { doctorsActions, getDoctorsSelector } from "store/doctors";
import { getAuthSelector } from "store/auth";

import { Form, FormBlock, Label, Select } from "./styled";
import { TForms } from "./types";
import { clinicsActions, getClinicsSelector } from "store/clinics";

export const Forms = ({ inputChangeHandler, formData }: TForms) => {
  const dispatch = useDispatch();

  const { users } = useTypedSelector(getUsersSelector);
  const { doctors } = useTypedSelector(getDoctorsSelector);
  const { type, currentId } = useTypedSelector(getAuthSelector);
  const { clinics } = useTypedSelector(getClinicsSelector);

  const clinicsOptions = clinics.map(
    (clinic) =>
      clinic.address
        .map((address) => {
          if (address.lang === "uk") {
            return { name: address.value, value: clinic._id };
          }
        })
        .filter((item) => item)[0]
  );

  useEffect(() => {
    dispatch(
      usersActions.getUsers({
        limit: 500,
        doctor: type === "admin" ? undefined : currentId,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      doctorsActions.getDoctors({ speciality: formData.speciality, limit: 500 })
    );
  }, [formData.speciality]);

  useEffect(() => {
    dispatch(clinicsActions.getClinics({}));
  }, []);

  return (
    <Form>
      <FormBlock>
        <Label>Пацієнт</Label>
        <Select
          name="patient"
          onChange={inputChangeHandler}
          value={formData.patient}
        >
          <option value="">{`Всі (${users?.docs.length ?? "0"})`}</option>
          {users?.docs?.map((option) => (
            <option key={option._id} value={option._id}>
              {`${option.surname} ${option?.name
                ?.slice(0, 1)
                .toUpperCase()}.${option?.patronymic
                ?.slice(0, 1)
                .toUpperCase()}.`}
            </option>
          ))}
        </Select>
      </FormBlock>
      {type === "admin" && (
        <>
          <FormBlock>
            <Label>Спеціалізація</Label>
            <Select
              onChange={inputChangeHandler}
              name="speciality"
              value={formData?.speciality}
            >
              <option value="">
                {`Всі спеціалізації (${specialityOptions?.length})`}
              </option>
              {specialityOptions.map((option, index: number) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormBlock>
          <FormBlock>
            <Label>Лікар</Label>
            <Select
              onChange={inputChangeHandler}
              name="doctor"
              value={formData.doctor}
            >
              <option value="">
                {`Всі (${doctors?.docs?.length ?? "0"})`}
              </option>
              {doctors?.docs?.map((option) => (
                <option key={option._id} value={option._id}>
                  {`${option.surname} ${option?.name
                    ?.slice(0, 1)
                    .toUpperCase()}.${option?.patronymic
                    ?.slice(0, 1)
                    .toUpperCase()}.`}
                </option>
              ))}
            </Select>
          </FormBlock>
        </>
      )}
      <FormBlock>
        <Label>Клініка</Label>
        <Select
          onChange={inputChangeHandler}
          name="clinic"
          value={formData.clinic}
        >
          <option value="">{`Всі (${clinicsOptions?.length ?? "0"})`}</option>
          {clinicsOptions.map((option, index: number) => (
            <option key={index} value={option?.value}>
              {option?.name}
            </option>
          ))}
        </Select>
      </FormBlock>
    </Form>
  );
};
