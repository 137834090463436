import { api } from "common/config/api"

export const CHAT_URL = {
  getChat: `${api.withAPI}api/chat/search`,
  getChatIdMessages: `${api.withAPI}api/chat`,
  getCurrentChatData: `${api.withAPI}api/chat`,
  createMessage: `${api.withAPI}api/chat`,
  chatRead: `${api.withAPI}api/chat`,
  renameFile: `${api.withAPI}api/chat/rename`,
}
