import { createAction } from "@reduxjs/toolkit"
import {
  CLOSE_SUPPORT,
  GET_SUPPORT,
  SET_SUPPORT,
  SET_SUPPORT_STATE,
} from "./consts"

export const supportActions = {
  getSupport: createAction(GET_SUPPORT, (payload) => ({ payload })),
  setSupport: createAction(SET_SUPPORT, (payload) => ({ payload })),
  setSupportState: createAction(SET_SUPPORT_STATE, (payload) => ({ payload })),
  closeSupport: createAction(CLOSE_SUPPORT, (payload) => ({ payload })),
}
