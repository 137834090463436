import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 506px;

  overflow-y: scroll;

  &::-webkit-scrollbar { width: 0; }

  padding: 16px 21px;

  
  @media (max-width: 1030px) {
    height: 60vh;
  };
`;

export const ChannelContainer = styled.div``;

export const ChannelBlock = styled.div``;

export const Div = styled.div`
  padding: 5px;
`;

export const LoadingText = styled.div`
  font-size: 18px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
`;