import { call, put, takeLatest } from "redux-saga/effects";

import { getAccessToken } from "store/auth";
import { showToastAction } from "store/toasts";
import { TDataWrapper, TResponse } from "store/types";
import { generateErrorToast, generateSuccessToast } from "common/helpers";

import { appointmentActions } from "./actions";
import { ApiAppointmentService } from "./api.service";
import {
  TAppointmentCompleteRequestPayload,
  TCreateAppointmentBulkPayload,
  TCreateAppointmentPayload,
  TCreateAppointmentRepeatSlotPayload,
  TDeleteAppointmentCurrentDatePayload,
  TDeleteAppointmentPayload,
  TDeleteAppointmentRepeatTablePayload,
  TDoctorPlainPayload,
  TGetAppointmentByDoctorPayload,
  TGetCurrentAppointmentPayload,
  TUpdateAppointmentPayload,
} from "./types";

function* getAppointmentWorker({
  payload,
}: TDataWrapper<TGetAppointmentByDoctorPayload>) {
  yield put(appointmentActions.setAppointmentState({ loading: true }));

  const { _id, start, end, timezone } = payload;
  const token: string = yield call(getAccessToken);

  try {
    const response: TResponse = yield call(
      [ApiAppointmentService, ApiAppointmentService.getAppointmentByDoctor],
      { _id, start, end, timezone, token }
    );
    if (response.data) {
      yield put(appointmentActions.setAppointmentByDoctor(response.data));
      yield put(appointmentActions.setAppointmentState({ loading: false }));
    } else {
      yield put(appointmentActions.setAppointmentByDoctor([]))
      yield put(appointmentActions.setAppointmentState({ loading: false }));
    }
  } catch (e) {
    yield put(appointmentActions.setAppointmentByDoctor([]))
  }finally{
    yield put(appointmentActions.setAppointmentState({ response: 'GET_APPOINTMENT' }));
  }
}

function* getCurrentAppointmentWorker({ payload }: TDataWrapper<TGetCurrentAppointmentPayload>) {
  yield put(appointmentActions.setAppointmentState({ currentAppointmentLoading: true }));
  const { _id } = payload;
  const token: string = yield call(getAccessToken);

  try {
    const response: TResponse = yield call(
      [ApiAppointmentService, ApiAppointmentService.getCurrentAppointment],
      { _id, token }
    );
    if (response.data) {
      yield put(appointmentActions.setCurrentAppointment({...response.data}));
    }
  } catch (e) {}
  yield put(appointmentActions.setAppointmentState({ currentAppointmentLoading: false }));
}

function* getDoctorPlainWorker({ payload }: TDataWrapper<TDoctorPlainPayload>) {
  yield put(appointmentActions.setAppointmentState({ doctorPlainLoading: true }));

  const { doctor, start, end, skip, limit, user, speciality, sortBy, order, status, clinic } = payload;
  const token: string = yield call(getAccessToken);

  try {
    const response: TResponse = yield call(
      [ApiAppointmentService, ApiAppointmentService.getDoctorPlainByDoctor],
      { doctor, start, end, skip, limit, user, token, speciality, sortBy, order, status, clinic }
    );
    if (response.data) {
      yield put(appointmentActions.setDoctorPlain(response.data));
      yield put(appointmentActions.setAppointmentState({ doctorPlainLoading: false }));
    }
  } catch (e) {
    yield put(appointmentActions.setDoctorPlain({ data: [], meta: null }));
  }
}

function* createAppointmantWorker({
  payload,
}: TDataWrapper<TCreateAppointmentPayload>) {
  const { data } = payload;
  const token: string = yield call(getAccessToken);

  try {
   const response:TResponse = yield call(
      [ApiAppointmentService, ApiAppointmentService.createAppointment],
      { data, token }
   );
   yield put(showToastAction.request(generateSuccessToast('Консультацію успішно створено')));
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast('Консультація вже існує')));
  }finally{
    yield put(appointmentActions.setAppointmentState({ response: 'CREATE_APPOINTMENT' }));
  }
}

function* appointmantCompleteWorker({ payload }: TDataWrapper<TAppointmentCompleteRequestPayload>) {
  const { data, _id } = payload;
  const token: string = yield call(getAccessToken);

  try {
    yield call(
      [ApiAppointmentService, ApiAppointmentService.appointmentComplete],
      { _id, data, token }
    );
    yield put(showToastAction.request(generateSuccessToast('Консультація успішно завершена')));
  } catch (e) {}
}

function* createAppointmantBulk({
  payload,
}: TDataWrapper<TCreateAppointmentBulkPayload>) {
  const { data } = payload;
  const token: string = yield call(getAccessToken);

  try {
    yield call(
      [ApiAppointmentService, ApiAppointmentService.createAppointmentBulk],
      { data, token }
    );
    yield put(showToastAction.request(generateSuccessToast('Розклад скопійовано на наступний тиждень')));
  } catch (e) {
    yield put(showToastAction.request(generateSuccessToast('Помилка копіювання розкладу')));
  }
}

function* createAppointmentRepeatSlotWorker({
  payload,
}: TDataWrapper<TCreateAppointmentRepeatSlotPayload>) {
  const { data } = payload;
  const token: string = yield call(getAccessToken);

  try {
    const response: TResponse = yield call(
      [
        ApiAppointmentService,
        ApiAppointmentService.createAppointmentRepeatSlot,
      ],
      { data, token }
    );
    yield put(showToastAction.request(generateSuccessToast('Консультації успішно створено')));
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast('Помилка створення консультацій')));
  }finally{
    yield put(appointmentActions.setAppointmentState({ response: 'CREATE_APPOINTMENT' }));
  }
}

function* updateAppointmentWorker({
  payload,
}: TDataWrapper<TUpdateAppointmentPayload>) {
  const { _id, data } = payload;
  const token: string = yield call(getAccessToken);

  try {
    const response:TResponse = yield call(
      [ApiAppointmentService, ApiAppointmentService.updateAppointment],
      { _id, data, token }
    );
    yield put(showToastAction.request(generateSuccessToast('Консультацію успішно оновлено')));
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast('Помилка оновлення консультації')));
  }finally{
    yield put(appointmentActions.setAppointmentState({ response: 'UPDATE_APPOINTMENT' }));
  }
}

function* deleteAppointmentWorker({
  payload,
}: TDataWrapper<TDeleteAppointmentPayload>) {
  const { _id } = payload;
  const token: string = yield call(getAccessToken);

  try {
    yield call(
      [ApiAppointmentService, ApiAppointmentService.delteAppointment],
      { _id, token }
    );
    yield put(showToastAction.request(generateSuccessToast('Консультацію успішно видалено')));
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast('Помилка видалення консультації')));
  }finally{
    yield put(appointmentActions.setAppointmentState({ response: 'DELETE_APPOINTMENT' }));
  }
}

function* deleteAppointmentRepeatTabletWorker({
  payload,
}: TDataWrapper<TDeleteAppointmentRepeatTablePayload>) {
  const { group, start, end, doctor, emptyOnly } = payload;
  const token: string = yield call(getAccessToken);

  try {
    yield call(
      [
        ApiAppointmentService,
        ApiAppointmentService.delteAppointmentRepeatTable,
      ],
      { group, start, end, token, doctor, emptyOnly }
    );
    yield put(showToastAction.request(generateSuccessToast('Консультації успішно видалено')));
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast('Помилка видалення консультацій')));
  }finally{
    yield put(appointmentActions.setAppointmentState({ response: 'DELETE_APPOINTMENT' }));
  }
}

function* deleteAppointmentCurrentDateWorker({
  payload,
}: TDataWrapper<TDeleteAppointmentCurrentDatePayload>) {
  const { date, doctor } = payload;
  const token: string = yield call(getAccessToken);

  try {
    yield call(
      [ApiAppointmentService, ApiAppointmentService.deleteAppointmentCurrentDate],
      { date, doctor, token }
    );
    yield put(showToastAction.request(generateSuccessToast('Консультації на сьогоднішній день видалено')));
  } catch (e) {
    yield put(showToastAction.request(generateErrorToast('Помилка видалення консультацій')));
  }finally{
    yield put(appointmentActions.setAppointmentState({ response: 'DELETE_APPOINTMENT' }));
  }
}

export function* appointmentWatcher() {
  yield takeLatest(
    appointmentActions.getAppointmentByDoctor,
    getAppointmentWorker
  );
  yield takeLatest(
    appointmentActions.deleteAppointmentCurrentDate,
    deleteAppointmentCurrentDateWorker,
  );
  yield takeLatest(
    appointmentActions.createAppointment,
    createAppointmantWorker
  );
  yield takeLatest(
    appointmentActions.createAppintmentBulk,
    createAppointmantBulk
  );
  yield takeLatest(appointmentActions.getDoctorPlain, getDoctorPlainWorker);
  yield takeLatest(
    appointmentActions.createAppointmentRepeatSlot,
    createAppointmentRepeatSlotWorker
  );
  yield takeLatest(
    appointmentActions.updateAppointment,
    updateAppointmentWorker
  );
  yield takeLatest(
    appointmentActions.deleteAppointment,
    deleteAppointmentWorker
  );
  yield takeLatest(
    appointmentActions.deleteAppointmentRepeatTable,
    deleteAppointmentRepeatTabletWorker
  );
  yield takeLatest(
    appointmentActions.getCurrentAppointment,
    getCurrentAppointmentWorker
  );
  yield takeLatest(
    appointmentActions.appointmentComplete,
    appointmantCompleteWorker
  );
}
