import styled from "styled-components";

export const Contaienr = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Message = styled.div`
  padding: 13px 27px 14px 16px;
  background: white;
  display: inline-block;

  border-radius: 12px;
`;

export const Video = styled.video`
  width: 180px;

  padding: 10px
`;

export const MessageBlock = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const Time = styled.div`
  font-size: 12px;
  color: #6a7d75;
  margin-top: 6px;
  margin-right: 45px;
`;

export const Photo = styled.img`
  margin-left: 8px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;