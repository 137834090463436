import styled from "styled-components";

export const Main = styled.div`
  display: flex;

  overflow-y: auto;

  padding: 42px 30px;

  @media (max-width: 1463px) {
    flex-wrap: wrap;
  };

  @media (max-width: 400px) {
    padding: 35px 15px;
  };
`;

export const FeedbackBlock = styled.div`
  overflow-y: auto;
  height: 550px;
`;

export const CommentContainer = styled.div`
  padding-left: 27px;
  width: 100%;

  @media (max-width: 1463px) {
    margin-top: 30px;
    border-left: none;
    padding-left: 0;
  } ;
`;

export const H2 = styled.h2`
  margin-bottom: 43px;
  font-size: 18px;
  font-weight: 500;
`;
