import styled from "styled-components"
import Select from "react-select"

import { TInput } from "pages/InfoAboutDoctor/types"
import { colors } from "common"
export const H2 = styled.h2`
  text-align: left;

  font-size: 16px;
  font-weight: 500;

  @media (max-width: 1010px) {
    display: flex;
    justify-content: center;
  }
`

export const FormsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 8px;

  @media (max-width: 1010px) {
    justify-content: center;
  }
`

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 16px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  @media (max-width: 1010px) {
    justify-content: center;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
`

export const Input = styled.input<TInput>`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  padding: 11px;

  width: ${({ width }) => (width ? `${width}px` : "306px")};

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 1040px) {
    width: 306px;
  }

  @media (max-width: 660px) {
    width: 306px;
  }

  @media (max-width: 420px) {
    width: 275px;
  }
`

export const StyledSelect = styled(Select)`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  padding: 11px;

  width: 330px;

  cursor: pointer;

  &::placeholder {
    font-size: 15px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 420px) {
    width: 295px;
  }
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 695px;
  width: 100%;

  margin-bottom: 30px;

  @media (max-width: 1040px) {
    align-items: center;
  }
`
