import {
  TGenderOptions,
  TLangOptions,
  TSpecialityOptions,
  TRelationsOptions,
  TSortOrderOptions,
  TConsultationOtions,
  TClinicsOptions,
  TDayOptions,
  TCalendarDataOptions,
} from "./types"

export const genderOptions: TGenderOptions[] = [
  { value: "man", name: "Чоловік" },
  { value: "woman", name: "Жінка" },
  { value: "other", name: "Інші" },
]

export const langOptions: TLangOptions[] = [
  { value: "uk", name: "Українська" },
  { value: "en", name: "Англійська" },
  { value: "ru", name: "Російська" },
]

export const sortOrderOptions: TSortOrderOptions[] = [
  { value: -1, name: "Останні додані спочатку" },
  { value: 1, name: "Перші додані спочатку" },
]

export const specialityOptions: TSpecialityOptions[] = [
  { label: "Ендокринолог", value: "endocrinologist" },
  { label: "Терапевт", value: "therapist" },
  { label: "Педіатр", value: "pediatrician" },
  { label: "Кардіолог", value: "cardiologist" },
  { label: "Невролог", value: "neurologist" },
  { label: "Гінеколог", value: "gynecologist" },
  { label: "Уролог-андролог", value: "urologistAndrologist" },
  { label: "Нефролог", value: "nephrologist" },
  { label: "ЛОР", value: "ent" },
  { label: "Офтальмолог", value: "ophthalmologist" },
  { label: "Дерматолог", value: "dermatologist" },
  { label: "Ортопед-травматолог", value: "orthopedistTraumatologist" },
  { label: "Психолог", value: "psychologist" },
  { label: "Гастроентеролог", value: "gastroenterologist" },
  { label: "Дієтолог", value: "nutritionist" },
  { label: "Трихолог", value: "trichologist" },
  { label: "Проктолог", value: "proctologist" },
  { label: "Хірург", value: "surgeon" },
  { label: "Алерголог", value: "allergist" },
]

export const relationsOptions: TRelationsOptions[] = [
  { name: "Неодружений", value: "single" },
  { name: "Одружений", value: "married" },
]

export const consultationOptions: TConsultationOtions[] = [
  { name: "Онлайн", value: "online" },
  { name: "Всі", value: "all" },
  { name: "Клініка", value: "clinic" },
]

export const consultationWithoutAllOptions: TConsultationOtions[] = [
  { name: "Онлайн", value: "online" },
  { name: "Клініка", value: "clinic" },
]

export const clinicsOptions: TClinicsOptions[] = [
  { name: "Вул. Є. Чавдар 2, ст.м. Осокорки", value: "clinic1" },
  {
    name: "Вул. Академіка Філатова 1/22, ст.м Дружби Народів",
    value: "clinic2",
  },
]

export const dayOptions: TDayOptions[] = [
  { name: "1", value: "1" },
  { name: "2", value: "2" },
  { name: "3", value: "3" },
  { name: "4", value: "4" },
  { name: "5", value: "5" },
  { name: "6", value: "6" },
  { name: "7", value: "7" },
]

export const сalendarDataOptions: TCalendarDataOptions[] = [
  { name: "День", value: "day" },
  { name: "Тиждень", value: "week" },
]
