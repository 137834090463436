import { createReducer } from '@reduxjs/toolkit';

import { showToastAction, hideToastAction } from './actions';
import { TInitialState } from './types';

const InitialState: TInitialState = {
  toasts: [],
}

export const toastsReducer = createReducer<TInitialState>(
  InitialState,
  builder => {
    builder.addCase(showToastAction.success, (state, { payload }) => ({
      ...state,
      toasts: [...state.toasts, payload],
    }))

    builder.addCase(hideToastAction, (state, { payload }) => ({
      ...state,
      toasts: state.toasts.filter(({ id }) => id !== payload),
    }))
  },
)
