import React, { useState } from "react";

import { api, Assets, Modal, downloadFileFunction, Button } from "common";
import { TChatFiles } from "store/chat";

import {
  Video,
  Image,
  Source,
  FileContainer,
  Info,
  FileIcon,
  Container,
  Text,
  Close,
} from "./styled";
import { TMessageBody } from "./types";

export const MessageBody = ({ el }: TMessageBody) => {
  const [currentFile, setCurrentFile] = useState<null | TChatFiles>(null);

  const currentFileHandler = (el: TChatFiles) => {
    setCurrentFile(el);
  };

  return (
    <>
      {el?.extension?.includes("image") && (
        <Image
          width={"auto"}
          height={"auto"}
          maxWidth={"300px"}
          maxHeight={"300px"}
          onClick={() => currentFileHandler(el)}
          key={el?._id}
          src={`${api.withImageAPI}/chat/${el.filename}`}
        />
      )}
      {el?.extension?.includes("video") && (
        <Video width="200px" controls>
          <Source
            aria-disabled
            src={`${api.withImageAPI}/chat/${el.filename}`}
            type="video/mp4"
          />
        </Video>
      )}
      {el?.extension?.includes("application") && (
        <FileContainer>
          <Info>
            <FileIcon src={Assets.DOCUMENT} />
            <Text>{el?.filename}</Text>
          </Info>
          <Button
            onClick={() =>
              downloadFileFunction(
                `${api.withImageAPI}/chat/${el.filename}`,
                el.filename
              )
            }
            size={14}
            title="Завантажити файл"
            width={144}
            height={28}
            margin="10px 0 0 0"
          />
        </FileContainer>
      )}
      {currentFile && (
        <Modal padding={"58px 90px"}>
          <Container>
            <Close src={Assets.CLOSE} onClick={() => setCurrentFile(null)} />
            {currentFile.extension.includes("image") && (
              <Image
                maxWidth={"500px"}
                maxHeight={"500px"}
                width={"auto"}
                height={"auto"}
                src={`${api.withImageAPI}/chat/${el.filename}`}
              />
            )}
          </Container>
        </Modal>
      )}
    </>
  );
};
