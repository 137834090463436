import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import _ from "lodash"

import { authActions, getAuthSelector } from "store/auth"
import { doctorsActions } from "store/doctors/actions"
import {
  getNotificationSelector,
  notifiactionActions,
} from "store/notification"

import { Assets, useTypedSelector, Text, colors } from "common"

import {
  Container,
  NavContainer,
  NavBlock,
  Image,
  NavigateBack,
  TextBlock,
  Notification,
  NotificationModal,
  Header,
  Counter,
  NotifiactionContainer,
} from "./styled"
import { TMainNavbar } from "./types"
import { NotificationBlock } from "./components"

export const MainNavbar = ({ navigate }: TMainNavbar) => {
  const navigateBack = useNavigate()
  const dispatch = useDispatch()
  const { notifications } = useTypedSelector(getNotificationSelector)

  const { currentDoctorData, notificationIsOpen } =
    useTypedSelector(getAuthSelector)

  const navigateHandler = () => {
    navigateBack(`${navigate}`)
  }

  const signOutHandler = async () => {
    await dispatch(doctorsActions.setDoctor(null))
    await dispatch(authActions.logout())
    await dispatch(authActions.setNotificationIsOpen(false))
  }

  const navigateLoginPhone = () => {
    navigateBack("/login-phone")
  }

  const isOpenNotificationModalHandler = () => {
    dispatch(authActions.setNotificationIsOpen(!notificationIsOpen))
  }

  const getNotifications = () => {
    dispatch(notifiactionActions.getNotifications({}))
  }

  const clearNotifiation = () => {
    dispatch(notifiactionActions.readNotifications({}))
    getNotifications()
    getNotifications()
  }

  useEffect(() => {
    getNotifications()
  }, [])

  return (
    <Container justify={navigate ? "space-between" : "flex-end"}>
      {navigate && (
        <NavigateBack onClick={navigateHandler}>
          <Image src={Assets.ARROW_BACK} />
          <Text
            text="Повернутись назад"
            margin="0 0 0 4px"
            fontSize="16"
            color={`${colors.salmon}`}
            $cursor={true}
          />
        </NavigateBack>
      )}
      <NavContainer>
        {currentDoctorData && (
          <Notification onClick={isOpenNotificationModalHandler}>
            <Image src={Assets.NOTIFICATION} />
            <Counter>{notifications?.docs?.length}</Counter>
          </Notification>
        )}
        <NavBlock
          onClick={currentDoctorData ? signOutHandler : navigateLoginPhone}
        >
          <Image src={Assets.LOGOUT} />
        </NavBlock>
        {notificationIsOpen && (
          <NotificationModal>
            <Header>
              <TextBlock>
                <Text fontWeight={700} text="Оповіщення" />
              </TextBlock>
              <Text
                onClick={clearNotifiation}
                text="Відмітити всі як прочитані"
                $cursor={true}
                fontSize="13"
                color={`${colors.salmon}`}
              />
              <Image
                src={Assets.CLOSE}
                onClick={isOpenNotificationModalHandler}
              />
            </Header>
            <NotifiactionContainer>
              {notifications?.docs?.map((el) => (
                <NotificationBlock key={el._id} el={el} />
              ))}
            </NotifiactionContainer>
          </NotificationModal>
        )}
      </NavContainer>
    </Container>
  )
}
