import React from "react";
import ReactDOM from "react-dom";

import { BlurContainer, Container, ModalContainer } from "./styled";
import { TModal } from "./typed";

export const Modal = ({
  children,
  background = "white",
  padding = "18px",
}: TModal) => {
  return ReactDOM.createPortal(
    <Container>
      <ModalContainer padding={padding} background={background}>
        {children}
      </ModalContainer>
      <BlurContainer />
    </Container>,
    document.getElementById("modal-root")!
  );
};
