import React, { useEffect } from "react";
import moment from "moment";
import { t } from "i18next";
import { registerLocale } from "react-datepicker";
import uk from "date-fns/locale/uk"; // the locale you want
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";

import {
  Assets,
  consultationOptions,
  consultationWithoutAllOptions,
  Text,
  useTypedSelector,
} from "common";
import { TCalendarEventData } from "../ModalInfoEventCalendar/types";

import {
  DatePickerBlock,
  FormBlock,
  Image,
  ReactDatePickerStyled,
  DefaultSelect,
  SelectBlock,
} from "./styled";
import { TTiming } from "./types";
import { getAuthSelector } from "store/auth";
import { doctorsActions, getDoctorsSelector } from "store/doctors";
import { useDispatch } from "react-redux";

registerLocale("uk", uk); // register it with the name you want

export const Timing = ({
  inputChangeHandler,
  setCalendarEventData,
  calendarEventData,
  register,
  doctorId,
  endDate,
  setEndDate,
  startDate,
  setStartDate,
}: TTiming) => {
  const dispatch = useDispatch();
  const { type } = useTypedSelector(getAuthSelector);
  const { doctors } = useTypedSelector(getDoctorsSelector);

  const specialitiesOptions = doctors?.docs
    ?.filter((item) => item?._id == doctorId)
    ?.map((item) =>
      item?.speciality?.map((item) => ({ name: item, value: item }))
    )[0];

  useEffect(() => {
    dispatch(
      doctorsActions.getDoctors({
        limit: 500,
      })
    );
  }, []);

  return (
    <React.Fragment>
      <FormBlock>
        <DatePickerBlock>
          <Image src={Assets.CALENDAR_FOR_INPUT} />
          <Text text="Дата" />
          <ReactDatePickerStyled
            placeholderText="Виберіть дату"
            onChange={(e) =>
              setCalendarEventData((props: TCalendarEventData) => ({
                ...props,
                date: e,
              }))
            }
            selected={
              new Date(moment(calendarEventData?.date).format("D MMMM YYYY"))
            }
            dateFormat="d MMMM"
            locale="uk"
          />
        </DatePickerBlock>

        <DatePickerBlock>
          <Text text="Час з" margin="0 0 2px 0" />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              value={dayjs(startDate as string)}
              InputProps={{
                className: "time-input",
              }}
              onChange={(newValue) => setStartDate(newValue as Dayjs)}
              format="HH:mm"
              size="small"
            />
          </LocalizationProvider>
        </DatePickerBlock>

        <DatePickerBlock>
          <Text text="Час по" margin="0 0 2px 0" />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeField
              sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              className="time-input"
              InputProps={{
                className: "time-input",
              }}
              value={dayjs(endDate as string)}
              onChange={(newValue) => setEndDate(newValue as Dayjs)}
              format="HH:mm"
              size="small"
            />
          </LocalizationProvider>
        </DatePickerBlock>
      </FormBlock>
      <FormBlock>
        <SelectBlock>
          <Text text="Тип консультації" />
          <DefaultSelect
            width={272}
            {...register("typeConsultation")}
            value={calendarEventData.typeConsultation}
            onChange={inputChangeHandler}
          >
            {(!calendarEventData.user
              ? consultationOptions
              : consultationWithoutAllOptions
            ).map((option, index: number) => (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            ))}
          </DefaultSelect>
        </SelectBlock>
      </FormBlock>
      {type == "admin" && (
        <SelectBlock style={{ marginTop: "8px" }}>
          <Text text="Спеціальність" margin="0 0 2px 0" sup={true} />
          <DefaultSelect
            width={272}
            value={calendarEventData.speciality}
            {...register("speciality")}
            onChange={inputChangeHandler}
          >
            {specialitiesOptions?.map((option, index: number) => (
              <option key={index} value={option.value}>
                {t(option.name) as any}
              </option>
            ))}
          </DefaultSelect>
        </SelectBlock>
      )}
    </React.Fragment>
  );
};
