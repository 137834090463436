export const SET_APPOINTMENT_STATE = 'SET_APPOINTMENT_STATE';
export const GET_APPOINTMENT_BY_DOCTOR = 'GET_APPOINTMENT_BY_DOCTOR';
export const SET_APPOINTMENT_BY_DOCTOR = 'SET_APPOINTMENT_BY_DOCTOR';
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const CREATE_APPOINTMENT_BULK = 'CREATE_APPOINTMENT_BULK';
export const APPOINTMENT_REPEAT_SLOT = 'APPOINTMENT_REPEAT_SLOT';
export const GET_DOCTOR_PLAIN = 'GET_DOCTOR_PLAIN';
export const SET_DOCTOR_PLAIN = 'SET_DOCTOR_PLAIN';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const DELETE_APPOINTMANT_REPEAT_TABLE = 'DELETE_APPOINTMANT_REPEAT_TABLE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const GET_CURRENT_APPOINTMENT = 'GET_CURRENT_APPOINTMENT';
export const SET_CURRENT_APPOINTMENT = 'SET_CURRENT_APPOINTMENT';
export const APPOINTMENT_COMPLETE = 'APPOINTMENT_COMPLETE';
export const DELETE_APPOINTMANT_CURRENT_DATE = 'DELETE_APPOINTMANT_CURRENT_DATE';