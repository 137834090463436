import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from './reducer';
import rootSaga from "./saga";

const persistedReducer = persistReducer(
	{
		key: 'dev.my-doctor',
		storage,
	},
	rootReducer
);

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware]
export const store = configureStore({
	reducer: persistedReducer,
	middleware: middlewares,
	devTools: true
})

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store)