import React, { useState } from "react";
import { registerLocale } from "react-datepicker";
import moment from "moment";
import uk from "date-fns/locale/uk"; // the locale you want

import { Assets, Text } from "common";

import {
  Container,
  DaysOfWeekWrapper,
  DaysOfWeekContainer,
  DaysOfWeekBlock,
  EndRepeatWrapper,
  EndRepeatBlock,
  Item,
  RadioButton,
  RadioButtonLabel,
  Image,
  DatePickerBlock,
  ReactDatePickerStyled,
} from "./styled";
import { TDaysOfWeek, TRepeatConsultation } from "./types";

registerLocale("uk", uk); // register it with the name you want

export const RepeatConsultation = ({
  dateFinish,
  setDaysOfWeekResult,
  daysOfWeekResult,
  select,
  setDateFinish,
  setSelect,
}: TRepeatConsultation) => {
  const [daysOfWeek, setDaysOfWeek] = useState<TDaysOfWeek[]>([
    { name: "Пн", value: "1", checked: false },
    { name: "Вт", value: "2", checked: false },
    { name: "Ср", value: "3", checked: false },
    { name: "Чт", value: "4", checked: false },
    { name: "Пт", value: "5", checked: false },
    { name: "Сб", value: "6", checked: false },
    { name: "Нд", value: "0", checked: false },
  ]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelect(event.target.value);
  };

  const addDaysOfWeekArrayHandle = (el: TDaysOfWeek) => {
    let newData = daysOfWeek.map((element: TDaysOfWeek) => {
      if (el === element) {
        return { ...element, checked: !element.checked };
      } else {
        return element;
      }
    });
    setDaysOfWeek(newData);
    if (daysOfWeekResult.includes(el.value)) {
      setDaysOfWeekResult((prevState: string[]) =>
        prevState.filter((element: string) => el.value !== element)
      );
    } else {
      setDaysOfWeekResult((prevState: string[]) => [...prevState, +el.value]);
    }
  };

  return (
    <Container>
      <DaysOfWeekWrapper>
        <Text text="Дні тижня" />
        <DaysOfWeekContainer>
          {daysOfWeek.map((el) => (
            <DaysOfWeekBlock
              checked={el.checked}
              onClick={() => addDaysOfWeekArrayHandle(el)}
              key={el.value}
            >
              {el.name}
            </DaysOfWeekBlock>
          ))}
        </DaysOfWeekContainer>
      </DaysOfWeekWrapper>
      <EndRepeatWrapper>
        <Text text="Закінчити повтор" />
        <EndRepeatBlock>
          <Item>
            <RadioButton
              type="radio"
              name="radio"
              value="year"
              checked={select === "year"}
              onChange={(event) => handleSelectChange(event)}
            />
            <RadioButtonLabel />
            <Text text="Через рік" />
          </Item>
          <Item>
            <RadioButton
              type="radio"
              name="radio"
              value="day"
              checked={select === "day"}
              onChange={(event) => handleSelectChange(event)}
            />
            <RadioButtonLabel />
            <Text text="Дата закінчення" />
          </Item>
          {select === "day" && (
            <DatePickerBlock>
              <Image src={Assets.CALENDAR_FOR_INPUT} />
              <ReactDatePickerStyled
                locale="uk"
                placeholderText="Виберіть дату"
                onChange={(e: Date) => setDateFinish(e)}
                selected={new Date(moment(dateFinish).format("D MMMM YYYY"))}
                dateFormat="d MMMM"
              />
            </DatePickerBlock>
          )}
        </EndRepeatBlock>
      </EndRepeatWrapper>
    </Container>
  );
};
