import React, { useEffect } from "react"
import { useDispatch } from "react-redux"

import { genderOptions, sortOrderOptions, useTypedSelector } from "common"
import { doctorsActions, getDoctorsSelector } from "store/doctors"
import { getAuthSelector } from "store/auth"

import { Form, FormBlock, Input, Label, Select } from "./styled"
import { TForms } from "./types"

export const Forms = ({ formData, inputChangeHandler }: TForms) => {
  const dispatch = useDispatch()

  const { type } = useTypedSelector(getAuthSelector)
  const { doctors } = useTypedSelector(getDoctorsSelector)

  useEffect(() => {
    dispatch(doctorsActions.getDoctors({ limit: 500 }))
  }, [])

  return (
    <Form>
      <FormBlock>
        <Label>Пошук за номером телефону</Label>
        <Input
          onChange={inputChangeHandler}
          name="phone"
          value={formData.phone}
        />
      </FormBlock>
      <FormBlock>
        <Label>Пошук по імені</Label>
        <Input
          onChange={inputChangeHandler}
          name="name"
          value={formData.name}
        />
      </FormBlock>
      <FormBlock>
        <Label>Сортування</Label>
        <Select
          name="order"
          onChange={inputChangeHandler}
          value={formData.order}
        >
          {sortOrderOptions.map((option, index: number) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </Select>
      </FormBlock>
      <FormBlock>
        <Label>Стать</Label>
        <Select
          name="gender"
          onChange={inputChangeHandler}
          value={formData.gender}
        >
          <option value="">Всі пацієнти</option>
          {genderOptions.map((option, index: number) => (
            <option key={index} value={option.value}>
              {option.name}
            </option>
          ))}
        </Select>
      </FormBlock>
      {type === "admin" && (
        <FormBlock>
          <Label>Лікар</Label>
          <Select
            name="doctor"
            onChange={inputChangeHandler}
            value={formData.doctor}
          >
            <option value="">Всі ({doctors?.meta?.totalCount ?? ""})</option>
            {doctors?.docs?.map((option, index: number) => (
              <option key={index} value={option._id}>
                {`${option.surname} ${option?.name
                  ?.slice(0, 1)
                  .toUpperCase()}.${option?.patronymic
                  ?.slice(0, 1)
                  .toUpperCase()}.`}
              </option>
            ))}
          </Select>
        </FormBlock>
      )}
    </Form>
  )
}
