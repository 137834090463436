import styled from "styled-components";
import ReactDatePicker from "react-datepicker";

import { colors } from "common";

import { TDaysOfWeekblock, TSelect } from "./types";

export const Container = styled.div`
  margin-top: 25px;
`;

export const SelectBlock = styled.div`
  display: flex;
  justify-content: space-between;

  width: 272px;
`;

export const Select = styled.select<TSelect>`
  border: 1px solid ${colors.white};
  border-radius: 8px;

  background-color: ${colors.white};

  margin-top: 2px;
	
  padding: 11px;

  width: ${({ width }) => `${width}px`};

  cursor: pointer;

  &::placeholder{
    font-size: 15px;
    letter-spacing: 0.1px;
  }
`;

export const DaysOfWeekWrapper = styled.div`
  margin-top: 20px;
`;

export const DaysOfWeekContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 8px;
`;

export const DaysOfWeekBlock = styled.div<TDaysOfWeekblock>`
  background-color: ${colors.white};

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ checked }) => !checked ? colors.black : colors.salmon};

  width: 45px;
  height: 32px;

  border: ${({ checked }) => !checked ? `2px solid ${colors.white}` : `2px solid ${colors.salmon}`};
  border-radius: 8px;

  cursor: pointer;
`;

export const EndRepeatWrapper = styled.div`
  margin-top: 26px;
`;

export const EndRepeatBlock = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 560px) {
    width: 100%;
    display: block;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: ${colors.white};
  border: 3px solid ${colors.salmon};
`;

export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  background-color: ${colors.salmon};
  width: 25px;
  height: 25px;
  margin-left: 10px;
  &:hover ~ ${RadioButtonLabel} {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.salmon};
    &::after {
      content: "";
      border: 3px solid ${colors.white};
      border-radius: 50%;
      color: ${colors.white};
      width: 15px;
      height: 15px;
    }
  }
  &:checked + ${Item} {
    background: ${colors.salmon};
    border: 3px solid ${colors.salmon};
  }
  &:checked + ${RadioButtonLabel} {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.salmon};
    &::after {
      content: "";
      border: 3px solid ${colors.white};
      border-radius: 50%;
      color: ${colors.white};
      width: 15px;
      height: 15px;
    }
  }
`;

export const DatePickerBlock = styled.div`
  position: relative;
  width: 128px;
`;

export const Image = styled.img`
  position: absolute;
  z-index: 100;
  top: 15px;
  right: 10px;  
`;

export const ReactDatePickerStyled = styled(ReactDatePicker)`
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${colors.white};
  margin-bottom: 10px;
  font-size: 14px;
  width: 128px;
  font-size: 14px;
  font-weight: 300;
  padding: 12px 10px;
  margin-top: 2px;

  &::placeholder{
    font-size: 13px;
  }
`;

 