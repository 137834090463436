import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ua } from '../locales';

i18n.use(initReactI18next).init({
    resources: {
        ua: { translations: ua },
    },
    fallbackLng: 'ua',
    lng: 'ua',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },

});

export default i18n;