import { createAction } from "@reduxjs/toolkit";

import {
  GET_APPOINTMENT_BY_DOCTOR,
	SET_APPOINTMENT_BY_DOCTOR,
  SET_APPOINTMENT_STATE,
  CREATE_APPOINTMENT,
  CREATE_APPOINTMENT_BULK,
  APPOINTMENT_REPEAT_SLOT,
  GET_DOCTOR_PLAIN,
  SET_DOCTOR_PLAIN,
  UPDATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  DELETE_APPOINTMANT_REPEAT_TABLE,
  REFRESH_TOKEN,
  GET_CURRENT_APPOINTMENT,
  SET_CURRENT_APPOINTMENT,
  APPOINTMENT_COMPLETE,
  DELETE_APPOINTMANT_CURRENT_DATE,
} from "./consts";

export const appointmentActions = {
	setAppointmentByDoctor: createAction(SET_APPOINTMENT_BY_DOCTOR, (payload) => ({payload})),
	getAppointmentByDoctor: createAction(GET_APPOINTMENT_BY_DOCTOR, (payload) => ({payload})),
  getDoctorPlain: createAction(GET_DOCTOR_PLAIN, (payload) => ({payload})),
  setDoctorPlain: createAction(SET_DOCTOR_PLAIN, (payload) => ({payload})),
  setAppointmentState: createAction(SET_APPOINTMENT_STATE, (payload) => ({payload})),
  createAppointment: createAction(CREATE_APPOINTMENT, (payload) => ({payload})),
  createAppintmentBulk: createAction(CREATE_APPOINTMENT_BULK, (payload) => ({payload})),
  createAppointmentRepeatSlot: createAction(APPOINTMENT_REPEAT_SLOT, (payload) => ({payload})),
  updateAppointment: createAction(UPDATE_APPOINTMENT, (payload) => ({payload})),
  deleteAppointment: createAction(DELETE_APPOINTMENT, (payload) => ({payload})),
  deleteAppointmentRepeatTable: createAction(DELETE_APPOINTMANT_REPEAT_TABLE, (payload) => ({payload})),
  setStatus: createAction(REFRESH_TOKEN, (payload) => ({payload})),
  getCurrentAppointment: createAction(GET_CURRENT_APPOINTMENT, (payload) => ({payload})),
  setCurrentAppointment: createAction(SET_CURRENT_APPOINTMENT, (payload) => ({payload})),
  appointmentComplete: createAction(APPOINTMENT_COMPLETE, (payload) => ({payload})),
  deleteAppointmentCurrentDate: createAction(DELETE_APPOINTMANT_CURRENT_DATE, (payload) => ({payload})),
}