import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
`;

export const Main = styled.div`
  display: flex;

  @media (max-width: 1030px) {
    flex-wrap: wrap;
  };
`;

export const ChatBlock = styled.div` 
  padding-top: 250px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  
   @media (max-width: 1030px) {
    display: none;
  };
`;

export const Image = styled.img``;
