import React, { FC, useState, useEffect, SyntheticEvent } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useDispatch } from "react-redux";

import { Button, colors, PhotoPicker, useTypedSelector } from "common";
import { getNewsSelector, newsActions } from "store/news";

import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";

import { ButtonContainer, Form, Image, Line, Main } from "./styled";
import { CreateEditorProps } from "./types";

export const CreateEditor: FC<CreateEditorProps> = ({ form, setForm }) => {
  const dispatch = useDispatch();
  const { news } = useTypedSelector(getNewsSelector);

  const [create, setCreate] = useState<boolean>(false);
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);

  const hiddenFileInput = React.useRef<any>(null);

  const inputChangeHandler = (text: string) => {
    setForm({ ...form, text: text });
  };

  const alertText = () => {
    if (news?.meta) {
      if (news.meta?.totalCount > 9) {
        return "Максимальна кількість слайдерів: 10";
      }
      return "Необхідно додати фото";
    }
  };

  const onAddClickHandler = () => setCreate(true);
  const onSaveClickHandler = () => {
    if (news?.meta) {
      if (news.meta.totalCount > 9) {
        toogleIsAlertOpen(true);
        return;
      }
    }

    if (form?.photo) {
      const data = new FormData();

      data.append("text", form?.text ? form.text : "");
      data.append("image", form.photo as File);

      dispatch(newsActions.createNews({ data }));
      setCreate(false);
      setForm({ ...form, text: "", photo: null });

      return;
    }

    toogleIsAlertOpen(true);
  };

  const hiddenImageFileUpload = () => {
    hiddenFileInput.current.click();
  };

  const submitHandler = (e: SyntheticEvent) => {
    const inputEventHandler = (e.target as HTMLInputElement).files;
    const file = inputEventHandler ? inputEventHandler[0] : "";

    if (file) {
      setForm({ ...form, photo: file as File | null });
    }
  };

  const deleteNews = () => {
    setForm({ ...form, text: "", photo: null });
    setCreate(false);
  };

  const onPhotoChange = (fileData: File | null) => {
    setForm({ ...form, photo: fileData as File });
  };

  useEffect(() => {
    if (!create)
      dispatch(
        newsActions.getNews({
          lang: "ru",
          limit: 10,
          page: 0,
          order: -1,
          sortBy: "_id",
        })
      );
  }, [create]);

  return (
    <Main>
      <Button
        title={"Додати"}
        width={120}
        height={58}
        size={20}
        onClick={onAddClickHandler}
      />

      {create && (
        <Form>
          {form?.photo?.size ? (
            <Image
              src={URL.createObjectURL(form?.photo as Blob)}
              onClick={hiddenImageFileUpload}
            />
          ) : (
            <PhotoPicker width={250} height={250} onChange={onPhotoChange} />
          )}
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={submitHandler}
            style={{ display: "none" }}
          />
          <Editor
            apiKey="lci85rlzi43qr9ohaqaufzdvoefi7w9ldifxcexfwhtn2ff8"
            value={form?.text}
            init={{
              height: 350,
              width: "100%",
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor textcolor",
                "searchreplace visualblocks code fullscreen textcolor ",
                "insertdatetime media table paste code help wordcount image textcolor",
              ],
              image_title: false,
              automatic_uploads: false,
              images_upload_url: "postAcceptor.php",
              toolbar:
                "undo redo | formatselect | bold italic backcolor forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | image code",
              branding: false,
            }}
            onEditorChange={(c) => {
              inputChangeHandler(c);
            }}
          />
          <ButtonContainer>
            <Button
              title="Зберегти"
              height={36}
              width={120}
              size={18}
              onClick={onSaveClickHandler}
            />
            <Button
              title="Видалити"
              color={colors.red}
              height={36}
              width={120}
              size={18}
              onClick={deleteNews}
            />
          </ButtonContainer>
        </Form>
      )}
      <Line />

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={() => toogleIsAlertOpen(false)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {alertText()}
        </Alert>
      </Snackbar>
    </Main>
  );
};
