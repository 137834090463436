import React, { SyntheticEvent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import {
  MainNavbar,
  Pagination,
  Title,
  useTypedSelector,
} from "common"
import { getUsersSelector, usersActions } from "store/users"
import { getAuthSelector } from "store/auth"

import { Main } from "./styled"
import { Forms, Table } from "./components"
import { ITEM_PER_PAGE } from "./consts"
import { TFormData } from "./types"

export const PatientsList = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { currentDoctorData, type } = useTypedSelector(getAuthSelector)

  const [formData, setFormData] = useState<TFormData>({
    name: "",
    phone: "",
    gender: "",
    order: "-1",
    doctor: currentDoctorData?._id as string,
  })
  const { users } = useTypedSelector(getUsersSelector)
  const [page, setPage] = useState<number>(0)

  const onPageChangeHandler = ({ selected }: { selected: number }) => {
    setPage(selected)
  }

  const utils = {
    getQueryParams: () => {
      const res = {}

      const requestFields = {
        ...Object.keys(formData)
          .map((field) =>
            formData[field as keyof typeof formData]
              ? Object.assign(res, {
                  [field]: formData[field as keyof typeof formData],
                })
              : ""
          )
          .filter((item) => item),
      }
      return res
    },
  }

  useEffect(() => {
    dispatch(
      usersActions.getUsers({
        ...utils.getQueryParams(),
        doctor: type === "admin" ? formData.doctor : currentDoctorData?._id,
        skip: page,
        sortBy: "createdAt",
        limit: ITEM_PER_PAGE,
      })
    )
  }, [formData, page, dispatch])

  useEffect(() => {
    if (id) {
      setFormData({
        doctor: id === "all" ? "" : id,
        name: "",
        order: "-1",
        gender: "",
        phone: "",
      })
    }
  }, [id])

  useEffect(() => {
    if (users?.docs?.length === 0) {
      setPage(0)
    }
  }, [users])

  const inputChangeHandler = (e: SyntheticEvent) => {
    const { name, value } = e.target as HTMLInputElement
    setFormData((props: TFormData) => ({ ...props, [name]: value }))
  }

  return (
    <React.Fragment>
      <MainNavbar />
      <Main>
        <Title title={"Пацієнти"} />
        <Forms inputChangeHandler={inputChangeHandler} formData={formData} />
        {users?.docs?.map((patient) => (
          <Table key={patient?._id} patient={patient} />
        ))}
      </Main>
      {users?.docs?.length !== 0 && (
        <Pagination
          page={page}
          pageCount={
            users?.meta ? Math.ceil(users?.meta?.totalCount / ITEM_PER_PAGE) : 1
          }
          onPageChange={onPageChangeHandler}
        />
      )}
    </React.Fragment>
  )
}
