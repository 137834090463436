import { createAction } from "@reduxjs/toolkit";

import {
	GET_USERS,
	SET_USERS,
	SET_USER,
	GET_USER,
	SET_DOCTORS_STATE,
	UPDATE_USER,
	GET_FILES,
	SET_FILES
} from "./consts";

export const usersActions = {
	getUsers: createAction(GET_USERS, (payload) => ({ payload })),
	setUsers: createAction(SET_USERS, (payload) => ({ payload })),
	setUser: createAction(SET_USER, (payload) => ({ payload })),
	getUser: createAction(GET_USER, (payload) => ({ payload })),
	updateUser: createAction(UPDATE_USER, (payload) => ({ payload })),
  setUsersState: createAction(SET_DOCTORS_STATE, (payload) => ({ payload })),
	getFiles: createAction(GET_FILES, (payload) => ({ payload })),
	setFiles: createAction(SET_FILES, (payload) => ({ payload }))
}