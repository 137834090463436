import styled from "styled-components";

import { colors } from "common/styles/colors";

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  background: #E5E5E5;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-size: 16px;
  line-height: 20px;
`;

export const Wrapper = styled.div`
  width: 433px;

  padding: 52px 57px 87px 56px;

  border-radius: 22px;

  background: ${colors.white};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    padding: 40px 15px;
    width: 80%;
  }
`;

export const Logo = styled.img`
  margin-bottom: 36px;
`;