import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import moment from "moment";

import { api, Assets, Button, colors, Modal, Text } from "common";

import { supportActions } from "store/support";

import {
  Form,
  FormBlock,
  FormTextare,
  HeaderSection,
  Image,
  MainSection,
  NavigateButton,
  SupportContainer,
  Video,
  TextAreaBlock,
  ModalContainer,
  Close,
  ImageModal,
  GalleryWrapper,
} from "./styled";
import { SupportItemComponentProps } from "./types";

export const SupportItemComponent: FC<SupportItemComponentProps> = ({
  isActive,
  setIsActive,
  getSupport,
  currentSupportData,
  setCurrentSupportData,
}) => {
  const dispatch = useDispatch();

  const [currentFile, setCurrentFile] = useState<string>("");

  const currentFileHandler = (el: string) => {
    setCurrentFile(el);
  };

  const closeButtonClickHandler = () => {
    setIsActive(false);
    setCurrentSupportData("");
    dispatch(supportActions.closeSupport({ _id: currentSupportData?._id }));
    getSupport();
    getSupport();
    getSupport();
  };

  return (
    <SupportContainer $isActive={isActive} $position="right">
      {currentSupportData ? (
        <>
          <HeaderSection $justify="flex-start">
            <NavigateButton
              onClick={() => {
                setCurrentSupportData("");
                setIsActive(false);
                return <Navigate to={"/support"} />;
              }}
              src={Assets.ARROW_BACK}
            />
            <Text
              text={moment(currentSupportData?.createdAt).format('HH:mm YYYY.MM.DD')}
              color={`${colors.gray}`}
            />
          </HeaderSection>
          <MainSection>
            <FormBlock>
              <Text text="Ім'я" margin="0 0 2px 0" />
              <Form $width={300} $height={40}>
                {currentSupportData?.name}
              </Form>
            </FormBlock>
            <FormBlock>
              <Text text="Імейл" margin="0 0 2px 0" />
              <Form $width={300} $height={40}>
                {currentSupportData?.email}
              </Form>
            </FormBlock>

            <TextAreaBlock>
              <Text text="Повідомлення" margin="0 0 2px 0" />
              <FormTextare>{currentSupportData?.message}</FormTextare>
            </TextAreaBlock>

            <GalleryWrapper>
              {currentSupportData?.files?.slice(0, 5).map((file) => {
                if (file?.split(".")[1] === "jpg") {
                  return (
                    <Image
                      onClick={() => currentFileHandler(file)}
                      src={`${api.withImageAPI}support/${file}`}
                    />
                  );
                } else if (file?.split(".")[1] === "mp4") {
                  return (
                    <Video
                      src={`${api.withImageAPI}support/${file}`}
                      controls
                    />
                  );
                }
              })}
            </GalleryWrapper>

            <Button
              width={120}
              height={58}
              title={"Закрити запит підтримки"}
              size={16}
              margin={"12px 0"}
              color={colors.red}
              onClick={closeButtonClickHandler}
            />
          </MainSection>
          {currentFile && (
            <Modal padding={"58px 90px"}>
              <ModalContainer>
                <Close src={Assets.CLOSE} onClick={() => setCurrentFile("")} />
                <ImageModal
                  maxWidth={"500px"}
                  maxHeight={"500px"}
                  width={"auto"}
                  height={"auto"}
                  src={`${api.withImageAPI}/support/${currentFile}`}
                />
              </ModalContainer>
            </Modal>
          )}
        </>
      ) : (
        ""
      )}
    </SupportContainer>
  );
};
