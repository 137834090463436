import { api } from "common";

export const APPOINTMENT_URL = {
	getAppointmentByDoctor:`${api.withAPI}api/appointment`,
	createAppointment:`${api.withAPI}api/appointment`,
	createAppointmentBulk: `${api.withAPI}api/appointment/bulk`,
	appointmentRepeatSlot: `${api.withAPI}api/appointment/repeatSlot`,
	getDoctorPlain: `${api.withAPI}api/appointment`,
	updateAppointment: `${api.withAPI}api/appointment`,
	deleteAppointment: `${api.withAPI}api/appointment`,
	deleteAppointmentRepeatTable: `${api.withAPI}api/appointment/repeatable`,
	getCurrentAppointment: `${api.withAPI}api/appointment`,
	appointmentComplete: `${api.withAPI}api/appointment`,
	deleteAppointmentCurrentDate: `${api.withAPI}/api/appointment/day`,
}