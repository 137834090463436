import { useState } from "react";

import { Assets } from "common";
import Sidebar from "pages/Sidebar/Sidebar";

import { LayoutWrapper, ContentWrapper, MenuBurger } from "./styled";
import { AuxProps } from "./types";

export const LayoutComponent = ({ children }: AuxProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <>
      <LayoutWrapper>
        <Sidebar setIsActive={setIsActive} isActive={isActive} />
        <ContentWrapper>
          <MenuBurger
            src={Assets.MENU_ICON}
            onClick={() => setIsActive(true)}
          />
          {children}
        </ContentWrapper>
      </LayoutWrapper>
    </>
  );
}
