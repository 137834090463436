import { TGenerateToast } from './types';

export const generateErrorToast: TGenerateToast = message => ({
  severity: 'error',
  message,
});

export const generateSuccessToast: TGenerateToast = message => ({
  severity: 'success',
  message,
});
