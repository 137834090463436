export const GET_TOKEN = 'GET_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_PHONE = 'SET_PHONE';
export const LOGOUT_DOCTOR = 'LOGOUT_DOCTOR';
export const SET_ID_CURRENT_DOCTOR = 'SET_ID_CURRENT_DOCTOR';
export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_CURRENT_DOCTOR_DATA = 'SET_CURRENT_DOCTOR_DATA';
export const GET_AUTH_ME = 'GET_AUTH_ME';
export const SET_AUTH_ME = 'SET_AUTH_ME';
export const CREATE_AUTH_ME = 'CREATE_AUTH_ME';
export const ACTIVE_SIDEBAR = 'ACTIVE_SIDEBAR';
export const SET_CURRENT_ID = 'SET_CURRENT_ID';
export const SET_TYPE = 'SET_TYPE';
export const SET_NOTIFICATION_IS_OPEN = 'SET_NOTIFICATION_IS_OPEN';