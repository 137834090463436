import styled from "styled-components";

import { colors } from "common";

import {
  THeaderSection,
  TMainBlock,
  TForm,
  TSupportContainerStyled,
  TImage,
} from "../../types";

export const Main = styled.div`
  padding: 42px 30px;
`;

export const NavigateButton = styled.img`
  display: none;
  cursor: pointer;
  width: 30px;
  margin-right: 10px;

  @media (max-width: 1260px) {
    display: flex;
  }
`;

export const SupportWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 80vh;

  margin-top: 30px;
  border-radius: 12px;
  background-color: ${colors.white};

  @media (max-width: 500px) {
    margin-top: 10px;
    height: 100%;
  }
`;

export const SupportContainer = styled.div<TSupportContainerStyled>`
  padding: 20px 20px;
  width: 100%;

  &:not(:last-child) {
    border-right: 1px solid ${colors.bgSidebar};
  }

  @media (max-width: 1260px) {
    width: 100%;
    display: ${({ $isActive, $position }) =>
      $position === "left"
        ? !$isActive
          ? "block"
          : "none"
        : $isActive
        ? "block"
        : "none"};
    &:not(:last-child) {
      border-right: none;
    }
  }
`;

export const HeaderSection = styled.div<THeaderSection>`
  display: flex;
  align-items: center;
  justify-content: ${({ $justify }) => $justify};

  padding: 20px 0;

  border-bottom: 1px solid ${colors.bgSidebar};
`;

export const GalleryWrapper = styled.div`
  margin: 10px 0 10px -15px;

  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const MainBlock = styled.div<TMainBlock>`
  display: flex;
  justify-content: space-around;

  background-color: ${({ $background }) => $background};

  padding: 30px 0;

  width: 100%;

  cursor: pointer;

  border-bottom: 1px solid ${colors.bgSidebar};
`;

export const TextBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 33%;
  margin-right: 10px;
`;

export const TextAreaBlock = styled.div`
  margin-top: 15px;
`;

export const FormTextare = styled.div`
  padding: 10px;
  min-height: 40px;
  overflow-y: auto;
  max-width: 675px;
  width: 100%;
  border-radius: 8px;
  background-color: ${colors.bgSidebar};
  box-sizing: border-box;

  @media (max-width: 1660px) {
    width: 600px;
    height: 350px;
  }

  @media (max-width: 1600px) {
    width: 450px;
    height: auto;
  }

  @media (max-width: 1060px) {
    width: 350px;
  }

  @media (max-width: 700px) {
    width: 300px;
    height: auto;
  }

  @media (max-width: 500px) {
    width: 250px;
  }
`;

export const MainSection = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FormBlock = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }
  max-height: 220px;

  @media (max-width: 785px) {
    max-height: 100%;
  }

  @media (max-width: 660px) {
    max-height: 220px;
  }

  @media (max-width: 535px) {
    max-height: 100%;
  }
`;

export const Form = styled.div<TForm>`
  padding: 10px;
  width: ${({ $width }) => `${$width}px`};
  height: ${({ $height }) => `${$height}px`};
  background-color: ${colors.bgSidebar};
  border-radius: 8px;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 250px;
  }
`;

export const Image = styled.img`
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  margin-left: 15px;
  margin-top: 10px;
`;

export const Video = styled.video`
  max-width: 200px;
  max-height: 200px;
  width: 100%;
  height: 100%;

  margin-left: 15px;
  margin-top: 10px;
`;

export const ModalContainer = styled.div`
  position: relative;

  @media (max-width: 780px) {
    width: 250px;
  }

  @media (max-width: 500px) {
    width: 200px;
  }

  @media (max-width: 400px) {
    width: 150px;
  }
`;

export const Close = styled.img`
  position: absolute;
  right: -60px;
  cursor: pointer;
  top: -25px;
`;

export const ImageModal = styled.img<TImage>`
  max-width: ${({ maxWidth }) => `${maxWidth}`};
  max-height: ${({ maxHeight }) => `${maxHeight}`};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};

  background-size: cover;
  background-position: center;

  cursor: pointer;

  display: block;
  padding: 10px;

  @media (max-width: 780px) {
    width: 250px;
  }  

  @media (max-width: 500px) {
    width: 100%;
  }  
`;
