export const GET_DOCTORS = 'GET_DOCTORS';
export const SET_DOCTORS = 'SET_DOCTORS';
export const GET_DOCTOR = 'GET_DOCTOR';
export const SET_DOCTOR = 'SET_DOCTOR';
export const EDIT_DOCTOR = 'EDIT_DOCTOR';
export const SET_DOCTORS_STATE = 'SET_DOCTORS_STATE';
export const SET_FEEDBACKS_DOCTOR = 'SET_FEEDBACKS_DOCTOR';
export const GET_FEEDBACKS_DOCTOR = 'GET_FEEDBACKS_DOCTOR';
export const CREATE_DOCTOR = 'CREATE_DOCTOR';
export const SET_DOCTOR_ID = 'SET_DOCTOR_ID';
export const DELETE_FEEDBACK = 'DELETE_FEEDBACK';