import React from "react";

import moment from "moment";

import { Container, Text } from "./styled";
import { TNotificationBlock } from "./types";

export const NotificationBlock = ({ el }: TNotificationBlock) => {
  return (
    <Container>
      {el.action === "appointmentDelete" && (
        <Text>{`Видалено консультацію в ${moment(el?.createdAt).format(
          "HH:mm"
        )}`}</Text>
      )}
      {el.action === "newAppointment" && (
        <Text>{`Створено консультацію на ${moment(
          el?.data?.appointment?.startTime
        ).format("DD/MM/YY")} з ${moment(
          el?.data?.appointment?.startTime
        ).format("HH:mm")} до ${moment(el?.data?.appointment?.endTime).format(
          "HH:mm"
        )}`}</Text>
      )}
      {el.action === "appointmentUpdate" && (
        <Text>{`Оновлено консультацію на ${moment(
          el?.data?.appointment?.startTime
        ).format("DD/MM/YY")} з ${moment(
          el?.data?.appointment?.startTime
        ).format("HH:mm")} до ${moment(el?.data?.appointment?.endTime).format(
          "HH:mm"
        )}`}</Text>
      )}
    </Container>
  );
};
